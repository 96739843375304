import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BadgeIcon from '@mui/icons-material/Badge';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Card, Container, Grid, Link, Modal, Stack, TextField, Typography, createSvgIcon } from '@mui/material';
import Box from '@mui/material/Box';
import { GoogleLogin } from '@react-oauth/google';
import { useFormik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import MetaTags from '../componets/MetaTags';
import { loginUserWithGoogle, loginWithGoogleUpdate, registerUser } from '../helper/helper';
import { registerValidate } from '../helper/validate';
import Footer from './Footer';

export default function Register() {

    const navigate = useNavigate();
    const [userModel, setuserModel] = useState(false);
    const [selectedItemIndex, setSelectedItemIndex] = useState(0);
    const [open, setOpen] = React.useState(false);
    const [userData, setuserData] = useState();


    const items = [
        {
            icon: <BadgeIcon />,
            title: 'Join as a market place seller',
            description: 'I plan to submit name ideas, Logo designs or sell names in Domain Marketplaces',
        },
        {
            icon: <AccountCircleIcon />,
            title: 'Join as a buyer',
            description: 'I am looking for a name, logo or tagline for my business, brand or product.',
        },
    ];



    const EmailBox = createSvgIcon(
        <svg xmlns="http://www.w3.org/2000/svg" width="94" height="101" viewBox="0 0 94 101" fill="none">
            <path d="M40.71 4.91656L4.11162 34.6307C2.13529 36.2352 0.987582 38.6452 0.987435 41.1909L0.984375 93.9325C0.984375 97.8219 4.13734 100.975 8.02672 100.975H85.9729C89.8623 100.975 93.015 97.8219 93.015 93.9326V41.201C93.015 38.6495 91.8623 36.2347 89.8784 34.6302L53.1244 4.90483C49.5029 1.97596 44.3259 1.98085 40.71 4.91656Z" fill="#951B3B" />
            <path d="M76.1622 13.6055C81.6073 13.6055 86.0215 18.0196 86.0215 23.4647L86.0215 99.5668C86.0215 100.345 85.3909 100.975 84.613 100.975L9.38783 100.975C8.60996 100.975 7.97937 100.345 7.97937 99.5668L7.97937 23.4647C7.97937 18.0196 12.3935 13.6055 17.8386 13.6055L76.1622 13.6055Z" fill="#F9F9FF" />
            <rect x="18.6084" y="47.5156" width="44.4953" height="6.42546" rx="3.21273" fill="#EC4470" fillOpacity="0.2" />
            <rect x="18.6084" y="25.6582" width="44.0601" height="6.42546" rx="3.21273" fill="#EC4470" fillOpacity="0.2" />
            <rect x="18.6084" y="36.5859" width="33.4123" height="6.42546" rx="3.21273" fill="#EC4470" fillOpacity="0.2" />
            <path d="M36.4275 68.8643L0.563477 39.9219L0.812429 98.0902L36.4275 68.8643Z" fill="#EC4470" />
            <path d="M57.7556 69.374L94.0869 40.3398L93.583 95.6636L57.7556 69.374Z" fill="#EC4470" />
            <path d="M-14.2325 99.2153L39.0569 61.8901C43.9079 58.4923 50.3664 58.4923 55.2175 61.8901L119.481 106.902C120.608 107.691 120.05 109.464 118.673 109.464L-11.0004 109.464C-16.5079 109.464 -18.7436 102.375 -14.2325 99.2153Z" fill="#951B3B" />
        </svg>,
        'email_box',
    );

    const handleItemClick = (index) => {
        setSelectedItemIndex(index);
    };

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        navigate('/login');
    };

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            // phone: '',
            email: '',
            password: '',
            confirm_password: '',
            // user_type: '',
        },
        enableReinitialize: true,
        validate: registerValidate,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async values => {
            values = await Object.assign(values, { user_type: (selectedItemIndex + 1).toString() || '' })
            // console.log('callsdsdsls', selectedItemIndex)
            // console.log(values);
            let registerPromise = registerUser(values);
            registerPromise.then(function () {
                // console.log('ok');
                handleOpen()
                toast.success('Registration successfull..')
                // navigate('/')
            }).catch(error => {
                toast.error(error)
            });

        },
    });
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: '#000',
        color: '#fff',
        textAlign: 'ceter',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };


    async function loginwithgoogle(tokenResponse) {
        try {
            let registerWithGooglePromise = loginUserWithGoogle(tokenResponse);

            registerWithGooglePromise.then(res => {
                let { token, user } = res.data;
                if (user.user_type !== "3") {
                    toast.success("Login Successfully..!");
                    localStorage.setItem('token', token);
                    navigate('/dashboard');
                    setuserModel(false);
                } else {
                    setuserData(user);
                    setuserModel(true);
                }
            }).catch(error => {
                // console.log('Please Use Unique Email');
                toast.error(error);
            })
        } catch (error) {
            // console.log(error);
        }
    }


    const handleSubmit = () => {
        try {
            if (userData && userData !== "") {
                let registerWithGooglePromise = loginWithGoogleUpdate({ email: userData.email, status: (selectedItemIndex + 1).toString() || '' });

                registerWithGooglePromise.then(res => {
                    let { token } = res.data;
                    toast.success("Login Successfully..!");
                    localStorage.setItem('token', token);
                    navigate('/');
                }).catch(error => {
                    // console.log('Please Use Unique Email');
                    toast.error(error);
                })
            }
        } catch (error) {
            // console.log(error);
        }
    }

    return (
        <>
            <MetaTags title="Register" description="Register your account." />
            <Toaster position='top-center' reverseOrder={false}></Toaster>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Card sx={{ ...style }}>
                    <CloseIcon onClick={handleClose} sx={{ position: 'absolute', top: '10px', right: '10px', cursor: "pointer" }} />
                    <EmailBox sx={{ width: '100%', mb: 1, height: 50, display: "flex" }} />
                    <Typography variant="h4" sx={{ textAlign: 'center' }} fontWeight={600} gutterBottom>Verify Your Email</Typography>
                    <Typography sx={{ textAlign: 'center' }} variant="body2">We have sent an email verification link to your email address. Please click the link in the message to verify your account and start using hydra api. If you do not receive the message in a few minutes, please check your spam folder or contact our support team at support@xillentech.com for assistance.</Typography>
                </Card>
            </Modal>
            <Container
                id="register_form"
                sx={{
                    pt: { xs: 4, sm: 5 },
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: { xs: "100%", sm: 800 },
                    gap: { xs: 3, sm: 6 },
                }}
            >

                <Card
                    sx={(theme) => ({
                        p: 3,
                        height: 'fit-content',
                        width: '100%',
                        background: 'none',
                        '&:hover': {
                            boxShadow:
                                theme.palette.mode === 'light'
                                    ? '0px 2px 8px hsla(0, 0%, 0%, 0.1)'
                                    : '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                        },
                    })}
                >
                    <Box
                        sx={{
                            p: { xs: 2, sm: 5 },
                            borderRadius: 5,
                        }}
                    // autoComplete="off"
                    >
                        <Typography variant="h2" align="center" sx={{ fontSize: { xs: "30px", sm: "40px" }, marginBottom: "10px" }}> Create an Account</Typography>
                        <Typography variant="subtitle1" align="center"> We always keep your name and email private </Typography>
                        <div className='w-full flex justify-center my-4'>
                            <GoogleLogin
                                type='standard'
                                className="bg-transparent"
                                text="Sign up with Google"
                                size="large"
                                theme='filled_black'
                                fullWidth
                                // shape="circle"
                                onSuccess={credentialResponse => {
                                    // console.log(credentialResponse);
                                    loginwithgoogle(credentialResponse);
                                }}

                                onError={() => {
                                    console.log('Login Failed');
                                }}
                            />
                        </div>
                        <div className="flex items-center w-full max-w-52 mx-auto">
                            <div className="flex-grow border-t border-gray-300"></div>
                            <span className="mx-4 text-gray-500 text-[16px]">or</span>
                            <div className="flex-grow border-t border-gray-300"></div>
                        </div>

                        <br />
                        <form className='py-1' onSubmit={formik.handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField size="small" {...formik.getFieldProps('first_name')} required id="first-name" label="First Name" variant="outlined" fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField size="small" {...formik.getFieldProps('last_name')} required id="last-name" label="Last Name" variant="outlined" fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                </Grid>
                            </Grid>
                            <Stack spacing={2}>
                                {/* <TextField size="small" {...formik.getFieldProps('phone')} required id="phone" label="Phone" variant="outlined" fullWidth /> */}
                                <TextField size="small" {...formik.getFieldProps('email')} required id="email" label="Email Address" variant="outlined" fullWidth type="email" />
                                <TextField size="small" {...formik.getFieldProps('password')} required id="password" label="Password" variant="outlined" fullWidth type="password" />
                                <TextField size="small" {...formik.getFieldProps('confirm_password')} required id="confirm_password" label="Confirm Password" variant="outlined" fullWidth type="password" />
                            </Stack>
                            <br />
                            <Grid container spacing={3}>
                                {items.map(({ icon, title, description }, index) => (
                                    <Grid key={index} item xs={12} sm={6}>
                                        <Card
                                            component={Button}
                                            onClick={() => handleItemClick(index)}
                                            sx={(theme) => ({
                                                p: 3,
                                                height: 'fit-content',
                                                width: '100%',
                                                background: 'none',
                                                textTransform: 'none',
                                                ...(selectedItemIndex === index && {
                                                    backgroundColor: 'action.selected',
                                                    borderColor:
                                                        theme.palette.mode === 'light'
                                                            ? 'primary.light'
                                                            : 'primary.dark',
                                                }),
                                                '&:hover': {
                                                    background:
                                                        theme.palette.mode === 'light'
                                                            ? 'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)'
                                                            : 'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
                                                    borderColor:
                                                        theme.palette.mode === 'light'
                                                            ? 'primary.light'
                                                            : 'primary.dark',
                                                    boxShadow:
                                                        theme.palette.mode === 'light'
                                                            ? '0px 2px 8px hsla(0, 0%, 0%, 0.1)'
                                                            : '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                                                },
                                            })}
                                        >
                                            <Box
                                                sx={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    textAlign: 'left',
                                                    flexDirection: { xs: 'column', md: 'row' },
                                                    alignItems: { md: 'center' },
                                                    gap: 2.5,
                                                }}
                                            >
                                                <Box
                                                    sx={(theme) => ({
                                                        color:
                                                            theme.palette.mode === 'light' ? 'grey.400' : 'grey.600',
                                                        ...(selectedItemIndex === index && {
                                                            color: 'primary.main',
                                                        }),
                                                    })}
                                                >
                                                    {icon}
                                                </Box>
                                                <div>
                                                    <Typography
                                                        color="text.primary"
                                                        fontWeight="medium"
                                                        gutterBottom
                                                    >
                                                        {title}
                                                    </Typography>
                                                    <Typography
                                                        color="text.secondary"
                                                        variant="body2"
                                                        sx={{ mb: 1.5 }}
                                                    >
                                                        {description}
                                                    </Typography>
                                                    {/* <Link
                                                        color="primary"
                                                        variant="body2"
                                                        fontWeight="bold"
                                                        sx={{
                                                            display: 'inline-flex',
                                                            alignItems: 'center',
                                                            '& > svg': { transition: '0.2s' },
                                                            '&:hover > svg': { transform: 'translateX(2px)' },
                                                        }}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                        }}
                                                    >
                                                        <span>Learn more</span>
                                                        <ChevronRightRoundedIcon
                                                            fontSize="small"
                                                            sx={{ mt: '1px', ml: '2px' }}
                                                        />
                                                    </Link> */}
                                                </div>
                                            </Box>
                                        </Card>
                                    </Grid>
                                ))}
                                <Grid item xs={12} sm={6}>
                                    <Button variant="contained" type='submit' color="primary"> Submit </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Card>
            </Container>
            <div>
                <div id="static-modal" data-modal-backdrop="static" tabIndex="-1" aria-hidden="true" className={`${userModel ? "flex" : "hidden"} overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[100%] max-h-full bg-slate-800/[.4]`}>
                    <div className="relative p-4 w-full max-w-2xl max-h-full">
                        <div className="relative bg-white rounded-lg shadow shadow-black">
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
                                <h3 className="text-xl font-semibold text-gray-900">
                                    Who you want to be?
                                </h3>
                            </div>
                            <div className="p-4 md:p-5 space-y-4">
                                <Grid container spacing={3}>
                                    {items.map(({ icon, title, description }, index) => (
                                        <Grid key={index} item xs={12} sm={6}>
                                            <Card
                                                component={Button}
                                                onClick={() => handleItemClick(index)}
                                                sx={(theme) => ({
                                                    p: 3,
                                                    height: 'fit-content',
                                                    width: '100%',
                                                    background: 'none',
                                                    textTransform: 'none',
                                                    ...(selectedItemIndex === index && {
                                                        backgroundColor: 'action.selected',
                                                        borderColor:
                                                            theme.palette.mode === 'light'
                                                                ? 'primary.light'
                                                                : 'primary.dark',
                                                    }),
                                                    '&:hover': {
                                                        background:
                                                            theme.palette.mode === 'light'
                                                                ? 'linear-gradient(to bottom right, hsla(210, 100%, 97%, 0.5) 25%, hsla(210, 100%, 90%, 0.3) 100%)'
                                                                : 'linear-gradient(to right bottom, hsla(210, 100%, 12%, 0.2) 25%, hsla(210, 100%, 16%, 0.2) 100%)',
                                                        borderColor:
                                                            theme.palette.mode === 'light'
                                                                ? 'primary.light'
                                                                : 'primary.dark',
                                                        boxShadow:
                                                            theme.palette.mode === 'light'
                                                                ? '0px 2px 8px hsla(0, 0%, 0%, 0.1)'
                                                                : '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                                                    },
                                                })}
                                            >
                                                <Box
                                                    sx={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        textAlign: 'left',
                                                        flexDirection: { xs: 'column', md: 'row' },
                                                        alignItems: { md: 'center' },
                                                        gap: 2.5,
                                                    }}
                                                >
                                                    <Box
                                                        sx={(theme) => ({
                                                            color:
                                                                theme.palette.mode === 'light' ? 'grey.400' : 'grey.600',
                                                            ...(selectedItemIndex === index && {
                                                                color: 'primary.main',
                                                            }),
                                                        })}
                                                    >
                                                        {icon}
                                                    </Box>
                                                    <div>
                                                        <Typography
                                                            color="text.primary"
                                                            fontWeight="medium"
                                                            gutterBottom
                                                        >
                                                            {title}
                                                        </Typography>
                                                        <Typography
                                                            color="text.secondary"
                                                            variant="body2"
                                                            sx={{ mb: 1.5 }}
                                                        >
                                                            {description}
                                                        </Typography>
                                                        <Link
                                                            color="primary"
                                                            variant="body2"
                                                            fontWeight="bold"
                                                            sx={{
                                                                display: 'inline-flex',
                                                                alignItems: 'center',
                                                                '& > svg': { transition: '0.2s' },
                                                                '&:hover > svg': { transform: 'translateX(2px)' },
                                                            }}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                            }}
                                                        >
                                                            <span>Learn more</span>
                                                            <ChevronRightRoundedIcon
                                                                fontSize="small"
                                                                sx={{ mt: '1px', ml: '2px' }}
                                                            />
                                                        </Link>
                                                    </div>
                                                </Box>
                                            </Card>
                                        </Grid>
                                    ))}
                                    <Grid item xs={12} sm={6}>
                                        <Button variant="contained" onClick={() => handleSubmit()} color="primary"> Submit </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    );
}
