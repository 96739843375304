import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
// import { useRef } from 'react';

function SupportTawk() {
    // const tawkMessengerRef = useRef();

    return (
        <div className="App">
            <TawkMessengerReact
                propertyId="666bc5d49a809f19fb3d7479"
                widgetId="1i0afphse"
                // ref={tawkMessengerRef}
                useSessionStorage={false}
                visitorData={{
                    email: "test@gmail.com",
                }}
            />
            {/* <iframe className='w-full iframe_custom_height' loading="lazy" allowFullScreen src="https://tawk.to/chat/666bc5d49a809f19fb3d7479/1i0afphse" title="description"></iframe> */}
        </div>
    );
}

export default SupportTawk;