import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import Banner from "../componets/Home/Banner";
import BrandList from "../componets/Home/BrandList";
import Faq from "../componets/Home/Faq";
import HowItWork from "../componets/Home/How_it_work";
import PerfectBrand from "../componets/Home/PerfectBrand";
import PremiumDomain from "../componets/Home/PremiumDomain";
import Reviews from "../componets/Home/Reviews";
import { Spinner } from "../componets/Loader";
import MetaTags from "../componets/MetaTags";
import Search from "../componets/Search";
import CustomerBrand from "../componets/SellerHome/CustomerBrand";
import { getPageSetting, searchDomain } from "../helper/helper";
import Footer from "./Footer";

export default function HomePage() {
    const [pageSettings, setpageSettings] = useState();
    const [bannerData, setbannerData] = useState();
    const [premiumData, setpremiumData] = useState();
    const [brandData, setbrandData] = useState();
    const [faqData, setfaqData] = useState();
    const [loading, setloading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            const response = await getPageSetting('home');
            if (!response.error) {
                setpageSettings(response.data);
            }
            let domainPromise = searchDomain({});
            domainPromise.then((res) => {
                if (res && res.data) {
                    const newArray = [];
                    for (let i = 0; i < res.data.domain_res.length; i++) {
                        const element = res.data.domain_res[i];
                        if (i >= 10) {
                            newArray.push(element);
                        }
                    }
                    const premiumAllData = response.data.data.premium_domain;
                    premiumAllData.domain_list = newArray || [];
                    setpremiumData(premiumAllData || {});
                } else {
                    setpremiumData({});
                }
            }).catch(error => {
                // console.log('Login error:', error);
            });
        }
        fetchData();
    }, [])

    useEffect(() => {
        if (pageSettings && pageSettings !== "") {
            setbannerData(pageSettings.data.banner);
            // setpremiumData(pageSettings.data.premium_domain);
            setbrandData(pageSettings.data.brand);
            setfaqData(pageSettings.data.faq);
            setloading(false);
        } else {
            setloading(true);
        }
    }, [pageSettings])

    return (
        <>
            <MetaTags title="Home" description="The smartest, fasterst way to name your web app" />
            {loading ?
                <div className="flex items-center justify-center h-screen w-full">
                    <Stack direction="row" alignItems="center" gap={2} >
                        <Spinner />
                    </Stack>
                </div>
                :
                <>
                    <Banner data={bannerData} />
                    <PremiumDomain data={premiumData} />
                    <HowItWork />
                    <CustomerBrand />
                    {/* <OurCustomers /> */}
                    <BrandList data={brandData} />
                    <Reviews title={'What our customers say'} />
                    <Faq data={faqData} />
                    <PerfectBrand />
                    <Search data={bannerData} />
                    <Footer />
                </>
            }
        </>
    );
}