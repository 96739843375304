import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Card, Container, FormControl, InputLabel, MenuItem, Select, Slide, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { adminPasswordChange, getCityList, getCountryList, getStateList, passwordChange, updateUser } from '../../helper/helper';
import { passwordDashboardVerify } from '../../helper/validate';
import { Spinner } from '../Loader';
import MetaTags from '../MetaTags';

const Accountpage = (props) => {
    const { userData } = props;
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [loading, setLoading] = useState(true);
    const [saveLoading, setsaveLoading] = useState(false);
    const [showPasswordForm, setShowPasswordForm] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [countryList, setcountryList] = useState([]);
    const [countryName, setcountryName] = useState('');
    const [stateList, setstateList] = useState();
    const [stateName, setstateName] = useState('');
    const [cityList, setcityList] = useState();
    const [cityName, setcityName] = useState('');
    const [cityInput, setcityInput] = useState(false);
    const [addressLine, setaddressLine] = useState('');

    // console.log('userData', userData)


    const handleSubmit = (e) => {
        e.preventDefault();
        setsaveLoading(true);
        let userPromise = updateUser({ first_name: firstName, last_name: lastName, email: userData.email, shipping: { city: cityName, country: countryName, line1: addressLine, state: stateName } });
        userPromise.then(() => {
            setsaveLoading(false);
            toast.success('Update Successfully..!');
        }).catch(error => {
            // console.log('Login error:', error);
            toast.error(error);
            setsaveLoading(false);
        });
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        const values = { password: newPassword, confirm_password: confirmPassword };
        const checkPassword = await passwordDashboardVerify({}, values);
        if (Object.keys(checkPassword).length === 0) {
            let passwordPromise;
            if (userData.user_type === "0") {
                passwordPromise = adminPasswordChange({ password: newPassword, oldpassword: oldPassword, email: userData.email });
            } else {
                passwordPromise = passwordChange({ password: newPassword, oldpassword: oldPassword, email: userData.email });
            }

            passwordPromise.then(() => {
                setShowPasswordForm(false);
                toast.success('Update Successfully..!');
            }).catch(error => {
                // console.log('Login error:', error);
                toast.error(error);
            });
        }
    };

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            if (userData && userData !== "") {
                setFirstName(userData.first_name);
                setLastName(userData.last_name);

                if (userData.shipping && userData.shipping !== "") {
                    setaddressLine(userData.shipping.line1);
                    setcountryName(userData.shipping.country);

                    const countryId = countryList?.filter(item => item.sortname === userData.shipping.country);
                    if (countryId && countryId.length > 0) {
                        let statePromise = getStateList(countryId[0].id);
                        statePromise.then((res) => {
                            const sortData = [...res.data].sort((a, b) => a.name.localeCompare(b.name))
                            setstateList(sortData || []);
                            setstateName(userData.shipping.state);


                            const stateId = sortData.filter(item => item.name === userData.shipping.state);
                            if (stateId && stateId.length > 0) {
                                let cityPromise = getCityList(stateId[0].id);
                                cityPromise.then((res) => {
                                    const citySortData = [...res.data].sort((a, b) => a.name.localeCompare(b.name))
                                    if (citySortData && citySortData.length === 0) {
                                        setcityInput(true);
                                    } else {
                                        setcityInput(false);
                                    }
                                    setcityList(citySortData || [])
                                    setcityName(userData.shipping.city);
                                    setLoading(false);
                                }).catch(error => {
                                    setLoading(false);
                                    // console.log('Login error:', error);
                                });
                            }
                        }).catch(error => {
                            setLoading(false);
                            // console.log('Login error:', error);
                        });
                    }
                } else {
                    setLoading(false);
                }
            }
        }
        fetchData();
    }, [userData, countryList])

    useEffect(() => {
        async function fetchFirstData() {
            let countryPromise = getCountryList();
            countryPromise.then((res) => {
                const sortData = [...res.data].sort((a, b) => a.name.localeCompare(b.name))
                setcountryList(sortData || [])
            }).catch(error => {
                // console.log('Login error:', error);
            });
        }
        fetchFirstData();
    }, [])

    const handleCountryChange = (e) => {
        setcountryName(e.target.value);
        const countryId = countryList.filter(item => item.sortname === e.target.value);

        let statePromise = getStateList(countryId[0].id);
        statePromise.then((res) => {
            const sortData = [...res.data].sort((a, b) => a.name.localeCompare(b.name))
            setstateList(sortData || [])
        }).catch(error => {
            // console.log('Login error:', error);
        });

        setcityList([]);
        setstateName('');
        setcityInput(false)
    }

    const handleStateChange = (e) => {
        setstateName(e.target.value);
        const stateId = stateList.filter(item => item.name === e.target.value);
        let cityPromise = getCityList(stateId[0].id);
        cityPromise.then((res) => {
            const sortData = [...res.data].sort((a, b) => a.name.localeCompare(b.name))
            if (sortData && sortData.length === 0) {
                setcityInput(true);
            } else {
                setcityInput(false);
            }
            setcityList(sortData || [])
        }).catch(error => {
            // console.log('Login error:', error);
        });

        setcityName('');
    }

    const handleCityChange = (e) => {
        setcityName(e.target.value);
    }

    return (
        <>
            <MetaTags title="Account" description="Manage your profile data." />
            <Toaster position='top-center' reverseOrder={false}></Toaster>
            {loading ? <div className='flex w-full justify-center h-screen items-center'> <Spinner /> </div> :
                <Container
                    sx={{
                        pt: { xs: 4, sm: 5 },
                        pb: { xs: 4, sm: 5 },
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: { xs: "100%", sm: 600 },
                        gap: { xs: 3, sm: 6 },
                    }}
                >

                    <Card
                        sx={(theme) => ({
                            p: 3,
                            height: 'fit-content',
                            width: '100%',
                            background: 'none',
                            '&:hover': {
                                boxShadow:
                                    theme.palette.mode === 'light'
                                        ? '0px 2px 8px hsla(0, 0%, 0%, 0.1)'
                                        : '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                            },
                        })}
                    >
                        <Box component="form" onSubmit={handleSubmit} sx={{ width: '100%' }}>
                            <Typography variant="h6" gutterBottom>
                                Profile
                            </Typography>
                            <TextField
                                label="First Name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                fullWidth
                                required
                                margin="normal"
                            />
                            <TextField
                                label="Last Name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                fullWidth
                                required
                                margin="normal"
                            />
                            <Stack spacing={2} sx={{ mt: 2, mb: 2 }}>
                                <TextField value={addressLine} onChange={(e) => setaddressLine(e.target.value)} required label="Address" variant="outlined" fullWidth />
                                <FormControl fullWidth>
                                    <InputLabel id="country-label">Country</InputLabel>
                                    <Select
                                        labelId="country-label"
                                        value={countryName}
                                        label="Country"
                                        required
                                        onChange={handleCountryChange}
                                    >
                                        {countryList.length > 0 && countryList.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.sortname}>{item.name}</MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                                {stateList && stateList.length > 0 &&
                                    <FormControl fullWidth>
                                        <InputLabel id="state-label">State</InputLabel>
                                        <Select
                                            labelId="state-label"
                                            value={stateName}
                                            label="State"
                                            required
                                            onChange={handleStateChange}
                                        >
                                            {stateList.length > 0 && stateList.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                }
                                {!cityInput && cityList && cityList.length > 0 &&
                                    <FormControl fullWidth>
                                        <InputLabel id="city-label">City</InputLabel>
                                        <Select
                                            labelId="city-label"
                                            value={cityName}
                                            label="City"
                                            required
                                            onChange={handleCityChange}
                                        >
                                            {cityList.length > 0 && cityList.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                }

                                {cityInput &&
                                    <TextField value={cityName} onChange={(e) => setcityName(e.target.value)} required label="City" variant="outlined" fullWidth />
                                }
                            </Stack>
                            <LoadingButton loading={saveLoading} type="submit" variant="contained" fullWidth>
                                Save Changes
                            </LoadingButton>
                        </Box>
                        <Button variant="contained" sx={{ marginTop: "10px" }} fullWidth onClick={() => setShowPasswordForm(!showPasswordForm)}>
                            {showPasswordForm ? "Cancel" : "Update Password"}
                        </Button>
                        <Slide direction="up" in={showPasswordForm} mountOnEnter unmountOnExit>
                            <Box component="form" onSubmit={handlePasswordSubmit} sx={{ width: '100%', mt: 2 }}>
                                <TextField
                                    label="Old Password"
                                    type="password"
                                    value={oldPassword}
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    fullWidth
                                    required
                                    margin="normal"
                                />
                                <TextField
                                    label="New Password"
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                    fullWidth
                                    margin="normal"
                                />
                                <TextField
                                    label="Confirm Password"
                                    type="password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                    fullWidth
                                    margin="normal"
                                />
                                <Button type="submit" variant="contained" fullWidth>
                                    Update Password
                                </Button>
                            </Box>
                        </Slide>
                    </Card>
                </Container >
            }
        </>
    );
};

export default Accountpage;