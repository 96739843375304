import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Box, Checkbox, Collapse, Container, Divider, Drawer, FormControl, FormControlLabel, IconButton, InputLabel, ListItemButton, ListItemText, MenuItem, Select, Stack, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import DomainList from "../componets/DomainList";
import { RightArrow } from '../componets/Icon';
import { Spinner } from '../componets/Loader';
import MetaTags from '../componets/MetaTags';
import { getAllCategory, searchDomain } from "../helper/helper";
import Footer from './Footer';

export default function SearchDomain() {
    const [loading, setloading] = useState(true);
    const [mobileFilter, setmobileFilter] = useState(false);
    const [query, setquery] = useState("");
    const [view, setview] = useState("");
    const [category, setcategory] = useState("");
    const [categoryList, setcategoryList] = useState([]);
    const [length, setlength] = useState('');
    const [priceRange, setpriceRange] = useState('');
    const [topListDomain, settopListDomain] = useState('');
    const [searchParams, setsearchParams] = useSearchParams({});
    const [domainData, setdomainData] = useState([]);
    const [sortBy, setsortBy] = useState('relevance');
    const [currentPage, setcurrentPage] = useState(1);
    const [totalPages, settotalPages] = useState(1);
    const [nextPrevious, setnextPrevious] = useState(0);
    const [domainLoading, setdomainLoading] = useState(false);
    const [sidebarOpen, setsidebarOpen] = useState({
        category: true,
        tld: false,
        price: false,
        length: false,
    });
    const navigate = useNavigate();

    const DrawerHeader = styled('div')(({ theme }) => ({
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    }));

    const tldList = ['com', 'in', 'org', 'co', 'net', 'io'];
    const priceList = [
        { title: "Below $2000", value: "0-2000" },
        { title: "From $2K to $5K", value: "2000-5000" },
        { title: "From $5K to $10K", value: "5000-10000" },
        { title: "From $10K to $50K", value: "10000-50000" },
        { title: "Greater Than 50K", value: "50000-999999" },
    ];
    const lengthList = [
        { title: "3 Letters", value: "3" },
        { title: "4 Letters", value: "4" },
        { title: "5 Letters", value: "5" },
        { title: "Short", value: "short" },
        { title: "Medium", value: "medium" },
    ];

    const handleSideMenu = (item, value) => {
        setsidebarOpen({ ...sidebarOpen, [item]: value });
    };

    const handleSortBy = (event) => {
        setsortBy(event.target.value);
    };

    const handleChange = (e) => {
        setquery(e.target.value);
    };

    const handleCategoryChange = (id) => {
        if (category && category !== "") {
            const categoryArray = category.split(',');
            if (categoryArray.includes(id)) {
                const updatedCategories = categoryArray.filter(catId => catId !== id);
                setcategory(updatedCategories.join(','));
            } else {
                setcategory(category + ',' + id);
            }
        } else {
            setcategory(id);
        }
    }

    const handletldChange = (id) => {
        if (topListDomain && topListDomain !== "") {
            const topListDomainArray = topListDomain.split(',');
            if (topListDomainArray.includes(id)) {
                const updatedCategories = topListDomainArray.filter(tldId => tldId !== id);
                settopListDomain(updatedCategories.join(','));
            } else {
                settopListDomain(topListDomain + ',' + id);
            }
        } else {
            settopListDomain(id);
        }
    }

    const handlePriceChange = (value) => {
        if (priceRange && priceRange !== "") {
            if (priceRange.includes(value)) {
                setpriceRange('');
            } else {
                setpriceRange(value);
            }
        } else {
            setpriceRange(value);
        }
    }

    const handleLengthChange = (value) => {
        if (length && length !== "") {
            if (length.includes(value)) {
                setlength('');
            } else {
                setlength(value);
            }
        } else {
            setlength(value);
        }
    }

    const isExactTldPresent = (tld, topListDomainString) => {
        if (!topListDomainString) return false;
        const tldArray = topListDomainString.split(',');
        return tldArray.includes(tld);
    };

    const sortDomains = (domains, sortValue) => {
        switch (sortValue) {
            case 'ascending':
                return [...domains].sort((a, b) => a.domain_name.localeCompare(b.domain_name));

            case 'descending':
                return [...domains].sort((a, b) => b.domain_name.localeCompare(a.domain_name));

            case 'price_low_to_high':
                return [...domains].sort((a, b) => a.price - b.price);

            case 'price_high_to_low':
                return [...domains].sort((a, b) => b.price - a.price);

            case 'relevance':
            default:
                return domains;
        }
    };

    useEffect(() => {
        setdomainLoading(true);

        const intervalId = setTimeout(() => {
            const obj = {};
            if (query !== "") {
                obj.key = query;
            }
            if (category !== "") {
                obj.category = category;
            }
            if (length !== "") {
                obj.length = length;
            }
            if (priceRange !== "") {
                obj.price_range = priceRange;
            }
            if (topListDomain !== "") {
                obj.tld = topListDomain;
            }
            if (view !== "") {
                obj.view = view;
            }
            if (currentPage === 0 || currentPage === 1) {
                setsearchParams(obj);
            }
            obj.page = currentPage;
            let domainPromise = searchDomain(obj);
            domainPromise.then((res) => {
                if (res && res.data) {
                    const newData = sortDomains(res.data.domain_res, sortBy);
                    settotalPages(res.data.total_pages)
                    setcurrentPage(res.data.current_page);
                    setdomainData(newData);
                } else {
                    setdomainData([]);
                }
            }).catch(error => {
                // console.log('Login error:', error);
            });
            if (currentPage !== 0 && currentPage !== 1) {
                setsearchParams(obj);
            }

            setdomainLoading(false);
        }, 500);

        return () => clearTimeout(intervalId);
    }, [query, category, length, priceRange, topListDomain, sortBy, nextPrevious, view])

    useEffect(() => {
        async function fetchFirstData() {
            const obj = {};
            if (searchParams && searchParams.get('view') && searchParams.get('view') !== "") {
                setview(searchParams.get('view'));
                obj.value = searchParams.get('value');
            }
            if (searchParams && searchParams.get('key') && searchParams.get('key') !== "") {
                setquery(searchParams.get('key'));
                obj.key = searchParams.get('key');
            }
            if (searchParams && searchParams.get('category') && searchParams.get('category') !== "") {
                setcategory(searchParams.get('category'));
                obj.category = searchParams.get('category');
            }
            if (searchParams && searchParams.get('length') && searchParams.get('length') !== "") {
                setlength(searchParams.get('length'));
                obj.length = searchParams.get('length');
            }
            if (searchParams && searchParams.get('priceRange') && searchParams.get('priceRange') !== "") {
                setpriceRange(searchParams.get('priceRange'));
                obj.price_range = searchParams.get('priceRange');
            }
            if (searchParams && searchParams.get('tld') && searchParams.get('tld') !== "") {
                settopListDomain(searchParams.get('tld'));
                obj.tld = searchParams.get('tld');
            }

            let domainPromise = searchDomain(obj);
            domainPromise.then((res) => {
                if (res && res.data) {
                    setdomainData(res.data.domain_res);
                    settotalPages(res.data.total_pages)
                    setcurrentPage(res.data.current_page);
                } else {
                    setdomainData([]);
                }
            }).catch(error => {
                // console.log('Login error:', error);
            });

            const categoryData = await getAllCategory();
            if (categoryData && categoryData.data) {
                setcategoryList(categoryData.data);
            } else {
                setcategoryList([]);
            }
            setloading(false);
        }
        fetchFirstData();
    }, [])

    useEffect(() => {
        async function fetchParamsData() {
            if (searchParams && searchParams.get('view') && searchParams.get('view') !== "") {
                setview(searchParams.get('view'));
                setquery('');
                setcategory('');
                setlength('');
                setpriceRange('');
                settopListDomain('');
            }
        }
        fetchParamsData();
    }, [searchParams])
    // console.log('domainData', domainData);

    const sideDrawert = (
        <>
            <ListItemButton onClick={() => { handleSideMenu('category', !sidebarOpen.category) }}>
                <ListItemText primary="Category" />
                {sidebarOpen.category ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={sidebarOpen.category} timeout="auto" unmountOnExit>
                <div className='flex flex-col'>
                    {categoryList && categoryList.length > 0 &&
                        categoryList.map((item, index) => {
                            return (
                                <FormControlLabel sx={{ ml: 2 }} key={index} label={item.name} control={<Checkbox checked={category.includes(item._id) ? true : false} onChange={() => { handleCategoryChange(item._id) }} />} />
                            )
                        })
                    }
                </div>
            </Collapse>
            <ListItemButton onClick={() => { handleSideMenu('tld', !sidebarOpen.tld) }}>
                <ListItemText primary="Top Leavel Domain" />
                {sidebarOpen.tld ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={sidebarOpen.tld} timeout="auto" unmountOnExit>
                <div className='flex flex-col'>
                    {tldList && tldList.length > 0 &&
                        tldList.map((item, index) => {
                            return (
                                <FormControlLabel sx={{ ml: 2 }} key={index} label={'.' + item} control={<Checkbox checked={isExactTldPresent(item, topListDomain)} onChange={() => { handletldChange(item) }} />} />
                            )
                        })
                    }
                </div>
            </Collapse>
            <ListItemButton onClick={() => { handleSideMenu('length', !sidebarOpen.length) }}>
                <ListItemText primary="Domain Length" />
                {sidebarOpen.length ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={sidebarOpen.length} timeout="auto" unmountOnExit>
                <div className='flex flex-col'>
                    {lengthList && lengthList.length > 0 &&
                        lengthList.map((item, index) => {
                            return (
                                <FormControlLabel sx={{ ml: 2 }} key={index} label={item.title} control={<Checkbox checked={length.includes(item.value) ? true : false} onChange={() => { handleLengthChange(item.value) }} />} />
                            )
                        })
                    }
                </div>
            </Collapse>
            <ListItemButton onClick={() => { handleSideMenu('price', !sidebarOpen.price) }}>
                <ListItemText primary="Price" />
                {sidebarOpen.price ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={sidebarOpen.price} timeout="auto" unmountOnExit>
                <div className='flex flex-col'>
                    {priceList && priceList.length > 0 &&
                        priceList.map((item, index) => {
                            return (
                                <FormControlLabel sx={{ ml: 2 }} key={index} label={item.title} control={<Checkbox checked={priceRange.includes(item.value) ? true : false} onChange={() => { handlePriceChange(item.value) }} />} />
                            )
                        })
                    }
                </div>
            </Collapse>
        </>
    );
    const handlePageChange = (page) => {
        setcurrentPage(page);
        setnextPrevious((nextPrevious + 1))
    };

    return (
        <>
            <MetaTags title="Search" description="Search for domains and more." />
            {loading ?
                <div className="flex items-center justify-center h-screen w-full">
                    <Stack direction="row" alignItems="center" gap={2} >
                        <Spinner />
                    </Stack>
                </div>
                :
                <>
                    <Container
                        sx={{
                            pt: { xs: 4, sm: 5 },
                            pb: { xs: 4, sm: 5 },
                            position: 'relative',
                            width: "100%",
                            maxWidth: "1400px !important",
                            margin: "auto",
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: { xs: 3, sm: 6 },
                        }}
                    >
                        <Box sx={{
                            width: '100%',
                            textAlign: "center",
                            color: "#000",
                        }}>
                            <Typography variant="h3" sx={{ fontSize: { xs: "35px", sm: "45px" } }} fontWeight={600} gutterBottom>
                                {query || "Search"} Domain Name
                            </Typography>
                            <Typography variant="h6" sx={{ fontSize: { xs: "14px", sm: "18px" } }} gutterBottom>
                                Discover Top-Notch {query ? `"${query}"` : ""} Brandable Domains for Sale! Explore our hand-curated collection of exceptional domain names, perfect for tech startups, testing solutions, & educational platforms. Expertly selected by branding gurus, these domains are ready for immediate purchase!
                            </Typography>
                        </Box>
                        <div className="mainbox search_page">
                            <div className="iconContainer text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"> <path d="M19.35 20.9251L13.325 14.9001C12.825 15.3334 12.2417 15.6709 11.575 15.9126C10.9083 16.1543 10.2 16.2751 9.45 16.2751C7.65 16.2751 6.125 15.6501 4.875 14.4001C3.625 13.1501 3 11.6418 3 9.8751C3 8.10843 3.625 6.6001 4.875 5.3501C6.125 4.1001 7.64167 3.4751 9.425 3.4751C11.1917 3.4751 12.6958 4.1001 13.9375 5.3501C15.1792 6.6001 15.8 8.10843 15.8 9.8751C15.8 10.5918 15.6833 11.2834 15.45 11.9501C15.2167 12.6168 14.8667 13.2418 14.4 13.8251L20.475 19.8501C20.625 19.9834 20.7 20.1543 20.7 20.3626C20.7 20.5709 20.6167 20.7584 20.45 20.9251C20.3 21.0751 20.1167 21.1501 19.9 21.1501C19.6833 21.1501 19.5 21.0751 19.35 20.9251ZM9.425 14.7751C10.775 14.7751 11.925 14.2959 12.875 13.3376C13.825 12.3793 14.3 11.2251 14.3 9.8751C14.3 8.5251 13.825 7.37093 12.875 6.4126C11.925 5.45426 10.775 4.9751 9.425 4.9751C8.05833 4.9751 6.89583 5.45426 5.9375 6.4126C4.97917 7.37093 4.5 8.5251 4.5 9.8751C4.5 11.2251 4.97917 12.3793 5.9375 13.3376C6.89583 14.2959 8.05833 14.7751 9.425 14.7751Z" fill="currentColor" /> </svg>
                            </div>
                            <input className="search_input focus:text-left" placeholder="Search Your Domain Name Here" value={query} onChange={handleChange} type="text" />
                            <div className="bg-[#FF6955] iconContainer rounded_icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="13" viewBox="0 0 24 13" fill="none"> <g clipPath="url(#clip0_1_71)"> <path d="M17.5384 0.96167L23.0769 6.50013M23.0769 6.50013L17.5384 12.0386M23.0769 6.50013H0.923065" stroke="currentColor" strokeWidth="1.38462" strokeLinecap="round" strokeLinejoin="round" /> </g> <defs> <clipPath id="clip0_1_71"> <rect width="24" height="12.9231" fill="white" transform="translate(0 0.0385742)" /> </clipPath> </defs> </svg>
                            </div>
                        </div>
                        {view !== "" && view === "all" ?
                            <div className='mt-5 flex flex-wrap justify-start p-4 items-center max-w-5xl m-auto'>
                                {categoryList && categoryList.length > 0 && categoryList.map((item, index) => {
                                    return (
                                        <div key={index} className='mt-3 ml-3 w-full md:w-auto'>
                                            <div onClick={() => { handleCategoryChange(item._id); setview(''); }} className='w-full flex justify-between cursor-pointer items-center bg-white rounded-xl p-4 custom_svg_color_main hover:shadow-xl'>
                                                {item.name}
                                                <div className='custom_svg_color rounded-full p-1 ml-2'>
                                                    <RightArrow />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            :
                            <div className="w-full">
                                <div className="flex items-center justify-between md:justify-end  m-auto">
                                    <div className='flex md:hidden'>
                                        <IconButton sx={{ color: "#000", fontSize: "20px" }} onClick={() => setmobileFilter(true)}>
                                            <FilterAltIcon fontSize='medium' />
                                            Filter
                                        </IconButton>
                                    </div>
                                    {domainData && domainData.length > 1 &&
                                        <FormControl sx={{ m: 1, minWidth: 80 }}>
                                            <InputLabel sx={{ mr: 2 }} id="sort-buy-labell">Sort by</InputLabel>
                                            <Select
                                                labelId="sort-buy-label"
                                                id="sort-buy"
                                                value={sortBy}
                                                onChange={handleSortBy}
                                                autoWidth
                                                label="Sort by"
                                            >
                                                <MenuItem value="relevance">Relevance</MenuItem>
                                                <MenuItem value={"ascending"}>Ascending</MenuItem>
                                                <MenuItem value={"descending"}>Descending</MenuItem>
                                                <MenuItem value={"price_low_to_high"}>Price - Low to High</MenuItem>
                                                <MenuItem value={"price_high_to_low"}>Price - High to Low</MenuItem>
                                            </Select>
                                        </FormControl>
                                    }
                                </div>
                                <div className="flex flex-wrap md:flex-nowrap justify-center items-start">
                                    <div className="!hidden md:!block w-[20%] md:min-w-64 relative mt-[40px]">
                                        <aside id="default-sidebar" className="w-full h-full transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
                                            <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 rounded-[10px]">
                                                <div>
                                                    {sideDrawert}
                                                </div>
                                            </div>
                                        </aside>
                                    </div>
                                    <div className='block md:hidden'>
                                        <Drawer
                                            anchor="left"
                                            open={mobileFilter}
                                            onClose={() => { setmobileFilter(false) }}
                                            ModalProps={{
                                                keepMounted: true,
                                            }}
                                            PaperProps={{
                                                sx: {
                                                    display: { xs: "block", md: "none" },
                                                    backgroundColor: "white",
                                                    color: "black",
                                                    padding: "15px 0",
                                                    width: 310,
                                                    boxSizing: "border-box",
                                                },
                                            }}
                                        >
                                            <DrawerHeader sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
                                                <Typography variant="h5" align="center">Filter</Typography>
                                                <IconButton onClick={() => { setmobileFilter(false); }}>
                                                    <ChevronLeftIcon />
                                                </IconButton>
                                            </DrawerHeader>
                                            <Divider />
                                            {sideDrawert}
                                        </Drawer>
                                    </div>
                                    <div className="w-full md:w-[80%]">
                                        {domainLoading ?
                                            <div className="flex items-start mt-10 justify-center h-screen w-full">
                                                <Stack direction="row" alignItems="center" gap={2} >
                                                    <Spinner />
                                                </Stack>
                                            </div>
                                            :
                                            <>
                                                {domainData && domainData.length > 0 ?
                                                    <DomainList data={domainData} />
                                                    :
                                                    <Typography variant='h5' sx={{ mt: 5, textAlign: 'center' }}>No Domain Found</Typography>
                                                }
                                            </>
                                        }
                                        {totalPages > 1 &&
                                            <>
                                                <Pagination
                                                    currentPage={currentPage}
                                                    totalPages={totalPages}
                                                    onPageChange={handlePageChange}
                                                />
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </Container>
                </>
            }
            <Footer />
        </>
    );
}

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const getPageNumbers = () => {
        let startPage, endPage;

        if (totalPages <= 3) {
            startPage = 1;
            endPage = totalPages;
        } else {
            if (currentPage === 1) {
                startPage = 1;
                endPage = 3;
            } else if (currentPage === totalPages) {
                startPage = totalPages - 2;
                endPage = totalPages;
            } else {
                startPage = currentPage - 1;
                endPage = currentPage + 1;
            }
        }

        const pages = [];
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        return pages;
    };

    return (
        <div className="flex justify-center mt-4">
            {currentPage > 1 && (
                <button
                    onClick={() => onPageChange(currentPage - 1)}
                    className="px-3 py-1 border border-gray-300 rounded-l-md hover:bg-gray-100"
                >
                    Previous
                </button>
            )}
            {getPageNumbers().map((page) => (
                <button
                    key={page}
                    onClick={() => onPageChange(page)}
                    className={`px-3 py-1 border border-gray-300 ${page === currentPage ? 'bg-blue-500 text-white' : 'hover:bg-gray-100'
                        }`}
                >
                    {page}
                </button>
            ))}
            {currentPage < totalPages && (
                <button
                    onClick={() => onPageChange(currentPage + 1)}
                    className="px-3 py-1 border border-gray-300 rounded-r-md hover:bg-gray-100"
                >
                    Next
                </button>
            )}
        </div>
    );
};