import { Card } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { UserContext } from '../App';
import { updateWishlist } from '../helper/helper';
import { FillHeart, Heart, LeftArrow, RightArrow } from './Icon';
// import { Link } from 'react-router-dom';

export default function DomainListSlider(props) {
    const { user, setUser } = useContext(UserContext);
    const { data, slide, blockId } = props;
    const [domainList, setdomainList] = useState();
    const [randomStyles, setRandomStyles] = useState([]);
    const [wishlist, setwishlist] = useState([]);
    const [wishlistShow, setwishlistShow] = useState(true);
    const navigate = useNavigate();
    const [slideShow, setslideShow] = useState(1);

    const [styleData, setStyleData] = useState([
        { backgroundColor: '#F2EED9' },
        { backgroundColor: '#DFF2D9' },
        { backgroundColor: '#D9EEF2' },
        { backgroundColor: '#D9E1F2' },
        { backgroundColor: '#D9E1F2' },
        { backgroundColor: '#EAD9F2' },
    ]);

    useEffect(() => {
        if (data && data !== "" && data !== undefined) {
            setdomainList(data);
        }
        setslideShow(slide || 1)
    }, [data, slide]);

    useEffect(() => {
        if (user && user !== "" && user !== undefined) {
            setwishlist(user.wishlist);
            if (user.user_type === "2") {
                setwishlistShow(true);
            } else {
                setwishlistShow(false);
            }
        } else {
            setwishlist([]);
            setwishlistShow(true);
        }
    }, [user]);


    useEffect(() => {
        if (styleData && styleData.length > 0) {
            const newRandomStyles = styleData.sort(() => 0.5 - Math.random());
            setRandomStyles(newRandomStyles);
        } else {
            setStyleData([
                { backgroundColor: '#F2EED9' },
                { backgroundColor: '#DFF2D9' },
                { backgroundColor: '#D9EEF2' },
                { backgroundColor: '#D9E1F2' },
                { backgroundColor: '#D9E1F2' },
                { backgroundColor: '#EAD9F2' },
            ])
        }

    }, [styleData]);

    const handleClickWishlist = (id) => {
        if (user && user !== "") {
            let newWishlist = [...wishlist];
            if (wishlist.includes(id)) {
                newWishlist = wishlist.filter((tldId) => tldId !== id);
            } else {
                newWishlist.push(id);
            }
            let wishlistPromise = updateWishlist({ wishlist: newWishlist });
            wishlistPromise.then((res) => {
                setwishlist(newWishlist);
                setUser(preData => ({ ...preData, wishlist: newWishlist }))
            }).catch(error => {
                // console.log('Login error:', error);
            });

        } else {
            navigate('/login');
        }
    }

    const isItemInWishlist = (itemId, wishlistArray) => {
        return wishlistArray.includes(itemId);
    };

    // console.log('domainList', domainList)

    return (
        <div className='relative w-full overflow-hidden'>
            {domainList && domainList.length > 0 &&
                <>
                    <div className={`prev-${blockId} mr-3 text-[#425CD9] cursor-pointer absolute top-[45%] left-0 rounded-full p-1 md:p-2 bg-[#F0F2FF]`}>
                        <LeftArrow />
                    </div>
                    <div className={`next-${blockId} cursor-pointer text-[#425CD9] absolute top-[45%] right-0 rounded-full p-1 md:p-2 bg-[#F0F2FF]`}>
                        <RightArrow />
                    </div>
                    <div className='p-12'>
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={30}
                            breakpoints={{
                                576: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: slideShow,
                                },
                            }}
                            loop={true}
                            navigation={{
                                prevEl: `.prev-${blockId}`,
                                nextEl: `.next-${blockId}`,
                            }}
                        >
                            {domainList && domainList.map((item, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <Card
                                            sx={{
                                                height: 170,
                                                width: "100%",
                                                minHeight: { xs: 170, sm: 220 },
                                                backgroundColor: randomStyles[index % randomStyles.length].backgroundColor,
                                                color: "#000",
                                                padding: '0 10px',
                                                textTransform: "capitalize",
                                                fontSize: (item?.name).length > 15 ? '1.1rem' : '1.3rem',
                                                wordBreak: 'break-word',
                                                transition: 'transform 0.3s ease'
                                            }}
                                        >
                                            <div className='flex justify-center items-center h-full relative'>
                                                {wishlistShow &&
                                                    <div className='absolute text-[#425CD9] top-2 right-2 cursor-pointer' onClick={() => { handleClickWishlist(item?._id); }}>
                                                        {isItemInWishlist(item?._id, wishlist) ? (
                                                            <FillHeart />
                                                        ) : (
                                                            <Heart />
                                                        )}
                                                    </div>
                                                }
                                                <div className='flex flex-col items-center justify-center'>
                                                    <Link to={`/domain/${item?.name}`}>
                                                        {item?.name}
                                                    </Link>
                                                    <span className='mt-4'>
                                                        ${item?.price}
                                                    </span>
                                                </div>
                                            </div>
                                        </Card>
                                    </SwiperSlide>
                                )
                            })}
                        </Swiper>
                    </div>
                </>
            }
        </div>
    );
}