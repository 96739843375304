import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React from 'react';
import MetaTags from '../MetaTags';
import SettingHome from './Settings/SettingHome';

function Settings() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <MetaTags title="Admin Setting" description="Manage your home settings data." />
            <Tabs value={value} onChange={handleChange} aria-label="icon label tabs example">
                <Tab icon={<HomeIcon />} label="Home" />
                <Tab icon={<InfoIcon />} label="About" />
            </Tabs>
            {value === 0 && <SettingHome />}

        </>
    );
};

export default Settings