import { Button } from "@mui/material";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  // const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/dashboard`,
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      toast.error(error.message);
      // setMessage(error.message);
    } else {
      toast.error("An unexpected error occured your address.");
    }

    setIsProcessing(false);
  };

  return (
    <>
      <Toaster position='top-center' reverseOrder={false}></Toaster>
      <form id="payment-form" className="mt-4" onSubmit={handleSubmit}>
        <div className="mb-5">
          <PaymentElement id="payment-element" />
        </div>
        <Button disabled={isProcessing || !stripe || !elements} id="submit" type="submit" variant="contained" sx={{ mt: 2, color: "#fff", background: "linear-gradient(90deg, rgba(255,122,142,1) 10%, rgba(155,186,249,1) 86%)" }}>{isProcessing ? "Processing ... " : "Pay now"}</Button>
        {/* <button disabled={isProcessing || !stripe || !elements} id="submit">
        <span id="button-text" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
      </button> */}
        {/* Show any error or success messages */}
        {/* {message && <div id="payment-message">{message}</div>} */}
      </form>
    </>
  );
}