import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useEffect, useState } from "react";
import { getAllCategory, getPageSetting } from "../../../helper/helper";
import { Spinner } from '../../Loader';

const SaveButton = ({ onSave }) => {
    return (
        <Button sx={{ marginTop: "20px" }} variant="contained" color="primary" onClick={onSave}>
            Save
        </Button>
    );
};

const BannerComponent = ({ data, setData, categoryList }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(prevData => ({
            ...prevData,
            banner: {
                ...prevData.banner,
                [name]: value
            }
        }));
    };

    const addWord = () => {
        setData(prevData => ({
            ...prevData,
            banner: {
                ...prevData.banner,
                words: [...prevData.banner.words, '']
            }
        }));
    };

    const handleCategoryChange = (index, e) => {
        const { value } = e.target;
        const newCategories = data.category.map((item, i) => (
            i === index ? { name: value } : item
        ));
        setData(prevData => ({
            ...prevData,
            banner: {
                ...prevData.banner,
                category: newCategories
            }
        }));
    };

    // const addCategory = () => {
    //     setData(prevData => ({
    //         ...prevData,
    //         banner: {
    //             ...prevData.banner,
    //             category: [...prevData.banner.category, { name: '' }]
    //         }
    //     }));
    // };

    const removeCategory = (index) => {
        const newCategories = data.category.filter((_, i) => i !== index);
        setData(prevData => ({
            ...prevData,
            banner: {
                ...prevData.banner,
                category: newCategories
            }
        }));
    };

    const handleWordChange = (index, e) => {
        const { value } = e.target;
        const newWords = data.words.map((word, i) => (
            i === index ? value : word
        ));
        setData(prevData => ({
            ...prevData,
            banner: {
                ...prevData.banner,
                words: newWords
            }
        }));
    };

    const removeWord = (index) => {
        const newWords = data.words.filter((_, i) => i !== index);
        setData(prevData => ({
            ...prevData,
            banner: {
                ...prevData.banner,
                words: newWords
            }
        }));
    };

    // const newArray = [];
    // for (let i = 0; i < data.category.length; i++) {
    //     const element = data.category[i];
    //     for (let j = 0; j < categoryList.length; j++) {
    //         const ct = categoryList[j];
    //         if (ct.name === element.name) {
    //             const obj = {};
    //             obj.name = ct.name;
    //             obj.id = ct._id;
    //             newArray.push(obj)
    //         }
    //     }
    // }
    // console.log(newArray, 'newarray')

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Banner Title"
                    name="banner_title"
                    variant='standard'
                    value={data.banner_title}
                    onChange={handleChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Banner Subtitle"
                    variant='standard'
                    name="banner_subtitle"
                    value={data.banner_subtitle}
                    onChange={handleChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ border: "1px solid", marginTop: '20px', padding: "20px 0px 20px 40px !important" }}>
                <h3 className='mb-5 text-black bold'>Categories</h3>
                {data.category.map((category, index) => (
                    <Grid container spacing={2} key={index} sx={{ width: '100%' }}>
                        <Grid item xs={10}>
                            <TextField
                                label={`Category ${index + 1}`}
                                variant='standard'
                                sx={{ marginBottom: "10px" }}
                                value={category.name}
                                onChange={(e) => handleCategoryChange(index, e)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton color='error' onClick={() => removeCategory(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
                {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={addCategory}
                    startIcon={<AddIcon />}
                >
                    Add Category
                </Button> */}
            </Grid>
            <Grid item xs={12} sm={6} sx={{ border: "1px solid", marginTop: '20px', padding: "20px 0px 20px 40px !important" }}>
                <h3 className='mb-5 text-black bold'>Words</h3>
                {data.words.map((word, index) => (
                    <Grid container spacing={2} key={index} sx={{ width: '100%' }}>
                        <Grid item xs={10}>
                            <TextField
                                label={`Word ${index + 1}`}
                                value={word}
                                variant='standard'
                                sx={{ marginBottom: "10px" }}
                                onChange={(e) => handleWordChange(index, e)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton color='error' onClick={() => removeWord(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={addWord}
                    startIcon={<AddIcon />}
                >
                    Add Word
                </Button>
            </Grid>
        </Grid>
    );
};


const PremiumDomainComponent = ({ data, setData }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(prevData => ({
            ...prevData,
            premium_domain: {
                ...prevData.premium_domain,
                [name]: value
            }
        }));
    };

    const handleDomainChange = (index, e) => {
        const { name, value } = e.target;
        const newDomainList = data.domain_list.map((domain, i) => (
            i === index ? { ...domain, [name]: value } : domain
        ));
        setData(prevData => ({
            ...prevData,
            premium_domain: {
                ...prevData.premium_domain,
                domain_list: newDomainList
            }
        }));
    };

    const handleAddDomain = () => {
        setData(prevData => ({
            ...prevData,
            premium_domain: {
                ...prevData.premium_domain,
                domain_list: [...prevData.premium_domain.domain_list, { name: "" }]
            }
        }));
    };

    const handleDeleteDomain = (index) => {
        const newDomainList = data.domain_list.filter((_, i) => i !== index);
        setData(prevData => ({
            ...prevData,
            premium_domain: {
                ...prevData.premium_domain,
                domain_list: newDomainList
            }
        }));
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Premium Domain Title"
                    name="title"
                    variant='standard'
                    sx={{ marginBottom: "10px" }}
                    value={data.title}
                    onChange={handleChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Premium Domain Subtitle"
                    name="subtitle"
                    value={data.subtitle}
                    variant='standard'
                    sx={{ marginBottom: "10px" }}
                    onChange={handleChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ border: "1px solid", marginTop: '20px', padding: "20px 0px 20px 40px !important" }}>
                <h3 className='mb-5 text-black bold'>Domains</h3>
                {data.domain_list.map((domain, index) => (
                    <Grid container spacing={2} key={index} alignItems="center">
                        <Grid item xs={10}>
                            <TextField
                                label={`Domain ${index + 1}`}
                                name="name"
                                variant='standard'
                                sx={{ marginBottom: "10px" }}
                                value={domain.name}
                                onChange={(e) => handleDomainChange(index, e)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton color='error' onClick={() => handleDeleteDomain(index)} aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
                <Grid>
                    <Button variant="contained" onClick={handleAddDomain}>Add Domain</Button>
                </Grid>
            </Grid>
        </Grid>
    );
};


const FAQComponent = ({ data, setData }) => {
    const handleQuestionChange = (index, e) => {
        const { name, value } = e.target;
        const newFaq = data.map((item, i) => (
            i === index ? { ...item, [name]: value } : item
        ));
        setData(prevData => ({
            ...prevData,
            faq: newFaq
        }));
    };

    const handleAddFaq = () => {
        const newFaq = { question: "", answer: "" };
        setData(prevData => ({
            ...prevData,
            faq: [...prevData.faq, newFaq]
        }));
    };

    const handleRemoveFaq = (index) => {
        const newFaq = data.filter((_, i) => i !== index);
        setData(prevData => ({
            ...prevData,
            faq: newFaq
        }));
    };

    return (
        <div>
            {data.map((faq, index) => (
                <div key={index} className='flex justify-center items-center flex-nowrap w-80 md:w-[700px]'>
                    <Accordion sx={{ border: "unset", width: "100%" }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <TextField
                                label="Question"
                                name="question"
                                value={faq.question}
                                onChange={(e) => handleQuestionChange(index, e)}
                                fullWidth
                            />

                        </AccordionSummary>
                        <AccordionDetails>
                            <TextField
                                label="Answer"
                                name="answer"
                                value={faq.answer}
                                onChange={(e) => handleQuestionChange(index, e)}
                                fullWidth
                            />
                        </AccordionDetails>
                    </Accordion>
                    <IconButton color='error' onClick={() => handleRemoveFaq(index)}>
                        <DeleteIcon />
                    </IconButton>
                </div>
            ))}
            <Button
                variant="contained"
                color="primary"
                sx={{ marginLeft: "20px" }}
                startIcon={<AddIcon />}
                onClick={handleAddFaq}
            >
                Add FAQ
            </Button>
        </div>
    );
};


const BrandComponent = ({ data, setData }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setData(prevData => ({
            ...prevData,
            brand: {
                ...prevData.brand,
                [name]: value
            }
        }));
    };

    const handleBrandListChange = (index, e) => {
        const { name, value } = e.target;
        const newBrandList = data.brand_list.map((item, i) => (
            i === index ? { ...item, [name]: value } : item
        ));
        setData(prevData => ({
            ...prevData,
            brand: {
                ...prevData.brand,
                brand_list: newBrandList
            }
        }));
    };

    const handleAddBrand = () => {
        const newBrand = { name: "", img: "" };
        setData(prevData => ({
            ...prevData,
            brand: {
                ...prevData.brand,
                brand_list: [...prevData.brand.brand_list, newBrand]
            }
        }));
    };

    const handleRemoveBrand = (index) => {
        const newBrandList = data.brand_list.filter((_, i) => i !== index);
        setData(prevData => ({
            ...prevData,
            brand: {
                ...prevData.brand,
                brand_list: newBrandList
            }
        }));
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextField
                    label="Brand Title"
                    name="title"
                    variant='standard'
                    sx={{ marginBottom: "10px" }}
                    value={data.title}
                    onChange={handleChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Brand Subtitle"
                    name="subtitle"
                    variant='standard'
                    sx={{ marginBottom: "10px" }}
                    value={data.subtitle}
                    onChange={handleChange}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ border: "1px solid", marginTop: '20px', padding: "20px 0px 20px 40px !important" }}>
                <h3 className='mb-5 text-black bold'>Brand</h3>
                {data.brand_list.map((brand, index) => (
                    <Grid container spacing={2} key={index} alignItems="center">
                        <Grid item xs={5}>
                            <TextField
                                label="Brand Name"
                                name="name"
                                value={brand.name}
                                variant='standard'
                                sx={{ marginBottom: "10px" }}
                                onChange={(e) => handleBrandListChange(index, e)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                label="Brand Image"
                                name="img"
                                variant='standard'
                                sx={{ marginBottom: "10px" }}
                                value={brand.img}
                                onChange={(e) => handleBrandListChange(index, e)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <IconButton color="error" onClick={() => handleRemoveBrand(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
                <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddBrand}>
                    Add Brand
                </Button>
            </Grid>
        </Grid>

    );
};


export default function SettingHome() {
    const [pageSettings, setpageSettings] = useState();
    // const [bannerData, bannerDatasetbannerData] = useState();
    // const [premiumData, setpremiumData] = useState();
    // const [brandData, setbrandData] = useState();
    // const [faqData, setfaqData] = useState();
    const [loading, setloading] = useState(true);
    const [categoryList, setcategoryList] = useState([]);

    // const handleDataChange = (newData) => {
    //     setpageSettings(newData);
    // };

    const handleSave = () => {
        // API call to save data
        console.log('Saving data:', pageSettings);
    };

    useEffect(() => {
        async function fetchData() {
            const response = await getPageSetting('home');
            if (!response.error) {
                setpageSettings(response.data.data);
                setloading(false);
            } else {
                setloading(true);
            }

            const categoryData = await getAllCategory();
            if (categoryData && categoryData.data) {
                setcategoryList(categoryData.data);
            } else {
                setcategoryList([]);
            }
        }
        fetchData();
    }, [])

    // useEffect(() => {
    //     if (pageSettings && pageSettings !== "") {
    //         setbannerData(pageSettings.data.banner);
    //         setpremiumData(pageSettings.data.premium_domain);
    //         setbrandData(pageSettings.data.brand);
    //         setfaqData(pageSettings.data.faq);
    //         setloading(false);
    //     } else {
    //         setloading(true);
    //     }
    // }, [pageSettings])

    return (
        <>
            {loading ?
                <div className="flex items-center justify-center h-screen w-full">
                    <Stack direction="row" alignItems="center" gap={2} >
                        <Spinner />
                    </Stack>
                </div>
                :
                <>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '20px',
                        border: "1px solid #e5e5e5",
                        boxShadow: '0 0 2px 2px rgba(0, 0, 0, 0.2)',
                    }}>
                        <Typography variant="h3" color={'black'} align="center">Banner Section</Typography>
                        <BannerComponent data={pageSettings.banner} setData={setpageSettings} categoryList={categoryList} />
                        <br />
                        <Typography variant="h3" color={'black'} align="center">Premium Domain</Typography>
                        <PremiumDomainComponent data={pageSettings.premium_domain} setData={setpageSettings} />
                        <br />
                        <Typography variant="h3" color={'black'} align="center">Faq</Typography>
                        <FAQComponent data={pageSettings.faq} setData={setpageSettings} />
                        <br />
                        <Typography variant="h3" color={'black'} align="center">Brand</Typography>
                        <BrandComponent data={pageSettings.brand} setData={setpageSettings} />
                        <SaveButton onSave={handleSave} />
                    </Box>
                </>
            }
        </>
    );
}