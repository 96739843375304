import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
}

const images = importAll(require.context('../../assets/Images/Seller', false, /\.(png|jpe?g|svg)$/));

export default function CommissionRate() {
    const navigation = useNavigate();
    return (
        <>
            <Box sx={{ padding: { xs: '30px 20px', sm: '40px 20px' }, backgroundColor: '#fff', }}>
                <div className='max-w-[1400px] m-auto'>
                    <div className="commision_bg_image flex justify-between items-center flex-wrap p-3 rounded-xl md:p-5 mb-5 pb-0 md:pb-0 text-white">
                        <div className="w-full md:w-[48%] flex flex-col justify-center items-start">
                            <h1 className='text-[25px] font-bold md:text-[32px]'>Free to list 10% Commission Rate</h1>
                            <p className='mt-2 mb-4'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled</p>
                            <button onClick={() => { navigation('/register') }} type="button" className="text-[#425CD9] bg-white hover:bg-white focus:ring-4 focus:ring-white font-medium rounded-lg text-sm px-5 py-3.5 me-2 mb-2 focus:outline-none text-[16px]">Get started <ArrowRightAltIcon /></button>
                        </div>
                        <div className="w-full md:w-[40%]">
                            <img src={images['commission.png']} alt="commission" />
                            {/* <ImageSlider image={AiImage} slide={1} blockId="ai-image" /> */}
                        </div>
                    </div>
                </div>
            </Box>
        </>
    );
}