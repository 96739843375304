import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import DomainListSlider from '../DomainListSlider';

// const StyledButton = styled(Button)(({ theme }) => ({
//     backgroundColor: '#0070BA',
//     color: '#FFFFFF',
//     marginTop: 10,
//     fontWeight: 'bold',
//     fontSize: 16,
//     textTransform: 'none',
//     minWidth: 180,
//     borderRadius: '5px',
//     padding: '10px 15px',
//     '&:hover': {
//         backgroundColor: '#005A9E',
//     },
// }));

// function importAll(r) {
//     let images = {};
//     r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
//     return images
// }

// const images = importAll(require.context('../../assets/Images', false, /\.(png|jpe?g|svg)$/));

export default function PremiumDomain(props) {
    const { data } = props;
    const [domainList, setdomainList] = useState();
    const [title, settitle] = useState('');
    const [subtitle, setsubtitle] = useState('');
    const navigate = useNavigate();
    // const [randomStyles, setRandomStyles] = useState([]);
    // const styles = [
    //     { fontFamily: 'Arial, sans-serif', backgroundColor: 'linear-gradient(to right, #FF6B6B, #FFA07A)', textColor: '#333333' },
    //     { fontFamily: 'Georgia, serif', backgroundColor: 'linear-gradient(to bottom, #90EE90, #32CD32)', textColor: '#FFFFFF' },
    //     { fontFamily: 'Verdana, sans-serif', backgroundColor: 'linear-gradient(to left, #ADD8E6, #87CEEB)', textColor: '#000000' },
    //     { fontFamily: 'Tahoma, sans-serif', backgroundColor: 'linear-gradient(to top, #FFD700, #FFA500)', textColor: '#333333' },
    //     { fontFamily: 'Times New Roman, serif', backgroundColor: 'linear-gradient(135deg, #DA70D6, #BA55D3)', textColor: '#FFFFFF' },
    //     { fontFamily: 'Courier New, monospace', backgroundColor: 'linear-gradient(45deg, #D3D3D3, #A9A9A9)', textColor: '#000000' },
    //     { fontFamily: 'Impact, sans-serif', backgroundColor: 'linear-gradient(to right, #FF6347, #FF4500)', textColor: '#FFFFFF' },
    //     { fontFamily: 'Garamond, serif', backgroundColor: 'linear-gradient(to bottom, #7FFF00, #00FA9A)', textColor: '#333333' },
    //     { fontFamily: 'Lucida Console, monospace', backgroundColor: 'linear-gradient(to left, #6A5ACD, #483D8B)', textColor: '#FFFFFF' },
    //     { fontFamily: 'Brush Script MT, cursive', backgroundColor: 'linear-gradient(to top, #FFB6C1, #FF69B4)', textColor: '#000000' },
    //     { fontFamily: 'Lucida Calligraphy, cursive', backgroundColor: 'linear-gradient(to right, #FFA07A, #FA8072)', textColor: '#FFFFFF' },
    //     { fontFamily: 'Segoe Script, cursive', backgroundColor: 'linear-gradient(to bottom, #98FB98, #00FA9A)', textColor: '#333333' },
    // ];
    // const [styleData, setStyleData] = useState([
    //     { backgroundColor: '#F2EED9' },
    //     { backgroundColor: '#DFF2D9' },
    //     { backgroundColor: '#D9EEF2' },
    //     { backgroundColor: '#D9E1F2' },
    //     { backgroundColor: '#D9E1F2' },
    //     { backgroundColor: '#EAD9F2' },
    // ]);

    useEffect(() => {
        if (data && data !== "" && data !== undefined) {
            setdomainList(data.domain_list)
            settitle(data.title)
            setsubtitle(data.subtitle)
        }
    }, [data]);

    // useEffect(() => {
    //     if (styleData && styleData.length > 0) {
    //         const newRandomStyles = styleData.sort(() => 0.5 - Math.random());
    //         setRandomStyles(newRandomStyles);
    //     } else {
    //         setStyleData([
    //             { backgroundColor: '#F2EED9' },
    //             { backgroundColor: '#DFF2D9' },
    //             { backgroundColor: '#D9EEF2' },
    //             { backgroundColor: '#D9E1F2' },
    //             { backgroundColor: '#D9E1F2' },
    //             { backgroundColor: '#EAD9F2' },
    //         ])
    //     }

    // }, [styleData]);

    // const preminum_domain = [
    //     { image: "Beewa.svg", link: "/" },
    //     { image: "Scalini.svg", link: "/" },
    //     { image: "InstantOrder.svg", link: "/" },
    //     { image: "RedJolt.svg", link: "/" },
    //     { image: "Ples.svg", link: "/" },
    // ];


    return (
        <>
            <Box sx={{
                maxWidth: '1200px',
                margin: '0 auto',
                padding: { xs: '20px 15px', sm: "40px 15px" },
                textAlign: 'center',
            }}>
                <Typography variant="h4" fontWeight={600} gutterBottom sx={{ mt: 2, fontSize: { xs: "30px", sm: "40px" } }}>{title}</Typography>
                <Typography variant="body2" gutterBottom sx={{ mt: 2, color: '#5E5C9B' }}>{subtitle}</Typography>

                <div className='mt-10'>
                    <div className='flex items-center gap-5'>
                        <DomainListSlider data={domainList} slide={4} blockId='slider' />
                    </div>
                </div>
                {/* <Grid sx={{ mt: { xs: 2, sm: 5 } }} container justifyContent="center" spacing={4}>
                    {domainList && domainList.map((itemD, index) => {
                        return (
                            <Grid key={index} item>
                                <Card
                                    sx={{
                                        height: 170,
                                        width: "100%",
                                        // color: randomStyles[index % randomStyles.length].textColor,
                                        // fontFamily: randomStyles[index % randomStyles.length].fontFamily,
                                        backgroundColor: randomStyles[index % randomStyles.length].backgroundColor,
                                        color: "#000",
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: '0 10px',
                                        fontSize: (itemD.name).length > 15 ? '1.1rem' : '1.3rem',
                                        wordBreak: 'break-word',
                                        cursor: 'pointer',
                                        transition: 'transform 0.3s ease',
                                        // '&:hover': {
                                        //     transform: 'scale(1.1)',
                                        //     textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                                        // },
                                    }}
                                >
                                    {itemD.name}
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid> */}
                {/* <br />
                <Button variant="contained" sx={{
                    color: '#FFFFFF',
                    fontSize: 14,
                    background: "linear-gradient(90deg, #5A73ED 0%, #3D57D5 100%)",
                    minWidth: 180,
                    fontFamily: 'Arial, sans-serif',
                    borderRadius: '5px',
                    padding: '10px 15px',
                    '&:hover': {
                        background: 'linear-gradient(90deg, #3D57D5 0%, #5A73ED 100%)',
                    },
                }}>
                    View All Names
                </Button> */}
                <button onClick={() => { navigate('/search?view=all') }} type="button" className="text-[#fff] bg-[#425CD9] rounded-xl font-bold mt-2 px-8 py-4 focus:outline-none text-[16px]">
                    View All Names
                </button>
            </Box>
        </>
    );
}