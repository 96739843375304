

import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { DotSpinner } from "../componets/Loader";
import MetaTags from "../componets/MetaTags";
import { verifyEmailToken } from "../helper/helper";

const VerifyEmail = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const redirect = useNavigate();
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const verifyEmail = async () => {
                try {
                    await verifyEmailToken(token)
                    toast.success("Email verified successfully...")
                    redirect('/login')
                } catch (err) {
                    toast.error(err)
                    setTimeout(() => {
                        redirect('/login')
                    }, 2000);
                }
            }
            verifyEmail();
            setLoading(false)
        }, [2000])

        return () => clearTimeout(timeoutId);
    }, [token])

    return (
        <>
            <MetaTags title="Verify Email" description="Verify your email" />
            <Toaster position='top-center' reverseOrder={false}></Toaster>
            <div className="flex items-center justify-center h-screen w-full">
                {loading &&
                    (<Stack direction="row" alignItems="center" gap={2} >
                        <h1 className="text-black font-bold text-3xl">Verifying email please wait</h1><DotSpinner />
                    </Stack>)

                }
            </div>
        </>
    )
}

export default VerifyEmail