import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useFetch from '../hooks/fetch.hook';

// ==============================|| NAVIGATION - SCROLL TO TOP ||============================== //

const ScrollTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname, hash } = useLocation();
  useFetch()
  // console.log('api', apiData)
  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash.replace("#", ""));
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          // window.history.replaceState(null, null, window.location.pathname);
        }
      }, 100);
    }
  }, [pathname, hash]);
}
export default ScrollTop;
