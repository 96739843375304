import MetaTags from "../componets/MetaTags";
import Footer from "./Footer";

export default function TermsOfServices() {

    return (
        <>
            <MetaTags title="Terms Of Services" description="Welcome to the Xidomains Website, by reaching to our site we hope you consent to restrict by the given Terms of Use and every single pertinent law" />
            <div className="p-5">
                <div className="max-w-[900px] m-auto p-8 bg-[#FBFBFF] text-[#19184A] font-[Inter,sans-serif] rounded-3xl shadow-lg">
                    <h1 className="text-3xl mb-5 text-center">Terms Of Services</h1>
                    <p className="mb-3 text-sm">Welcome to the XiDomains Website, by reaching to our site we hope you consent to restrict by the given Terms of Use and every single pertinent law and regulations. Also, you agree to acknowledge obligation regarding your transaction with any relevant local laws. The information and materials on this Website are secured by pertinent copyright, trademark and other protected intellectual laws and global treaties which constitutes part of these Terms of Use.</p>
                    <p className="mb-3 text-sm">In the event that you don’t agree with any of these terms, you are forbidden from accessing or utilizing this Website.</p>
                    <ol className="list-decimal pl-5 mt-3">
                        <li className="text-2xl">
                            <h4 className="text-2xl mb-3">Our Foundation</h4>
                            {/* <p className="mb-3 text-sm">XiDomains is a mobile App service offered through the XiDomainsapp.com that allows you to manage your NationBuilder data through your smartphones.</p> */}
                            <p className="mb-3 text-sm">These Terms of Service and Privacy Policies, characterize the conditions under which you’re permitted to access XiDomains and how we’ll treat your account as you become our Member. In case that you have any queries concerning our terms and conditions, don’t hesitate to get in touch with us.</p></li>
                        <li className="text-2xl">
                            <h4 className="text-2xl mb-3">Copyright declaration</h4>
                            <p className="mb-3 text-sm">You shall respect our prohibitory rights (incorporating patents, trademarks, service marks, and copyrights) in the Website and the product used to manage XiDomains.</p>
                            <p className="mb-3 text-sm">You designate and warrant that you either possess or have consented to utilize every material utilized as a part of your access to XiDomains. You also hold responsibility for materials you transfer to the Service. We may use or reveal your materials only contingent upon the given Terms and our <a href="https://www.XiDomainsapp.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.</p></li>
                        <li className="text-2xl">
                            <h4 className="text-2xl mb-3">Limitation on usage</h4>
                            <p className="mb-3 text-sm">Authorization is conferred to temporarily download one copy of the materials (data or software) on this Website only for an individual, non-commercial, transitory review. This is the grant of a restricted license, not an exchange of title, and under this permit, you may not take part in any of the accompanying practices –</p>
                            <ol className="list-decimal pl-5 mt-3">
                                <li className="text-sm mb-2">Copy, alter or modify the materials.</li>
                                <li className="text-sm mb-2">Utilize the materials for any business reason, or for any public presentation (commercial or non-commercial both)</li>
                                <li className="text-sm mb-2">Endeavor to decompile or reverse engineer any product or electric parts included on this XiDomains Website.</li>
                                <li className="text-sm mb-2">Suspend any copyright or other restrictive documentations from the materials.</li>
                                <li className="text-sm mb-2">Exchange the materials with someone else or “reflect” the materials on some other server.</li>
                                <li className="text-sm mb-2">This permit shall consequently end in case that you violate any of these prohibitions and might be annulled by XiDomains any time.</li>
                                <li className="text-sm mb-2">After ending your viewing of these materials or upon the end of this permit, you should pulverize any downloaded materials in your ownership whether in printed or electronic form.</li>
                            </ol>
                        </li>
                        <li className="text-2xl">
                            <h4 className="text-2xl mb-3">Use in the interest of the organization</h4>
                            <p className="mb-3 text-sm">XiDomains is owned and directed by Xillentech LLC, USA private organization (“XiDomains ” “we,” or “us”). XiDomains has employees, self-employed entities, and delegates (“our Team”).</p>
                            <p className="mb-3 text-sm">As a client of the Service or a delegate of an entity that is a client of the Service, you’re a “Member” as indicated by this agreement (or “you”).</p></li>
                        <li className="text-2xl">
                            <h4 className="text-2xl mb-3">Registration &amp; account security</h4>
                            <ol className="list-decimal pl-5 mt-3">
                                <li className="text-sm mb-5"><strong className="mr-2">Account eligibility terms<br /></strong>
                                    <p className="mb-3 text-sm">To utilize XiDomains, you should –</p>
                                    <ol className="list-decimal pl-5 mt-3">
                                        <li className="text-sm mb-2">be no less than eighteen (18) years of age and capable to register into contracts;</li>
                                        <li className="text-sm mb-2">finish the registration procedure;</li>
                                        <li className="text-sm mb-2">agree to our Terms of Use and Policy; and</li>
                                        <li className="text-sm mb-2">give genuine, whole, and latest contact data.</li>
                                    </ol>
                                    <p className="mb-3 text-sm">By making use of XiDomains, you designate and guarantee that you meet every one of the prerequisites recorded above and that you will not utilize XiDomains in such a way that infringes any laws or directions. XiDomains may deny service, close accounts of any customers, and change eligibility conditions whenever and as needed.</p></li>
                                <li className="text-sm mb-5"><strong className="mr-2">Account disputes<br />
                                </strong>We don’t apprehend about the private workings of your association or the characteristics of your personal connections, and we don’t reconcile disagreements regarding who claims an account.You won’t ask for access to or data regarding an account that is not yours, and you’ll settle any account related disputes straightforwardly with the other party. We choose who possesses an account according to the content in that account, and if numerous individuals or elements are classified in the material, at that point we’ll depend on the contact data recorded for that account.</li>
                                <li className="text-sm mb-5"><strong className="mr-2">Changes to account </strong>We may modify any of the Terms by posting amended Terms of Use on our Website and/or additionally by sending an email to the last email address you provided us. Except if you discontinue your account within ten (10) days, the new Terms will come into effect instantly and apply to any proceeded or new utility of XiDomains. We hold the right to change the Website, the Service, or any other features of the Service at any time as obliged.</li>
                                <li className="text-sm mb-5"><strong className="mr-2">User account login conditions</strong>
                                    <ul className="list-decimal pl-5 mt-3">
                                        <li className="text-sm mb-2">It is your responsibility to keep your account name and password confidential.</li>
                                        <li className="text-sm mb-2">You’re likewise in charge of any account that you approach, regardless of whether you approved the utilization or not.</li>
                                        <li className="text-sm mb-2">You’ll instantly inform us of any illegal utilization of your account.</li>
                                        <li className="text-sm mb-2">We’re not in charge of any losses due to hacked or stolen passwords.</li>
                                        <li className="text-sm mb-2">We don’t have an access to your present password, and for any security reasons, we may just reset your password.</li>
                                    </ul>
                                </li>
                                <li className="text-sm mb-5"><strong className="mr-2">Removal and termination of the account<br />
                                </strong>You or XiDomains may eliminate this Agreement whenever and for any reason by providing notice to the third-party. We may discontinue our Service to you anytime, with or without reason. Once discontinued, we may forever remove your account and all of the information related to it, from our Website.In the event that you don’t sign in to your account for a year or more, we may regard your account as “inactive” and delete your account permanently as well as each and every information incorporated with it.</li>
                            </ol>
                        </li>
                        <li className="text-2xl">
                            <h4 className="text-2xl mb-3">Subscriptions</h4>
                            <ol className="list-decimal pl-5 mt-3">
                                <li className="text-sm mb-5"><strong className="mr-2">Memberships</strong>As a share of the beta release, we are not charging anything for the membership program, you can accomplish the membership by signing up our sign-up form and your country permission.</li>
                                <li className="text-sm mb-5"><strong className="mr-2">Changes in membership contract</strong>We may change our membership disposition whenever obliged by presenting another structure on our Website as well as sending you an email notification.</li>
                            </ol>
                        </li>
                        <li className="text-2xl">
                            <h4 className="text-2xl mb-3">Restricted warranties</h4>
                            <p className="mb-3 text-sm">To the most extreme degree granted by the law, we supply the material on the Website and the Service as may be. That implies we don’t give guarantees of any sort, either express or suggested, including however not restricted to warranties of merchantability and propriety for a specific reason.</p></li>
                        <li className="text-2xl">
                            <h4 className="text-2xl mb-3">Restrictions of Liability</h4>
                            <p className="mb-3 text-sm">In no circumstance shall XiDomains or its associates, representatives, suppliers, accomplice, or people portrayed in photographs , be liable responsible for any damages (comprising of without prohibition, direct or indirect, incidental, special, exemplary, disciplinary, or significant losses, or damages for lack of profit or loss of data, or because of business suspension) emerging out of the use or failure to handle this Website or the content on it, even though XiDomains has been informed of the probability of such loss. Since a few laws do not permit reservations on designated warranties, or restrictions of liability for incidental or consequential damages, these restrictions may not implement to you.</p></li>
                        <li className="text-2xl">
                            <h4 className="text-2xl mb-3">Reimbursement</h4>
                            <ol className="list-decimal pl-5 mt-3">
                                <li className="text-sm mb-2">You agree to reimburse and hold us and our Team innocuous from any losses (incorporating lawyer expenses) that occur from any demands you make that aren’t permitted under these Terms due to a “Limitation of Liability” or other assistance.</li>
                                <li className="text-sm mb-2">You additionally consent to reimburse and hold us innocuous from any losses (incorporating lawyer expenses) that due to the third-party claims that you or somebody utilizing your account password accomplished something that, if true, would violate any of our Terms and Policies.</li>
                            </ol>
                        </li>
                        <li className="text-2xl">
                            <h4 className="text-2xl mb-3">Infringement of our terms and conditions</h4>
                            <p className="mb-3 text-sm">Sometimes, an infringement of these Terms could cause losses, however, confirming of the real damages would be outlandish. These cases will lead to liquidated damages, which are a rational pre-estimate of the damages comprising of:</p>
                            <p className="mb-3 text-sm">If you don’t pay a sum due within thirty (30) days after we send you a notification of late payment, at that point the liquidated damages will be three times the aggregate sum you paid over the past 12 months to us, however, at least $200 in addition to the sum owed.</p></li>
                        <li className="text-2xl">
                            <h4 className="text-2xl mb-3">Equitable relief</h4>
                            <p className="mb-3 text-sm">In the event that you breach our Terms then we may look for injunctive help (which means we may request for a court request to suspend you) or other equitable relief.</p></li>
                        <li className="text-2xl">
                            <h4 className="text-2xl mb-3">No endorsement of third-party Links</h4>
                            <p className="mb-3 text-sm">XiDomains has not analyzed all of the third party sites linked with this Website and isn’t in charge of the material of any such linked site. The incorporation of any link does not suggest endorsement by XiDomains of the site or the appropriate association. Utilization of any such linked site is at your own risk.</p></li>
                        <li className="text-2xl">
                            <h4 className="text-2xl mb-3">Alteration</h4>
                            <p className="mb-3 text-sm">XiDomains may revise these Terms of Use at any time without notice. By using this Website you are agreeing to be bound by the then current version of these Terms of Use.</p></li>
                        <li className="text-2xl">
                            <h4 className="text-2xl mb-3">Severability</h4>
                            <p className="mb-3 text-sm">In case that it directs that a part of this Agreement isn’t enforceable, at that point that segment will be eliminated or altered as meager as significant, and rest of the Terms will yet be valid.</p></li>
                        <li className="text-2xl">
                            <h4 className="text-2xl mb-3">Designation</h4>
                            <p className="mb-3 text-sm">You may not designate any of your rights under this agreement to any other individual. We may dole out our rights to some other individual or entity at our inclination.</p></li>
                        <li className="text-2xl">
                            <h4 className="text-2xl mb-3">General standards</h4>
                            <ul className="list-decimal pl-5 mt-3">
                                <li className="text-sm mb-2">You guarantee to follow the XiDomains Terms of Service including every single relevant Policy that forms part of your Agreement with XiDomains.</li>
                                <li className="text-sm mb-2">In case that you infringe any of these Terms, then we may annul or suspend your account.</li>
                                <li className="text-sm mb-2">If you think anybody is disrupting any of these Terms kindly inform us as soon as possible.</li>
                            </ul>
                        </li>
                        <li className="text-2xl">
                            <h4 className="text-2xl mb-3">Law and authority</h4>
                            <p className="mb-3 text-sm">All the given terms and conditions are to be administered by and interpreted as per the laws of Gujarat India and any request made by either party against the other which in any capacity emerges out of these terms and conditions will be heard in Gujarat and you consent to submit to the rule of those Courts.</p></li>
                        <li className="text-2xl">
                            <h4 className="text-2xl mb-3">Survivability</h4>
                            <p className="mb-3 text-sm">Regardless of termination of this agreement, the accompanying divisions will keep on applying: Rights Owned by Us, Proprietary Rights Owned by You, Laws and Jurisdiction, Restricted Warranties, Restriction of Liability, Reimbursement, Severability, and Whole Agreement.</p></li>
                        <li className="text-2xl">
                            <h4 className="text-2xl mb-3">Additional actions</h4>
                            <p className="mb-3 text-sm">You’ll give all documents and take any steps important to meet your responsibilities under these Terms.</p></li>
                        <li className="text-2xl">
                            <h4 className="text-2xl mb-3">Whole agreement</h4>
                            <p className="mb-3 text-sm">Our Terms and <a href="https://www.XiDomainsapp.com/privacy-policy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a> (which are consolidated into these Terms by reference), and any Additional Terms you’ve consented to make up the whole agreement and replace every single earlier agreement, understanding, and representation.</p></li>
                        {/* <li className="text-2xl">
                            <h4 className="text-2xl mb-3">Our details</h4>
                            <p className="mb-3 text-sm">Social Benchers LLC,<br />
                                2035 Sunset Lake Road<br />
                                Suite B-2, Newark 19702<br />
                                xidomains1@gmail.com
                            </p>
                        </li> */}
                    </ol>
                </div>
            </div>
            <Footer />
        </>
    );
}