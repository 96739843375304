import toast from "react-hot-toast";

export async function registerValidate(values) {

    const errors = emailVerify({}, values);
    passwordVerify(errors, values);
    emailVerify(errors, values);

    return errors;
}

export async function contactValidate(values) {

    const errors = emailVerify({}, values);
    emailVerify(errors, values);

    return errors;
}

function emailVerify(error = {}, values) {
    if (!values.email) {
        error.email = toast.error('Email Required!');
    } else if (values.email.includes(" ")) {
        error.email = toast.error('Incoreect Email!');
    } else if (!/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(values.email)) {
        error.email = toast.error('Invalid Email Address!');
    }
    return error;
}

function passwordVerify(error = {}, values) {
    const specialCharacters = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/;

    if (!values.password) {
        error.password = toast.error('Password Required!');
    } else if (values.password.includes(" ")) {
        error.password = toast.error('Incoreect Password!');
    } else if (values.password.length < 4) {
        error.password = toast.error('Password Length Must Be More Than 4 Characters Long!');
    } else if (!specialCharacters.test(values.password)) {
        error.password = toast.error('Password Must Contain Special Characters!');
    } else if (values.password !== values.confirm_password) {
        error.password = toast.error('Password And Confirm Password Not Match');
    }
}

export async function passwordDashboardVerify(error = {}, values) {
    const specialCharacters = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/;

    if (!values.password) {
        error.password = toast.error('Password Required!');
    } else if (values.password.includes(" ")) {
        error.password = toast.error('Incoreect Password!');
    } else if (values.password.length < 4) {
        error.password = toast.error('Password Length Must Be More Than 4 Characters Long!');
    } else if (!specialCharacters.test(values.password)) {
        error.password = toast.error('Password Must Contain Special Characters!');
    } else if (values.password !== values.confirm_password) {
        error.password = toast.error('Password And Confirm Password Not Match');
    }
    return error;
}

export async function adminUserValidate(values) {
    const errors = emailVerify({}, values);
    if (values.password) {
        passwordVerifyNew(errors, values);
    }

    // usertypeVerify(errors, values);
    // primeuserVerify(errors, values);
    // statusVerify(errors, values);


    return errors;
}


function passwordVerifyNew(error = {}, values) {
    const specialCharacters = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/;

    if (!values.password) {
        error.password = toast.error('Password Required!');
    } else if (values.password.includes(" ")) {
        error.password = toast.error('Incoreect Password!');
    } else if (values.password.length < 4) {
        error.password = toast.error('Password Length Must Be More Than 4 Characters Long!');
    } else if (!specialCharacters.test(values.password)) {
        error.password = toast.error('Password Must Contain Special Characters!');
    }
}