import React from 'react';

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
}

const images = importAll(require.context('../../assets/Images/Seller', false, /\.(png|jpe?g|svg)$/));

export default function CustomerBrand() {

    return (
        <>
            <div className='bg-[#425CD9] px-5 py-14'>
                <div className='flex items-center justify-between flex-wrap max-w-[1440px] m-auto'>
                    <div className='w-full md:w-[39%] text-white mb-5 md:mb-0'>
                        <h1 className='text-[25px] font-bold md:text-[38px]'>100+ Customers</h1>
                        <p className='text-[16px]'>From early-stage startups to large enterprises. Also, recognized as one of the Most Innovative Companies by Inc Magazine.</p>
                    </div>
                    <div className='w-full md:w-[60%] flex items-center justify-start flex-wrap gap-4'>
                        <img src={images['philips.png']} alt="philips" />
                        <img src={images['nestle.png']} alt="nestle" />
                        <img src={images['nuskin.png']} alt="nuskin" />
                        <img src={images['delta.png']} alt="delta" />
                        <img src={images['virgin.png']} alt="virgin" />
                    </div>
                </div>
            </div>
        </>
    );
}