import { Box } from '@mui/material';
import React from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import { RightArrow } from '../Icon';
import ImageSlider from './ImageSlider';

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
}

const images = importAll(require.context('../../assets/Images/Seller', false, /\.(png|jpe?g|svg)$/));

export default function WhyDomain() {

    const AiImage = [
        {
            label: 'Ai',
            imgPath: images['ai1.png']
        },
        {
            label: 'Ai',
            imgPath: images['ai2.png']
        },
        {
            label: 'Ai',
            imgPath: images['ai1.png']
        },
        {
            label: 'Ai',
            imgPath: images['ai2.png']
        },
    ];

    const SaleImage = [
        {
            label: 'Ai',
            imgPath: images['sale1.png']
        },
        {
            label: 'Ai',
            imgPath: images['sale1.png']
        },
        {
            label: 'Ai',
            imgPath: images['sale1.png']
        },
        {
            label: 'Ai',
            imgPath: images['sale1.png']
        },
    ];

    const AssistImage = [
        {
            label: 'Ai',
            imgPath: images['assist1.png']
        },
        {
            label: 'Ai',
            imgPath: images['assist1.png']
        },
        {
            label: 'Ai',
            imgPath: images['assist1.png']
        },
        {
            label: 'Ai',
            imgPath: images['assist1.png']
        },
    ];

    // const RightArrow = createSvgIcon(
    //     <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    //         <rect y="0.738037" width="20" height="20" rx="10" fill="#425CD9" />
    //         <path d="M14 10.738C13.9968 10.3873 13.8555 10.0519 13.6067 9.80467L10.7467 6.938C10.6218 6.81384 10.4528 6.74414 10.2767 6.74414C10.1006 6.74414 9.9316 6.81384 9.80669 6.938C9.7442 6.99998 9.69461 7.07371 9.66076 7.15495C9.62691 7.23619 9.60949 7.32333 9.60949 7.41134C9.60949 7.49934 9.62691 7.58648 9.66076 7.66772C9.69461 7.74896 9.7442 7.82269 9.80669 7.88467L12 10.0713H5.33335C5.15654 10.0713 4.98697 10.1416 4.86195 10.2666C4.73693 10.3916 4.66669 10.5612 4.66669 10.738C4.66669 10.9148 4.73693 11.0844 4.86195 11.2094C4.98697 11.3344 5.15654 11.4047 5.33335 11.4047H12L9.80669 13.598C9.68115 13.7227 9.61027 13.8921 9.60965 14.069C9.60902 14.2459 9.6787 14.4158 9.80335 14.5413C9.92801 14.6669 10.0974 14.7377 10.2743 14.7384C10.4512 14.739 10.6212 14.6693 10.7467 14.5447L13.6067 11.678C13.8571 11.4291 13.9985 11.0911 14 10.738Z" fill="white" />
    //     </svg>,
    //     'right_arrow',
    // );

    return (
        <>
            <Box sx={{
                maxWidth: '1440px',
                margin: '0 auto',
                padding: { xs: '30px 20px', sm: "80px 40px 20px 40px" },
            }}>
                <h1 className='text-[30px] p-2 md:p-0 text-left md:text-center font-bold md:text-[50px] mb-2'>Why Sell Domain Names on Xidomains?</h1>
                <div className="flex justify-center items-center flex-wrap p-2 md:p-5 mb-5">
                    <div className="w-full md:w-[48%] flex flex-col justify-center items-start">
                        <h1 className='text-[25px] font-bold md:text-[32px]'>Powered By AI</h1>
                        <p className=' mt-2 mb-4'>Better results through effective AI, including one-click AI-generated descriptions and auto-categorization of your entire portfolio.</p>
                        <div className='flex flex-col mt-2'>
                            <div className="flex justify-start items-center mb-2">
                                <RightArrow />
                                <span className="ml-2">AI Descriptions</span>
                            </div>
                            <div className="flex justify-start items-center mb-2">
                                <RightArrow />
                                <span className="ml-2">AI Descriptions</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-[50%]">
                        <ImageSlider image={AiImage} slide={1} blockId="ai-image" />
                    </div>
                </div>
                <div className="flex justify-center items-center flex-wrap p-2 md:p-5 mb-5">
                    <div className="w-full md:w-[50%] order-2 md:order-1">
                        <ImageSlider image={SaleImage} slide={1} blockId="sale-image" />
                    </div>
                    <div className="w-full md:w-[48%] flex flex-col justify-center items-start md:ml-5 order-1 md:order-2">
                        <h1 className='text-[25px] font-bold md:text-[32px]'>Beautiful Domain Sales Page</h1>
                        <p className=' mt-2 mb-4'>Showcase your domain with a stunning landing page, fully mobile optimized and accented with complimentary visual images.</p>
                        <div className='flex flex-col mt-2'>
                            <div className="flex justify-start items-center mb-2">
                                <RightArrow />
                                <span className="ml-2">Mobile Optimized Landing Pages</span>
                            </div>
                            <div className="flex justify-start items-center mb-2">
                                <RightArrow />
                                <span className="ml-2">Complimentary Visual Images</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center items-center flex-wrap p-2 md:p-5 mb-5">
                    <div className="w-full md:w-[58%] flex flex-col justify-center items-start">
                        <h1 className='text-[25px] font-bold md:text-[32px]'>Domain Selling Assistance</h1>
                        <p className=' mt-2 mb-4'>Buyers can communicate with us 24x7 via online chat with an average response time of less than 3 minutes. Plus, when you receive offers, you can see the buyer's exact location and even engage with our brokers to negotiate on your behalf.</p>
                        <div className='flex flex-col mt-2'>
                            <div className="flex justify-start items-center mb-2">
                                <RightArrow />
                                <span className="ml-2">24x7 Buyer Support</span>
                            </div>
                            <div className="flex justify-start items-center mb-2">
                                <RightArrow />
                                <span className="ml-2">Offer Management</span>
                            </div>
                        </div>
                    </div>
                    <div className="w-full md:w-[40%]">
                        <ImageSlider image={AssistImage} slide={1} blockId="assist-image" />
                    </div>
                </div>
            </Box>
        </>
    );
}