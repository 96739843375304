import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FaceIcon from '@mui/icons-material/Face';
import { Chip, Stack, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { getAllCategory, getBuyDomains } from '../../helper/helper';
import { Spinner } from '../Loader';
import MetaTags from '../MetaTags';

function YourDomain(props) {
    const { userData } = props;
    const [domain, setdomain] = useState([]);
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1);
    const pageSize = 100;
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    useEffect(() => {
        async function fetchData() {
            if (userData && userData !== "") {
                setLoading(true)
                try {
                    const response = await getBuyDomains({ user_id: userData._id });
                    let domainData = response.data;
                    const categoryList = await getAllCategory();
                    if (categoryList && categoryList.data) {
                        const allCategoryList = categoryList.data;
                        const domainDataArray = [];
                        domainData.forEach(item => {
                            if (item.category && item.category.length > 0) {
                                const categoryArray = [];
                                item.category.forEach(categoryItem => {
                                    const categoryName = allCategoryList.filter(element => element._id === categoryItem);
                                    if (categoryName && categoryName.length > 0) {
                                        categoryArray.push(categoryName[0].name);
                                    }
                                });
                                item.category = categoryArray.join(", ");
                            } else {
                                item.category = "";
                            }
                            domainDataArray.push(item);
                        });
                        domainData = [...domainDataArray];
                    }
                    setdomain(domainData);
                    setLoading(false);
                } catch (error) {
                    console.error("Error fetching domain data:", error);
                    setLoading(false)
                }
            }
        }
        fetchData();
    }, [userData]);

    const columns = [
        { field: 'id', headerName: 'ID', headerClassName: " text-[12px]  bg-[#ffffff] text-black-100", minWidth: 100 },
        { field: 'name', headerName: 'Domain', headerClassName: " text-[12px]  bg-[#ffffff] text-black-100", flex: 2, minWidth: 150, renderCell: (params) => (<>{params.row.name}</>) },
        { field: 'domain_name', headerName: 'Domain Name', headerClassName: " text-[12px]  bg-[#ffffff] text-black-100", minWidth: 150, renderCell: (params) => (<>{params.row.domain_name}</>) },
        { field: 'top_level_domain', headerName: 'TLD', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", minWidth: 100, renderCell: (params) => (<>{params.row.top_level_domain}</>) },
        { field: 'category', headerName: 'category', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", flex: 2, minWidth: 150, cellClassName: 'font-tabular-nums', renderCell: (params) => (<>{params.row.category}</>) },
        { field: 'price', headerName: 'Price', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", minWidth: 100, renderCell: (params) => (<>{params.row.price}</>) },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100",
            minWidth: 110,
            renderCell: (params) => (
                <>
                    <Chip label={params.row.status === '1' ? '✓' : params.row.status === '2' ? '✓' : 'x'} color={params.row.status === '1' ? 'success' : params.row.status === '2' ? 'info' : 'error'} icon={params.row.status === '1' ? (<FaceIcon fontSize='15px' />) : (<FaceIcon />)} size='small' sx={{ color: "white", fontWeight: '500' }} />
                </>
            )
        },

    ];
    const domainWithUniqueId = domain && domain.length > 0 && domain.map((user, index) => ({
        id: user._id,
        status: user.status === '1' ? "Active" : "Inactive",
        ...user,
    }));

    const currentPageRows = domainWithUniqueId && domainWithUniqueId.length > 0 && domainWithUniqueId.slice(startIndex, endIndex);

    const handlePageChange = (event, value) => {
        setPage(value)
    };

    return (
        <>
            <MetaTags title="Your Domain" description="Manage your buy domain data." />
            {loading ?
                <div className="flex items-center justify-center h-screen w-full">
                    <Stack direction="row" alignItems="center" gap={2} >
                        <Spinner />
                    </Stack>
                </div>
                :
                <div className='w-full h-screen'>
                    <Stack direction="column" gap={4}>
                        <Stack gap={1}>
                            <DataGrid
                                autoHeight
                                ColumnFilter
                                // autosizeOptions={{
                                //     columns: ['name', 'domain_name', 'top_level_domain'],
                                //     includeOutliers: true,
                                //     includeHeaders: true,
                                // }}
                                slots={{
                                    // toolbar: GridToolbar,
                                    loadingOverlay: () => loading && <div className='flex w-full justify-center h-screen items-center'> <Spinner /> </div>,
                                    pagination: () => (
                                        <Stack spacing={2} justifyContent="center" alignItems="center" className='w-full p-3 bg-[#ffffff] text-center'>
                                            <Pagination
                                                count={Math.ceil(domainWithUniqueId && domainWithUniqueId.length > 0 && domainWithUniqueId.length / pageSize)}
                                                page={page}
                                                onChange={handlePageChange}
                                                renderItem={(item) => (
                                                    <PaginationItem
                                                        component="div"

                                                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                                        {...item}
                                                        onClick={() => handlePageChange(null, item.page)}

                                                    />
                                                )}

                                            />
                                        </Stack>
                                    ),
                                    noRowsOverlay: () => <Typography p={5} textAlign="center" >No Data Available</Typography>
                                }}
                                // componentsProps={{
                                //     toolbar: {
                                //         showQuickFilter: false,
                                //         quickFilterProps: { debounceMs: 500 },
                                //     },
                                // }}
                                sx={{

                                    '.MuiDataGrid-columnHeaderTitle': {
                                        fontWeight: 'bold',
                                    }, color: "#000", backgroundColor: "#ffffff", fontSize: "13px", lineHeight: "18px", cursor: "pointer", fontWeight: '600',
                                }}
                                loading={loading}
                                rows={currentPageRows && currentPageRows.length > 0 ? currentPageRows : []}
                                columns={columns}
                                initialState={{
                                    ...currentPageRows.initialState,
                                    columns: {
                                        columnVisibilityModel: {
                                            // top_level_domain: false,
                                            id: false,
                                            // domain_name: false
                                        }
                                    }
                                }}
                                disableRowSelectionOnClick
                            />
                        </Stack>
                    </Stack>
                </div>
            }
        </>
    )
};

export default YourDomain