import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import { Grid, IconButton, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { createCategory, getAllCategory, updateCategory } from '../../helper/helper';
import MetaTags from '../MetaTags';

function Category() {

    const [categoryList, setcategoryList] = useState([]);
    const [count, setcount] = useState(0);

    useEffect(() => {
        async function fetchFirstData() {
            const categoryData = await getAllCategory();
            if (categoryData && categoryData.data) {
                setcategoryList(categoryData.data);
            } else {
                setcategoryList([]);
            }
        }
        fetchFirstData();
    }, [count])

    const removeCategory = (index) => {
        // const newCategories = data.category.filter((_, i) => i !== index);
        // setData(prevData => ({
        //     ...prevData,
        //     banner: {
        //         ...prevData.banner,
        //         category: newCategories
        //     }
        // }));
    };

    const handleCategoryChange = (index, e) => {
        const { value } = e.target;
        const newCategories = categoryList.map((item, i) => (
            i === index ? { ...item, name: value } : item
        ));
        // console.log('newdca', newCategories)
        setcategoryList(newCategories);
        // setcategoryList(prevData => ({
        //     ...prevData,
        //     banner: {
        //         ...prevData.banner,
        //         category: newCategories
        //     }
        // }));
    };

    const addCategory = () => {
        const categoryCreate = createCategory({ name: "test" });
        categoryCreate.then(() => {
            // console.log('done');
            toast.success('Add Successfully..!');
            setcount(count + 1)
        }).catch(error => {
            // console.log('Login error:', error);
            toast.error(error);
        });
    }

    const updateCategoryChange = (name, id) => {
        // console.log('name', name, id)
        const categoryCreate = updateCategory({ name: name, id: id });
        categoryCreate.then(() => {
            // console.log('done');
            toast.success('Update Successfully..!');
            // setcount(count + 1)
        }).catch(error => {
            // console.log('Login error:', error);
            toast.error(error);
        });
    }

    return (
        <>
            <MetaTags title="Admin Category" description="Manage your category data." />
            <Toaster position='top-center' reverseOrder={false}></Toaster>
            <div className='flex flex-col text-black'>
                <Grid container spacing={2} sx={{ m: 5 }}>
                    {categoryList && categoryList.length > 0 &&
                        categoryList.map((category, index) => {
                            return (
                                <Grid item key={index} sx={{ width: '100%', display: "flex" }}>
                                    <Grid>
                                        <TextField
                                            label={`Category ${index + 1}`}
                                            variant='standard'
                                            sx={{ marginBottom: "10px" }}
                                            value={category.name}
                                            onChange={(e) => handleCategoryChange(index, e)}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid>
                                        <IconButton color='error' onClick={() => removeCategory(index)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid>
                                        <IconButton sx={{ ml: 2 }} onClick={() => updateCategoryChange(category.name, category._id)}>
                                            <UpgradeIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                    <Grid item>
                        <IconButton onClick={() => addCategory()}>
                            <AddIcon /> Add Category
                        </IconButton>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};

export default Category