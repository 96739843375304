import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { Spinner } from "../componets/Loader";
import { getUser } from '../helper/helper';

export const AuthorizeUser = ({ children }) => {

  const token = localStorage.getItem('token');
  // const navigate = useNavigate();
  if (token) {
    const { exp } = jwtDecode(token);
    const currentTimestamp = Math.floor(Date.now() / 1000);
    if (exp && exp < currentTimestamp) {
      localStorage.removeItem('token');
      return <Navigate to={'/login'} replace={true}></Navigate>;
    }
  }
  if (!token) {
    localStorage.removeItem('token');
    return <Navigate to={'/login'} replace={true}></Navigate>
  }
  return children;
}

export const LoginAuth = ({ children }) => {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (token) {
      navigate('/')
    }
    // if (!token) {
    //   navigate('/login')
    // }
    setLoading(false);

  }, [token, navigate])
  // console.log('calll', loading)
  if (loading) {
    return <Spinner />;
  }

  return children; // Return the wrapped component

}

export const SellerAuth = ({ children }) => {
  const token = localStorage.getItem('token');
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (token) {
          const { email, exp } = jwtDecode(token);
          const currentTimestamp = Math.floor(Date.now() / 1000);
          if (exp && exp < currentTimestamp) {
            localStorage.removeItem('token');
            return <Navigate to={'/login'} replace={true}></Navigate>;
          }
          const userData = await getUser(email);
          setUserData(userData);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    token && fetchData();
  }, [token]);

  if (loading) {
    return <div className='flex w-full justify-center h-screen items-center'><Spinner /></div>;
  }

  if (!token || !userData) {
    localStorage.removeItem('token');
    return <Navigate to={'/login'} replace={true}></Navigate>;
  }
  // console.log(userData);
  const { user_type } = userData && userData.data;
  // console.log(user_type);

  if (user_type !== '1') {
    localStorage.removeItem('token');
    return <Navigate to={'/login'} replace={true}></Navigate>;
  }
  return children;
};

export const BuyerAuth = ({ children }) => {
  const token = localStorage.getItem('token');
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (token) {
          const { email, exp } = jwtDecode(token);
          const currentTimestamp = Math.floor(Date.now() / 1000);
          if (exp && exp < currentTimestamp) {
            localStorage.removeItem('token');
            return <Navigate to={'/login'} replace={true}></Navigate>;
          }
          const userData = await getUser(email);
          setUserData(userData);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    token && fetchData();
  }, [token]);

  if (loading) {
    return <div className='flex w-full justify-center h-screen items-center'><Spinner /></div>;
  }

  if (!token || !userData) {
    localStorage.removeItem('token');
    return <Navigate to={'/login'} replace={true}></Navigate>;
  }
  // console.log(userData);
  const { user_type } = userData && userData.data;
  // console.log(user_type);

  if (user_type !== '2') {
    localStorage.removeItem('token');
    return <Navigate to={'/login'} replace={true}></Navigate>;
  }
  return children;
};

export const AdminAuth = ({ children }) => {
  const token = localStorage.getItem('token');
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (token) {
          const { email, exp } = jwtDecode(token);
          const currentTimestamp = Math.floor(Date.now() / 1000);
          if (exp && exp < currentTimestamp) {
            localStorage.removeItem('token');
            return <Navigate to={'/login'} replace={true}></Navigate>;
          }

          const userData = await getUser(email);
          setUserData(userData);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    token && fetchData();
  }, [token]);

  if (loading) {
    return <div className='flex w-full justify-center h-screen items-center'><Spinner /></div>;
  }
  if (!token || !userData) {
    localStorage.removeItem('token');
    return <Navigate to={'/login'} replace={true}></Navigate>;
  }

  const { user_type } = userData.data;

  if (user_type !== '0') {
    localStorage.removeItem('token');
    return <Navigate to={'/login'} replace={true}></Navigate>;
  }
  return children;
};

