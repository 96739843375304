import { Button, Card, Container, Grid, Stack, TextField, TextareaAutosize, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useFormik } from 'formik';
import * as React from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import MetaTags from '../componets/MetaTags';
import { createContact } from '../helper/helper';
import { contactValidate } from '../helper/validate';
import Footer from './Footer';

export default function Contact() {
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            message: '',
        },
        enableReinitialize: true,
        validate: contactValidate,
        validateOnBlur: false,
        validateOnChange: false,
        onSubmit: async values => {
            let registerPromise = createContact(values);
            registerPromise.then(function () {
                // console.log('ok');
                toast.success('Thank you for reaching out to us.')
                setTimeout(() => {
                    navigate('/')
                }, 1500);
            }).catch(error => {
                toast.error(error)
            });

        },
    });

    return (
        <>
            <MetaTags title="Contact" description="Get in touch with us." />
            <Toaster position='top-center' reverseOrder={false}></Toaster>
            <Container
                id="register_form"
                sx={{
                    pt: { xs: 4, sm: 5 },
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: { xs: "100%", sm: 600 },
                    gap: { xs: 3, sm: 6 },
                }}
            >

                <Card
                    sx={(theme) => ({
                        p: 3,
                        height: 'fit-content',
                        width: '100%',
                        background: 'none',
                        '&:hover': {
                            boxShadow:
                                theme.palette.mode === 'light'
                                    ? '0px 2px 8px hsla(0, 0%, 0%, 0.1)'
                                    : '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                        },
                    })}
                >
                    <Box
                        sx={{
                            p: { xs: 2, sm: 5 },
                            borderRadius: 5,
                        }}
                    // autoComplete="off"
                    >
                        <Typography variant="h2" align="center" sx={{ fontSize: { xs: "30px", sm: "40px" }, marginBottom: "10px" }}> Contact us</Typography>
                        {/* <Typography variant="subtitle1" align="center"> We always keep your name and email private </Typography> */}
                        <br />
                        <form className='py-1' onSubmit={formik.handleSubmit}>
                            <Stack spacing={2}>
                                <TextField size="small" {...formik.getFieldProps('name')} required id="name" label="Name" variant="outlined" fullWidth type="text" />
                                <TextField size="small" {...formik.getFieldProps('email')} required id="email" label="Email Address" variant="outlined" fullWidth type="email" />
                                <TextareaAutosize className='bg-transparent border p-1 px-2 text-black border-[#0000003b] focus:border-[#0959AA] hover:border-[#0959AA]' minRows={3} placeholder='Enter your message *' {...formik.getFieldProps('message')} required id="message" label="Message" variant="outlined" type="textarea" />
                            </Stack>
                            <br />
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Button variant="contained" type='submit' color="primary"> Submit </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Box>
                </Card>
            </Container>
            <Footer />
        </>
    );
}
