import { DeleteOutline, InfoOutlined } from '@mui/icons-material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { IconButton, Stack, SvgIcon, Tooltip, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import toast, { Toaster } from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { deleteDomain, getAllCategory, getDomains, searchSellerDomain, verifyDomain } from '../../helper/helper';
import { Spinner } from '../Loader';
import MetaTags from '../MetaTags';

function Domain(props) {
    const { userData } = props;
    const [domain, setdomain] = useState([]);
    const [formSubmitCount, setFormSubmitCount] = useState(0);
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1);
    const pageSize = 25;
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const navigator = useNavigate();
    const [query, setquery] = useState("");
    const [domainLoading, setdomainLoading] = useState(false);
    const location = useLocation();

    useEffect(() => {
        async function fetchData() {
            if (userData && userData !== "" && query === "") {
                setLoading(true)
                try {
                    const response = await getDomains({ user_id: userData._id });
                    let domainData = response.data;
                    if (location && location.search.includes('sold')) {
                        domainData = domainData.filter(item => item.status === "2");
                    } else {
                        domainData = domainData.filter(item => item.status !== "2");
                    }
                    const categoryList = await getAllCategory();
                    if (categoryList && categoryList.data) {
                        const allCategoryList = categoryList.data;
                        const domainDataArray = [];
                        domainData.forEach(item => {
                            if (item.category && item.category.length > 0) {
                                const categoryArray = [];
                                item.category.forEach(categoryItem => {
                                    const categoryName = allCategoryList.filter(element => element._id === categoryItem);
                                    if (categoryName && categoryName.length > 0) {
                                        categoryArray.push(categoryName[0].name);
                                    }
                                });
                                item.category = categoryArray.join(", ");
                            } else {
                                item.category = "";
                            }
                            domainDataArray.push(item);
                        });
                        domainData = [...domainDataArray];
                    }
                    setdomain(domainData);
                    setLoading(false);
                } catch (error) {
                    console.error("Error fetching domain data:", error);
                    setLoading(false)
                }
            }
        }
        fetchData();
    }, [formSubmitCount, userData, query, location]);

    const columns = [
        { field: 'id', headerName: 'ID', headerClassName: " text-[12px]  bg-[#ffffff] text-black-100", minWidth: 100 },
        { field: 'name', headerName: 'Domain Name', headerClassName: " text-[12px]  bg-[#ffffff] text-black-100", flex: 2, minWidth: 150, renderCell: (params) => (<>{params.row.name}</>) },
        // { field: 'domain_name', headerName: 'Domain Name', headerClassName: " text-[12px]  bg-[#ffffff] text-black-100", minWidth: 150, renderCell: (params) => (<>{params.row.domain_name}</>) },
        { field: 'top_level_domain', headerName: 'TLD', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", minWidth: 100, renderCell: (params) => (<>{params.row.top_level_domain}</>) },
        { field: 'category', headerName: 'category', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", flex: 2, minWidth: 150, cellClassName: 'font-tabular-nums', renderCell: (params) => (<>{params.row.category}</>) },
        { field: 'price', headerName: 'Price', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", minWidth: 100, renderCell: (params) => (<>{params.row.price}</>) },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100",
            minWidth: 130,
            renderCell: (params) => (
                <>
                    {params.row.status === '1' ?
                        <div className="p-2 h-full">
                            <div className="relative flex items-center justify-start h-full text-[#17BB09] bg-[#17BB09]/[11%] py-3 px-2 rounded-lg">
                                <span className="w-2 h-2 bg-[#17BB09] rounded-full min-w-2"></span>
                                <span className='ml-2'>Verified</span>
                            </div>
                        </div>
                        // <Tooltip title="Domain Verified">
                        //     <Chip label={'✓'} color={'success'} icon={<FaceIcon fontSize='15px' />} size='small' sx={{ color: "white", fontWeight: '500' }} />
                        // </Tooltip>
                        :
                        params.row.status === '2' ?
                            <div className="p-2 h-full">
                                <div className="relative flex items-center justify-start h-full text-[#0095EB] bg-[#0095EB]/[11%] py-3 px-2 rounded-lg">
                                    <span className="w-2 h-2 bg-[#0095EB] rounded-full min-w-2"></span>
                                    <span className='ml-2'>Sold</span>
                                </div>
                            </div>
                            // <Tooltip title="Domain Sold!">
                            //     <Chip label={'✓'} color={'info'} icon={<FaceIcon fontSize='15px' />} size='small' sx={{ color: "white", fontWeight: '500' }} />
                            // </Tooltip>
                            :
                            <div className="p-2 h-full w-full">
                                <div className="relative flex items-center justify-start h-full w-full text-[#BB0909] bg-[#BB0909]/[11%] py-3 px-2 rounded-lg">
                                    <span className="w-2 h-2 bg-[#BB0909] rounded-full min-w-2"></span>
                                    <span className='ml-2'>Unverified</span>
                                </div>
                            </div>
                        // <Tooltip title="Please verify A domain By Changing A record to 170.64.225.16">
                        //     <Chip label={'x'} color={'error'} icon={<FaceIcon fontSize='15px' />} size='small' sx={{ color: "white", fontWeight: '500' }} />
                        // </Tooltip>
                    }
                </>
            )
        },

        {
            field: 'action',
            headerName: 'Action',
            width: 120,
            headerClassName: "text-[12px]  bg-[#ffffff]  text-black-100",
            renderCell: (params) => (
                <div>
                    {params.row.status !== "1" && params.row.status !== "2" ?
                        <Tooltip title="Verify Your Domain">
                            <IconButton onClick={() => handleVerify(params.row.name, params.row.id)}>
                                <SvgIcon>
                                    <RotateRightIcon fontSize='small' />
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                        :
                        <>
                            {params.row.status !== "2" &&
                                <Link to={`/dashboard/domain/${params.row.id}`}>
                                    <Tooltip title="Edit">
                                        <IconButton>
                                            <SvgIcon>
                                                <InfoOutlined fontSize='small' />
                                            </SvgIcon>
                                        </IconButton>
                                    </Tooltip>
                                </Link>
                            }
                        </>
                    }
                    {params.row.status !== "2" &&
                        <Tooltip title="Delete">
                            <IconButton onClick={() => handleDelete(params.row.user_id, params.row.id)}>
                                <SvgIcon>
                                    <DeleteOutline fontSize='small' />
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                    }
                </div>
            ),
        },

    ];

    const domainWithUniqueId = domain && domain.length > 0 && domain.map((user, index) => ({
        id: user._id,
        status: user.status === '1' ? "Active" : "Inactive",
        ...user,
    }));

    const currentPageRows = domainWithUniqueId && domainWithUniqueId.length > 0 && domainWithUniqueId.slice(startIndex, endIndex);

    const handlePageChange = (event, value) => {
        setPage(value)
    };

    async function handleDelete(user_id, id) {
        // console.log(id);
        confirmAlert({
            title: "",
            message: "Are you sure you want to delete this domain?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        let deletePromise = deleteDomain(user_id, id);
                        // console.log('callsls', user_id, id)
                        deletePromise.then(function () {
                            toast.success('Domain Deleted Successfully..!')
                            setFormSubmitCount((prevCount) => prevCount + 1);
                        }).catch(error => {
                            // console.log(error);
                            toast.error('Plaese try again! ');
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                    }
                }

            ]
        });

    }

    async function handleVerify(name, id) {
        let userPromise = verifyDomain({ id: id, name: name, email: userData.email, first_name: userData.first_name });
        userPromise.then(() => {
            toast.success('Verify Successfully..!');
            setTimeout(() => {
                navigator(`/dashboard/domain/${id}`);
            }, 500);
        }).catch(error => {
            // console.log('Login error:', error);
            toast.error(error);
        });
    }

    const handleChange = (e) => {
        setquery(e.target.value);
    };


    useEffect(() => {
        setdomainLoading(true);

        const intervalId = setTimeout(async () => {
            if (userData && userData?.email) {
                const obj = {};
                if (query !== "") {
                    obj.key = query;

                    obj.email = userData.email;
                    const domainPromise = await searchSellerDomain(obj);
                    let domainData = domainPromise.data.data;
                    if (location && location.search.includes('sold')) {
                        domainData = domainData.filter(item => item.status === "2");
                    } else {
                        domainData = domainData.filter(item => item.status !== "2");
                    }
                    const categoryList = await getAllCategory();
                    if (categoryList && categoryList.data) {
                        const allCategoryList = categoryList.data;
                        const domainDataArray = [];
                        domainData.forEach(item => {
                            if (item.category && item.category.length > 0) {
                                const categoryArray = [];
                                item.category.forEach(categoryItem => {
                                    const categoryName = allCategoryList.filter(element => element._id === categoryItem);
                                    if (categoryName && categoryName.length > 0) {
                                        categoryArray.push(categoryName[0].name);
                                    }
                                });
                                item.category = categoryArray.join(", ");
                            } else {
                                item.category = "";
                            }
                            domainDataArray.push(item);
                        });
                        domainData = [...domainDataArray];
                    }
                    setdomain(domainData);
                    // setLoading(false);

                    setdomainLoading(false);
                }
            }
        }, 500);

        return () => clearTimeout(intervalId);
    }, [query])


    // function QuickSearchToolbar() {
    //     return (
    //         <Box
    //             sx={{
    //                 p: 0.5,
    //                 pb: 0,
    //             }}
    //         >
    //             <GridToolbarQuickFilter
    //                 quickFilterParser={(searchInput) =>
    //                     searchInput
    //                         .split(',')
    //                         .map((value) => value.trim())
    //                         .filter((value) => value !== '')
    //                 }
    //             />
    //         </Box>
    //     );
    // }


    return (
        <>
            <MetaTags title="Domain List" description="Manage your domain data." />
            {loading ?
                <div className="flex items-center justify-center h-screen w-full">
                    <Stack direction="row" alignItems="center" gap={2} >
                        <Spinner />
                    </Stack>
                </div>
                :
                <div className='w-full h-screen'>
                    <Toaster position='top-center' reverseOrder={false}></Toaster>
                    <div className='flex justify-between items-center p-3 text-black border'>
                        <span>Domains</span>
                        <div className='flex items-center'>
                            <div className="flex items-center mr-2 bg-white rounded-lg">
                                <div className="text-[#425CD9] w-[40px] flex justify-center items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"> <path d="M19.35 20.9251L13.325 14.9001C12.825 15.3334 12.2417 15.6709 11.575 15.9126C10.9083 16.1543 10.2 16.2751 9.45 16.2751C7.65 16.2751 6.125 15.6501 4.875 14.4001C3.625 13.1501 3 11.6418 3 9.8751C3 8.10843 3.625 6.6001 4.875 5.3501C6.125 4.1001 7.64167 3.4751 9.425 3.4751C11.1917 3.4751 12.6958 4.1001 13.9375 5.3501C15.1792 6.6001 15.8 8.10843 15.8 9.8751C15.8 10.5918 15.6833 11.2834 15.45 11.9501C15.2167 12.6168 14.8667 13.2418 14.4 13.8251L20.475 19.8501C20.625 19.9834 20.7 20.1543 20.7 20.3626C20.7 20.5709 20.6167 20.7584 20.45 20.9251C20.3 21.0751 20.1167 21.1501 19.9 21.1501C19.6833 21.1501 19.5 21.0751 19.35 20.9251ZM9.425 14.7751C10.775 14.7751 11.925 14.2959 12.875 13.3376C13.825 12.3793 14.3 11.2251 14.3 9.8751C14.3 8.5251 13.825 7.37093 12.875 6.4126C11.925 5.45426 10.775 4.9751 9.425 4.9751C8.05833 4.9751 6.89583 5.45426 5.9375 6.4126C4.97917 7.37093 4.5 8.5251 4.5 9.8751C4.5 11.2251 4.97917 12.3793 5.9375 13.3376C6.89583 14.2959 8.05833 14.7751 9.425 14.7751Z" fill="currentColor" /> </svg>
                                </div>
                                <input className="bg-transparent text-black py-2 border-none outline-none text-[1.2rem]" placeholder="Search Your Domain" value={query} onChange={handleChange} type="text" />
                            </div>
                            <Link to={`/dashboard/addDomain`} className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'>Add Domain</Link>
                        </div>
                    </div>
                    <Stack direction="column" gap={4}>
                        <Stack gap={1}>
                            <DataGrid
                                autoHeight
                                ColumnFilter
                                // autosizeOptions={{
                                //     columns: ['name', 'domain_name', 'top_level_domain'],
                                //     includeOutliers: true,
                                //     includeHeaders: true,
                                // }}
                                slots={{
                                    // toolbar: GridToolbar,
                                    // toolbar: QuickSearchToolbar,
                                    loadingOverlay: () => (loading || domainLoading) && <div className='flex w-full justify-center h-screen items-center'> <Spinner /> </div>,
                                    pagination: () => (
                                        <Stack spacing={2} justifyContent="center" alignItems="center" className='w-full p-3 bg-[#ffffff] text-center'>
                                            <Pagination
                                                count={Math.ceil(domainWithUniqueId && domainWithUniqueId.length > 0 && domainWithUniqueId.length / pageSize)}
                                                page={page}
                                                onChange={handlePageChange}
                                                renderItem={(item) => (
                                                    <PaginationItem
                                                        component="div"

                                                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                                        {...item}
                                                        onClick={() => handlePageChange(null, item.page)}

                                                    />
                                                )}

                                            />
                                        </Stack>
                                    ),
                                    noRowsOverlay: () => <Typography p={5} textAlign="center" >No Data Available</Typography>
                                }}
                                // componentsProps={{
                                //     toolbar: {
                                //         showQuickFilter: false,
                                //         quickFilterProps: { debounceMs: 500 },
                                //     },
                                // }}
                                sx={{

                                    '.MuiDataGrid-columnHeaderTitle': {
                                        fontWeight: 'bold',
                                    }, color: "#000", backgroundColor: "#ffffff", fontSize: "13px", lineHeight: "18px", cursor: "pointer", fontWeight: '600',
                                }}
                                loading={loading}
                                rows={currentPageRows && currentPageRows.length > 0 ? currentPageRows : []}
                                columns={columns}
                                initialState={{
                                    ...currentPageRows.initialState,
                                    columns: {
                                        columnVisibilityModel: {
                                            // top_level_domain: false,
                                            id: false,
                                            // domain_name: false
                                        }
                                    },
                                    // sorting: {
                                    //     sortModel: [{ field: 'status', sort: 'desc' }],
                                    // },
                                }}
                                disableRowSelectionOnClick
                            />
                        </Stack>
                    </Stack>
                </div>
            }
        </>
    )
};

export default Domain