import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { RightArrow } from '../Icon';

function importAll(r) {
    let images = {};
    r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
    return images
}

const images = importAll(require.context('../../assets/Images', false, /\.(png|jpe?g|svg)$/));
const banner_img = '' + images["HomeImage.png"] + '';

export default function BrandList(props) {
    const { data } = props;
    const [brandList, setbrandList] = useState();
    const [title, settitle] = useState('');
    const [subtitle, setsubtitle] = useState('');
    const navigate = useNavigate();

    // const RightArrow = createSvgIcon(
    //     <svg xmlns="http://www.w3.org/2000/svg" width="36" height="31" viewBox="0 0 36 31" fill="none">
    //         <g clipPath="url(#clip0_1_564)">
    //             <path d="M24 9.91983L30 15.9198M30 15.9198L24 21.9198M30 15.9198H6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    //         </g>
    //         <defs>
    //             <clipPath id="clip0_1_564">
    //                 <rect width="26" height="14" fill="white" transform="translate(5 8.91983)" />
    //             </clipPath>
    //         </defs>
    //     </svg>,
    //     'right_arrow',
    // );

    useEffect(() => {
        if (data && data !== "" && data !== undefined) {
            setbrandList(data.brand_list)
            settitle(data.title)
            setsubtitle(data.subtitle)
        }
    }, [data]);


    return (
        <>
            <div className='max-w-[1440px] m-auto p-5 md:p-10 text-center'>
                <h1 className='text-[22px] font-bold md:text-[32px] mb-1 mt-2'>{title}</h1>
                <p className='text-[14px] md:text-[16px]'>{subtitle}</p>

                <div className='w-full flex justify-center'>
                    <div className='w-full md:w-2/4'>
                        <img src={banner_img} alt="seller" />
                    </div>
                </div>

                <div className='mt-5 flex flex-wrap justify-between p-4 items-center max-w-5xl m-auto'>
                    {brandList && brandList.map((item, index) => {
                        return (
                            <div key={index} className='mt-2 w-full md:w-auto'>
                                <div onClick={() => { navigate(item.link || '/') }} className='w-full flex justify-between cursor-pointer items-center bg-white rounded-xl p-4 custom_svg_color_main hover:shadow-xl'>
                                    {item.name}
                                    <div className='custom_svg_color rounded-full p-1 ml-2'>
                                        <RightArrow />
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <button onClick={() => { navigate('/search?view=all') }} type="button" className="text-[#fff] bg-[#425CD9] rounded-xl font-bold mt-4 px-8 py-4 focus:outline-none text-[16px]">
                    View all categories
                </button>
            </div>
        </>
    );
}