import DnsIcon from '@mui/icons-material/Dns';
import { Box, Card, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { getBuyerDomainCounts, getSellerDomainCounts } from '../../helper/helper';
import MetaTags from '../MetaTags';

function Index(props) {

    const { userType, userData } = props;
    const navigate = useNavigate();
    const [listingsCount, setlistingsCount] = useState(0);
    const [soldCount, setsoldCount] = useState(0);
    const [buyCount, setbuyCount] = useState(0);
    const [wishlistCount, setwishlistCount] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            if (userType && userData && userType === "1") {
                let domainCountPromise = getSellerDomainCounts(userData._id);
                domainCountPromise.then((res) => {
                    if (res && res.data) {
                        setlistingsCount(res.data.listed_count);
                        setsoldCount(res.data.sold_count);
                    } else {
                        setlistingsCount(0);
                        setsoldCount(0);
                    }
                }).catch(error => {
                    console.log('Login error:', error);
                });
            }
            if (userType && userData && userType === "2") {
                let domainCountPromise = getBuyerDomainCounts(userData._id);
                domainCountPromise.then((res) => {
                    if (res && res.data) {
                        setbuyCount(res.data.buy_count || 0);
                    } else {
                        setbuyCount(0);
                    }
                }).catch(error => {
                    console.log('Login error:', error);
                });
                setwishlistCount(userData.wishlist.length || 0);
            }
        }
        fetchData();

    }, [userData, userType]);


    // console.log('usertype', userType, userData)
    return (
        <>
            <MetaTags title="Dashboard" description="Manage your account and settings." />
            {userType === "0" ? "" :
                <Container
                    sx={{
                        pt: { xs: 4, sm: 5 },
                        pb: { xs: 4, sm: 5 },
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: { xs: "100%", sm: 750 },
                        marginLeft: 'unset',
                        gap: { xs: 3, sm: 6 },
                    }}
                >

                    <Card
                        sx={(theme) => ({
                            p: 3,
                            height: 'fit-content',
                            width: '100%',
                            background: 'none',
                            boxShadow:
                                theme.palette.mode === 'light'
                                    ? '0px 2px 8px hsla(0, 0%, 0%, 0.1)'
                                    : '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                        })}
                    >
                        <Box sx={{ width: '100%' }}>
                            <div className='flex justify-start items-center flex-nowrap'>
                                <DnsIcon />
                                <h4 className='ml-5 font-bold'>{userType === "1" ? "Seller Dashboard" : "Buyer Dashboard"}</h4>
                            </div>
                            <div>
                                {userType === "1" &&
                                    <div className='mt-5'>
                                        <div className='flex flex-wrap md:flex-nowrap'>
                                            <div className="mx-2 max-w-sm p-6 w-full mb-5 md:mb-0 md:w-56 border rounded-lg shadow bg-gradient-to-r from-violet-500 to-fuchsia-500">
                                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-white cursor-pointer" onClick={() => navigate('/dashboard/domain')}>Domain Listings</h5>
                                                <p className="mb-3 font-normal text-white">{listingsCount}</p>
                                            </div>
                                            <div className="mx-2 max-w-sm p-6 w-full mb-5 md:mb-0 md:w-56 border rounded-lg shadow bg-gradient-to-r from-violet-500 to-fuchsia-500">
                                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-white cursor-pointer" onClick={() => navigate('/dashboard/domain?sold')}>Domain Sold</h5>
                                                <p className="mb-3 font-normal text-white">{soldCount}</p>
                                            </div>
                                            <div className="mx-2 max-w-sm p-6 w-full mb-5 md:mb-0 md:w-40 border-dashed border-2 border-sky-500 rounded-lg shadow cursor-pointer" onClick={() => navigate('/dashboard/addDomain')}>
                                                <p className="mb-3 text-2xl text-center text-[#0ea5e9] flex flex-col items-center justify-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" fill="none"> <rect width="24" height="24" fill="none" /> <path d="M12 6V18" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" /> <path d="M6 12H18" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                                                    Add More Domains
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {userType === "2" &&
                                    <div className='mt-5'>
                                        <div className='flex flex-wrap md:flex-nowrap'>
                                            <div className="mx-2 max-w-sm p-6 w-full mb-5 md:mb-0 md:w-56 md:min-h-44 border rounded-lg shadow bg-gradient-to-r from-violet-500 to-fuchsia-500">
                                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-white cursor-pointer" onClick={() => navigate('/dashboard/yourdomain')}>Domain Buy</h5>
                                                <p className="mb-3 font-normal text-white">{buyCount}</p>
                                            </div>
                                            <div className="mx-2 max-w-sm p-6 w-full mb-5 md:mb-0 md:w-56 md:min-h-44 border rounded-lg shadow bg-gradient-to-r from-violet-500 to-fuchsia-500">
                                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-white cursor-pointer" onClick={() => navigate('/dashboard/wishlist')}>Domain Wishlist</h5>
                                                <p className="mb-3 font-normal text-white">{wishlistCount}</p>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </Box>
                    </Card>
                </Container>
            }
        </>
    )
};

export default Index;