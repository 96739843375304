import { Box, Button, Card, Container, Slide, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import MetaTags from '../componets/MetaTags';
import { generateOTP, resetPassword, verifyOTP } from '../helper/helper';
import Footer from './Footer';

function Recovery() {

    const navigate = useNavigate();
    const [stepItemIndex, setstepItemIndex] = useState(0);
    const [recoveryTitle, setrecoveryTitle] = useState('Recover Your Password');
    const [recoverySubTitle, setrecoverySubTitle] = useState('Enter your email address, where you will receive OTP');
    const [email, setemail] = useState('');
    const [otp, setotp] = useState('');
    const [password, setpassword] = useState('');
    const [resettoken, setresettoken] = useState('');

    const handleSubmit = (index) => (event) => {
        event.preventDefault();
        if (index === 0) {
            let otpGeneratePromise = generateOTP({ email: email });
            otpGeneratePromise.then(() => {
                toast.success("OTP Email Sent Successfully!");
                setrecoveryTitle('OTP Code');
                setrecoverySubTitle("Enter 6 Digit OTP sent to your email address");
                setstepItemIndex(1);
            }).catch(error => {
                toast.error(error);
            });
        } else if (index === 1) {
            let otpVerifyPromise = verifyOTP({ email: email, code: otp });
            otpVerifyPromise.then((res) => {
                toast.success("OTP verified successfully, You can update new Password");
                setresettoken(res.data.data.reset_token)
                setrecoveryTitle('Create New Password');
                setrecoverySubTitle("Enter your new password");
                setstepItemIndex(2);
            }).catch(error => {
                toast.error(error);
            });
        } else if (index === 2) {
            let passwordPromise = resetPassword({ email: email, reset_token: resettoken, password: password });
            passwordPromise.then((res) => {
                toast.success("Password Update Successfully..!");
                setTimeout(() => {
                    navigate('/login')
                }, 1000);
            }).catch(error => {
                toast.error(error);
            });
        }
    }

    return (
        <div>
            <MetaTags title="Recover Email" description="Recover your email" />
            <Toaster position='top-center' reverseOrder={false}></Toaster>
            <Container
                id="register_form"
                sx={{
                    pt: { xs: 4, sm: 5 },
                    pb: { xs: 4, sm: 5 },
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: { xs: "100%", sm: 600 },
                    gap: { xs: 3, sm: 6 },
                }}
            >

                <Card
                    sx={(theme) => ({
                        p: 3,
                        height: 'fit-content',
                        width: '100%',
                        background: 'none',
                        '&:hover': {
                            boxShadow:
                                theme.palette.mode === 'light'
                                    ? '0px 2px 8px hsla(0, 0%, 0%, 0.1)'
                                    : '0px 1px 8px hsla(210, 100%, 25%, 0.5) ',
                        },
                    })}
                >
                    <Box sx={{ p: { xs: 2, sm: 5 }, borderRadius: 5, width: "100%" }} >
                        <Typography variant="h2" sx={{ fontSize: { xs: "20px", sm: "30px" }, marginBottom: "10px" }} align="center">{recoveryTitle}</Typography>
                        <Typography variant="body2" align="center">{recoverySubTitle}</Typography>
                        {/* <br /> */}
                        <Slide direction="right" in={stepItemIndex === 0 ? true : false} mountOnEnter unmountOnExit>
                            <Box component="form" onSubmit={handleSubmit(0)} sx={{ width: '100%', mt: 2 }}>

                                <TextField
                                    label="Email"
                                    type="email"
                                    value={email}
                                    onChange={(e) => setemail(e.target.value)}
                                    required
                                    fullWidth
                                    margin="normal"
                                />

                                <Button type="submit" variant="contained" fullWidth>Submit</Button>
                            </Box>
                        </Slide>
                        <Slide direction="right" in={stepItemIndex === 1 ? true : false} mountOnEnter unmountOnExit>
                            <Box component="form" onSubmit={handleSubmit(1)} sx={{ width: '100%', mt: 2 }}>

                                <TextField
                                    label="Enter code"
                                    type="text"
                                    value={otp}
                                    onChange={(e) => setotp(e.target.value)}
                                    required
                                    fullWidth
                                    margin="normal"
                                />

                                <Button type="submit" variant="contained" fullWidth>Send Code</Button>
                            </Box>
                        </Slide>
                        <Slide direction="right" in={stepItemIndex === 2 ? true : false} mountOnEnter unmountOnExit>
                            <Box component="form" onSubmit={handleSubmit(2)} sx={{ width: '100%', mt: 2 }}>

                                <TextField
                                    label="Password"
                                    type="password"
                                    value={password}
                                    onChange={(e) => setpassword(e.target.value)}
                                    required
                                    fullWidth
                                    margin="normal"
                                />

                                <Button type="submit" variant="contained" fullWidth>Save</Button>

                            </Box>
                        </Slide>
                    </Box>
                </Card>
            </Container>

            <Footer />

        </div>
    );
}

export default Recovery;