import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Box, Card, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../App";
import { getWishlistDomains, updateWishlist } from '../../helper/helper';
import { Spinner } from '../Loader';
import MetaTags from '../MetaTags';

export default function Wishlist() {
    const { user, setUser } = useContext(UserContext);
    const [loading, setloading] = useState(true);
    const [domainData, setdomainData] = useState([]);
    const [randomStyles, setRandomStyles] = useState([]);
    const [wishlist, setwishlist] = useState([]);
    const [wishlistShow, setwishlistShow] = useState(true);
    const navigate = useNavigate();

    const [styleData, setStyleData] = useState([
        { backgroundColor: '#F2EED9' },
        { backgroundColor: '#DFF2D9' },
        { backgroundColor: '#D9EEF2' },
        { backgroundColor: '#D9E1F2' },
        { backgroundColor: '#D9E1F2' },
        { backgroundColor: '#EAD9F2' },
    ]);

    useEffect(() => {
        async function fetchFirstData() {
            if (user && user !== "" && user !== undefined) {
                let whishlistPromise = getWishlistDomains({ wishlist: user.wishlist });
                whishlistPromise.then((res) => {
                    if (res && res.data) {
                        setdomainData(res.data);
                    } else {
                        setdomainData([]);
                    }
                    setloading(false);
                }).catch(error => {
                    // console.log('Login error:', error);
                });
            }
            if (user && user !== "" && user !== undefined) {
                setwishlist(user.wishlist);
                if (user.user_type === "2") {
                    setwishlistShow(true);
                } else {
                    setwishlistShow(false);
                }
            } else {
                setwishlist([]);
                setwishlistShow(true);
            }
        }
        fetchFirstData();
    }, [user])

    useEffect(() => {
        if (styleData && styleData.length > 0) {
            const newRandomStyles = styleData.sort(() => 0.5 - Math.random());
            setRandomStyles(newRandomStyles);
        } else {
            setStyleData([
                { backgroundColor: '#F2EED9' },
                { backgroundColor: '#DFF2D9' },
                { backgroundColor: '#D9EEF2' },
                { backgroundColor: '#D9E1F2' },
                { backgroundColor: '#D9E1F2' },
                { backgroundColor: '#EAD9F2' },
            ])
        }

    }, [styleData]);

    const handleClickWishlist = (id) => {
        if (user && user !== "") {
            let newWishlist = [...wishlist];
            if (wishlist.includes(id)) {
                newWishlist = wishlist.filter((tldId) => tldId !== id);
            } else {
                newWishlist.push(id);
            }
            let wishlistPromise = updateWishlist({ wishlist: newWishlist });
            wishlistPromise.then((res) => {
                setwishlist(newWishlist);
                setUser(preData => ({ ...preData, wishlist: newWishlist }))
            }).catch(error => {
                // console.log('Login error:', error);
            });

        } else {
            navigate('/login');
        }
    }

    const isItemInWishlist = (itemId, wishlistArray) => {
        return wishlistArray.includes(itemId);
    };


    return (
        <>
            <MetaTags title="Wishlist" description="View your wishlist items." />
            {loading ?
                <div className="flex items-center justify-center h-screen w-full">
                    <Stack direction="row" alignItems="center" gap={2} >
                        <Spinner />
                    </Stack>
                </div>
                :
                <>
                    <Container
                        sx={{
                            pt: { xs: 4, sm: 5 },
                            pb: { xs: 4, sm: 5 },
                            position: 'relative',
                            width: "100%",
                            maxWidth: "1400px !important",
                            margin: "auto",
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: { xs: 3, sm: 6 },
                        }}
                    >


                        <div className="w-full">
                            {domainData && domainData.length > 0 ?
                                <Box sx={{
                                    padding: { xs: '20px 15px', sm: "40px 15px" },
                                    textAlign: 'center',
                                }}>
                                    <Grid container justifyContent="center" spacing={4}>
                                        {domainData.map((itemD, index) => {
                                            return (
                                                <Grid key={index} item>
                                                    <Card
                                                        sx={{
                                                            height: 250,
                                                            width: 300,
                                                            // color: randomStyles[index % randomStyles.length].textColor,
                                                            // fontFamily: randomStyles[index % randomStyles.length].fontFamily,
                                                            backgroundColor: randomStyles[index % randomStyles.length].backgroundColor,
                                                            color: "#000",
                                                            padding: '0 10px',
                                                            textTransform: "capitalize",
                                                            fontSize: (itemD.name).length > 15 ? '1.1rem' : '1.3rem',
                                                            wordBreak: 'break-word',
                                                            transition: 'transform 0.3s ease',
                                                        }}
                                                    >
                                                        <div className='flex justify-center items-center h-full relative'>
                                                            {wishlistShow &&
                                                                <div className='absolute top-2 right-2 cursor-pointer' onClick={() => { handleClickWishlist(itemD._id); }}>
                                                                    {isItemInWishlist(itemD._id, wishlist) ? (
                                                                        <FavoriteIcon sx={{ color: "#000" }} />
                                                                    ) : (
                                                                        <FavoriteBorderIcon sx={{ color: "#000" }} />
                                                                    )}
                                                                </div>
                                                            }
                                                            <div className='flex flex-col items-center justify-center'>
                                                                <Link to={`/domain/${itemD.name}`}>
                                                                    {itemD.name}
                                                                </Link>
                                                                <span className='mt-4'>
                                                                    ${itemD.price}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </Card>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </Box >
                                :
                                <Typography variant='h5' sx={{ mt: 5, textAlign: 'center', color: "#000" }}>Your Wishlist is empty!</Typography>
                            }
                        </div>
                    </Container>
                </>
            }
        </>
    );
}