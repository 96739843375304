import React from 'react';
import MetaTags from './MetaTags';

function PageNotFound() {
    return (
        <>
            <MetaTags title="Page Not Found" description="Page not found." />
            <div className='container mx-auto text-black'>
                <div className='flex justify-center items-center h-screen'>
                    <div>
                        <div className='title text-3xl '>
                            <h1>Page Not Found!</h1>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PageNotFound;