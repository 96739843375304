import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { Spinner } from "../componets/Loader";
import MetaTags from "../componets/MetaTags";
import Search from "../componets/Search";
import Banner from "../componets/SellerHome/Banner";
import CommissionRate from "../componets/SellerHome/CommissionRate";
import CustomerBrand from "../componets/SellerHome/CustomerBrand";
import Faq from "../componets/SellerHome/Faq";
import PerfectBrand from "../componets/SellerHome/PerfectBrand";
import WhyDomain from "../componets/SellerHome/WhyDomain";
import { getPageSetting } from "../helper/helper";
import Footer from "./Footer";

export default function SellDomain() {
    const [pageSettings, setpageSettings] = useState();
    const [bannerData, setbannerData] = useState();
    const [faqData, setfaqData] = useState();
    const [loading, setloading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            const response = await getPageSetting('selldomain');
            if (!response.error) {
                setpageSettings(response.data);
            }
        }
        fetchData();
    }, [])

    useEffect(() => {
        if (pageSettings && pageSettings !== "") {
            setbannerData(pageSettings.data.banner);
            setfaqData(pageSettings.data.faq);
            setloading(false);
        } else {
            setloading(true);
        }
    }, [pageSettings])

    return (
        <>
            <MetaTags title="Sell A Domain" description="Sell your domain easily with us." />
            {loading ?
                <div className="flex items-center justify-center h-screen w-full">
                    <Stack direction="row" alignItems="center" gap={2} >
                        <Spinner />
                    </Stack>
                </div>
                :
                <>
                    <Banner />
                    <CustomerBrand />
                    <WhyDomain />
                    <CommissionRate />
                    <Faq data={faqData} />
                    <PerfectBrand />
                    <Search data={bannerData} />
                    <Footer />
                </>
            }
        </>
    );
}