import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../App";
import { getEmailFromToken } from "../helper/helper";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_DOMAIN

export default function useFetch(query) {
    // console.log(query);
    const [getData, setData] = useState({ isLoading: false, apiData: undefined, status: null, serverError: null });
    const location = useLocation();
    const { setUser } = useContext(UserContext);

    useEffect(() => {
        // console.log("query");
        const fetchData = async () => {
            try {
                setData(prev => ({ ...prev, isLoading: true }));

                const { email } = !query ? await getEmailFromToken() : await getEmailFromToken();
                // console.log("query       is called");
                const { data, status } = !query ? await axios.get(`api/user/${email}`) : await axios.get(`api/user/${query}`);

                if (status === 200) {
                    setData(prev => ({ ...prev, isLoading: false }));
                    setData(prev => ({ ...prev, apiData: data, status: status }));
                    setUser(data);
                }
                setData(prev => ({ ...prev, isLoading: false }));
            } catch (error) {
                setData(prev => ({ ...prev, isLoading: false, serverError: error }))
                setUser(null);
            }
        }
        fetchData();

    }, [query, location]);

    return [getData, setData];
}