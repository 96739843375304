import { Card } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LeftArrow, RightArrow } from '../Icon';

// const imagesDemo = [
//     {
//         label: 'San Francisco – Oakland Bay Bridge, United States',
//         imgPath:
//             'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
//     },
//     {
//         label: 'Bird',
//         imgPath:
//             'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
//     },
//     {
//         label: 'Bali, Indonesia',
//         imgPath:
//             'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
//     },
//     {
//         label: 'Goč, Serbia',
//         imgPath:
//             'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
//     },
// ];

function ImageSlider(props) {
    const { image, slide, blockId } = props;
    const [images, setimages] = useState([]);
    const [slideShow, setslideShow] = useState(1);

    useEffect(() => {
        if (image && image.length > 0) {
            setimages(image || [])
        } else {
            setimages([]);
        }
        setslideShow(slide || 1)
    }, [image, slide])


    return (
        <div className='w-full'>
            {images && images.length > 0 &&
                <>
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={20}
                        slidesPerView={slideShow}
                        loop={true}
                        navigation={{
                            prevEl: `.prev-${blockId}`,
                            nextEl: `.next-${blockId}`,
                        }}
                    >
                        {images.map((step, index) => (
                            <SwiperSlide key={index}>
                                <Card
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'start',
                                        // backgroundColor: "#FFFFFF",
                                        // padding: "10px",
                                        border: "none",
                                        // height: { xs: "200px", sm: "100%" },
                                        // borderRadius: "20px",
                                        // boxShadow: "0 8px 10px rgb(48 127 245 / .08)",
                                        boxShadow: "none",
                                        backgroundColor: "unset"
                                    }}
                                >

                                    <div className='w-full h-full'>
                                        <img className='w-full h-full block overflow-hidden' src={step.imgPath} alt={step.label || "label"} />
                                    </div>

                                </Card>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <div className='flex items-center w-full justify-center mt-3 text-white mb-3'>
                        <div className={`prev-${blockId} mr-3 cursor-pointer rounded-full p-1 md:p-2 bg-[#425CD9]`}>
                            <LeftArrow />
                        </div>
                        <div className={`next-${blockId} cursor-pointer rounded-full p-1 md:p-2 bg-[#425CD9]`}>
                            <RightArrow />
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default ImageSlider;