import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Box, Card, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserContext } from '../App';
import { updateWishlist } from '../helper/helper';
// import { Link } from 'react-router-dom';

export default function DomainList(props) {
    const { user, setUser } = useContext(UserContext);
    const { data } = props;
    const [domainList, setdomainList] = useState();
    const [randomStyles, setRandomStyles] = useState([]);
    const [wishlist, setwishlist] = useState([]);
    const [wishlistShow, setwishlistShow] = useState(true);
    const navigate = useNavigate();

    const [styleData, setStyleData] = useState([
        { backgroundColor: '#F2EED9' },
        { backgroundColor: '#DFF2D9' },
        { backgroundColor: '#D9EEF2' },
        { backgroundColor: '#D9E1F2' },
        { backgroundColor: '#D9E1F2' },
        { backgroundColor: '#EAD9F2' },
    ]);

    useEffect(() => {
        if (data && data !== "" && data !== undefined) {
            setdomainList(data);
        }
    }, [data]);

    useEffect(() => {
        if (user && user !== "" && user !== undefined) {
            setwishlist(user.wishlist);
            if (user.user_type === "2") {
                setwishlistShow(true);
            } else {
                setwishlistShow(false);
            }
        } else {
            setwishlist([]);
            setwishlistShow(true);
        }
    }, [user]);

    // useEffect(() => {
    //     if (user && user !== "" && user !== undefined) {
    //         let wishlistPromise = updateWishlist({ wishlist });
    //         wishlistPromise.then((res) => {

    //         }).catch(error => {
    //             console.log('Login error:', error);
    //         });

    //     }
    // }, [wishlist]);

    useEffect(() => {
        if (styleData && styleData.length > 0) {
            const newRandomStyles = styleData.sort(() => 0.5 - Math.random());
            setRandomStyles(newRandomStyles);
        } else {
            setStyleData([
                { backgroundColor: '#F2EED9' },
                { backgroundColor: '#DFF2D9' },
                { backgroundColor: '#D9EEF2' },
                { backgroundColor: '#D9E1F2' },
                { backgroundColor: '#D9E1F2' },
                { backgroundColor: '#EAD9F2' },
            ])
        }

    }, [styleData]);

    const handleClickWishlist = (id) => {
        if (user && user !== "") {
            let newWishlist = [...wishlist];
            if (wishlist.includes(id)) {
                newWishlist = wishlist.filter((tldId) => tldId !== id);
            } else {
                newWishlist.push(id);
            }
            let wishlistPromise = updateWishlist({ wishlist: newWishlist });
            wishlistPromise.then((res) => {
                setwishlist(newWishlist);
                setUser(preData => ({ ...preData, wishlist: newWishlist }))
            }).catch(error => {
                // console.log('Login error:', error);
            });

        } else {
            navigate('/login');
        }
    }

    const isItemInWishlist = (itemId, wishlistArray) => {
        return wishlistArray.includes(itemId);
    };

    return (
        <>
            <Box sx={{
                padding: { xs: '20px 15px', sm: "40px 15px" },
                textAlign: 'center',
            }}>
                <Grid container justifyContent="center" spacing={4}>
                    {domainList && domainList.map((itemD, index) => {
                        return (
                            <Grid key={index} item>
                                <Card
                                    sx={{
                                        height: 250,
                                        width: 300,
                                        backgroundColor: randomStyles[index % randomStyles.length].backgroundColor,
                                        // color: randomStyles[index % randomStyles.length].textColor,
                                        // fontFamily: randomStyles[index % randomStyles.length].fontFamily,
                                        color: "#000",
                                        // display: 'flex',
                                        // justifyContent: 'center',
                                        // alignItems: 'center',
                                        padding: '0 10px',
                                        textTransform: "capitalize",
                                        fontSize: (itemD.name).length > 15 ? '1.1rem' : '1.3rem',
                                        wordBreak: 'break-word',
                                        // cursor: 'pointer',
                                        transition: 'transform 0.3s ease',
                                        // '&:hover': {
                                        //     transform: 'scale(1.1)',
                                        //     textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
                                        // },
                                    }}
                                >
                                    <div className='flex justify-center items-center h-full relative'>
                                        {wishlistShow &&
                                            <div className='absolute top-2 right-2 cursor-pointer' onClick={() => { handleClickWishlist(itemD._id); }}>
                                                {isItemInWishlist(itemD._id, wishlist) ? (
                                                    <FavoriteIcon sx={{ color: "#000" }} />
                                                ) : (
                                                    <FavoriteBorderIcon sx={{ color: "#000" }} />
                                                )}
                                            </div>
                                        }
                                        <div className='flex flex-col items-center justify-center'>
                                            <Link to={`/domain/${itemD.name}`}>
                                                {itemD.name}
                                            </Link>
                                            <span className='mt-4'>
                                                ${itemD.price}
                                            </span>
                                        </div>
                                    </div>
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
            </Box >
        </>
    );
}