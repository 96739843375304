import { Box, Card, Typography } from '@mui/material';
import React from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LeftArrow, RightArrow } from '../Icon';
// import 'swiper/css/autoplay';

function importAll(r) {
    let images = {};
    r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
}

const images = importAll(require.context('../../assets/Images', false, /\.(png|jpe?g|svg)$/));

export default function Reviews(props) {
    const { title } = props;
    // const RightArrow = createSvgIcon(
    //     <svg xmlns="http://www.w3.org/2000/svg" width="27" height="15" viewBox="0 0 27 15" fill="none">
    //         <path d="M19.5 1.35016L25.5 7.35016M25.5 7.35016L19.5 13.3502M25.5 7.35016H1.5" stroke="#307FF5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    //     </svg>,
    //     'right_arrow',
    // );
    // const LeftArrow = createSvgIcon(
    //     <svg xmlns="http://www.w3.org/2000/svg" width="27" height="15" viewBox="0 0 27 15" fill="none">
    //         <path d="M7.5 1.35016L1.5 7.35016M1.5 7.35016L7.5 13.3502M1.5 7.35016H25.5" stroke="#307FF5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    //     </svg>,
    //     'left_arrow',
    // );
    return (
        <>
            <Box sx={{ padding: { xs: '30px 15px', sm: '40px 15px 80px 15px' }, backgroundColor: "rgb(99 124 247 / .08)" }}>
                <Box sx={{ maxWidth: '1440px', margin: '0 auto' }}>
                    {title && <Typography variant="h4" fontWeight={600} gutterBottom sx={{ mt: 2, mb: 5, textAlign: 'center', fontSize: { xs: "30px", sm: "40px" } }}>{title}</Typography>}

                    <div className='flex items-center w-full justify-end mb-5 text-[#425CD9]'>
                        <div className="prev mr-3 cursor-pointer">
                            <LeftArrow />
                        </div>
                        <div className="next cursor-pointer">
                            <RightArrow />
                        </div>
                    </div>
                    <Swiper
                        modules={[Navigation]}
                        spaceBetween={20}
                        breakpoints={{
                            576: {
                                slidesPerView: 1.5,
                            },
                            768: {
                                slidesPerView: 2.5,
                            },
                        }}
                        // slidesPerView={2.5}
                        loop={true}
                        navigation={{
                            prevEl: '.prev',
                            nextEl: '.next',
                        }}
                    // onSlideChange={() => console.log('slide change')}
                    // onSwiper={(swiper) => console.log(swiper)}
                    >
                        <SwiperSlide>
                            <Card
                                sx={{
                                    height: 340,
                                    // width: 480,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    backgroundColor: "#FFFFFF",
                                    padding: "40px",
                                    border: "none",
                                    borderRadius: "20px",
                                    boxShadow: "0 8px 10px rgb(48 127 245 / .08)",
                                }}
                            >
                                <Card
                                    sx={{
                                        height: 30,
                                        width: 100,
                                        backgroundImage: 'url("' + images["Review.svg"] + '")',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        mb: 2,
                                        border: "none",
                                    }}
                                />
                                <Typography variant="body2" gutterBottom sx={{ color: "#5E5C9B" }}>Love this site great support and very reasonable fees with excellent work.</Typography>
                                <Typography variant="body2" fontWeight={600} gutterBottom sx={{ mt: 2 }}>dhatley</Typography>
                                <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>January 11th, 2024</Typography>
                            </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card
                                sx={{
                                    height: 340,
                                    // width: 480,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    backgroundColor: "#FFFFFF",
                                    padding: "40px",
                                    border: "none",
                                    borderRadius: "20px",
                                    boxShadow: "0 8px 10px rgb(48 127 245 / .08)",
                                }}
                            >
                                <Card
                                    sx={{
                                        height: 30,
                                        width: 100,
                                        backgroundImage: 'url("' + images["Review.svg"] + '")',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        mb: 2,
                                        border: "none",
                                    }}
                                />
                                <Typography variant="body2" gutterBottom sx={{ color: "#5E5C9B" }}>This is the second business name we have used this service for. Loved the entire process.</Typography>
                                <Typography variant="body2" fontWeight={600} gutterBottom sx={{ mt: 2 }}>srfaris</Typography>
                                <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>December 13th, 2023</Typography>
                            </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card
                                sx={{
                                    height: 340,
                                    // width: 480,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    backgroundColor: "#FFFFFF",
                                    padding: "40px",
                                    border: "none",
                                    borderRadius: "20px",
                                    boxShadow: "0 8px 10px rgb(48 127 245 / .08)",
                                }}
                            >
                                <Card
                                    sx={{
                                        height: 30,
                                        width: 100,
                                        backgroundImage: 'url("' + images["Review.svg"] + '")',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        mb: 2,
                                        border: "none",
                                    }}
                                />
                                <Typography variant="body2" gutterBottom sx={{ color: "#5E5C9B" }}>You made the naming process so much easier by having such a vast array of options. It really helped our marketing team.</Typography>
                                <Typography variant="body2" fontWeight={600} gutterBottom sx={{ mt: 2 }}>hjknapp</Typography>
                                <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>November 21st, 2023</Typography>
                            </Card>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Card
                                sx={{
                                    height: 340,
                                    // width: 480,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    backgroundColor: "#FFFFFF",
                                    padding: "40px",
                                    border: "none",
                                    borderRadius: "20px",
                                    boxShadow: "0 8px 10px rgb(48 127 245 / .08)",
                                }}
                            >
                                <Card
                                    sx={{
                                        height: 30,
                                        width: 100,
                                        backgroundImage: 'url("' + images["Review.svg"] + '")',
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        mb: 2,
                                        border: "none",
                                    }}
                                />
                                <Typography variant="body2" gutterBottom sx={{ color: "#5E5C9B" }}>This is the second business name we have used this service for. Loved the entire process.</Typography>
                                <Typography variant="body2" fontWeight={600} gutterBottom sx={{ mt: 2 }}>srfaris</Typography>
                                <Typography variant="body2" gutterBottom sx={{ mt: 2 }}>December 13th, 2023</Typography>
                            </Card>
                        </SwiperSlide>
                    </Swiper>
                </Box>
            </Box>
        </>
    );
}