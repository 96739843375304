import MetaTags from "../componets/MetaTags";
import Footer from "./Footer";

export default function PrivacyPolicy() {

    return (
        <>
            <MetaTags title="Privacy Policy" description="Welcome to the Xidomains Website, by reaching to our site we hope you consent to restrict by the given Privacy Policy." />
            <div className="p-5">
                <div className="max-w-[900px] m-auto p-8 bg-[#FBFBFF] text-[#19184A] font-[Inter,sans-serif] rounded-3xl shadow-lg">
                    <h1 className="text-3xl mb-5 text-center">Privacy Policy</h1>
                    <p className="mb-3 text-sm">Privacy of our clients is highly essential for us. Accordingly, our XiDomains developed Privacy Policy, so that you may understand the way, in which we use, collect, disclose and communicate our personal information. Our Privacy Policy explains about the collection of data and use practices of our official website and it’s API. However, our policy is inapplicable for products, services or practices related to any offline or online websites. Hence, whenever our visitors/clients access our website, they consent our collected information use the necessary practices, as we have mentioned in our Privacy Policy.</p>
                    <ul className="list-disc pl-5 mt-3">
                        <li className="text-sm">
                            <h4 className="text-2xl mb-3">Coverage of Our Privacy Policy</h4>
                            <p className="mb-3 text-sm">Privacy Policy of our company covers the way, in which we deal with your collected Personal Information when you access them or use our Services. However, our coverage does not contain companies practices, which we do not have control or own and people whom we do not manage.</p>
                            <p className="mb-3 text-sm">We use your Personal Information related to our offered services. These will include personalization and bringing improvement in our offered services, so that you may setup your user profile and account, contact you and allow users to help in contacting you. Even we collect Personal Information to fulfill your made requests for specific products and services, to analyze the way of using our offered services and to deliver and bring improvement of your Services. In special case, we also share personal information with our third parties but in accordance with the one we have explained here.</p>
                            {/* <p className="mb-3 text-sm">You should keep in mind that NationBuilder and other similar Services allow you deliver Personal Information regarding your own visitors, users, voters and supporters. Privacy Policy deals with the usage of your Personal Information and not with the information possessed by Third Party, which you may provide related to the Services.</p> */}
                            <p className="mb-3 text-sm">Our XiDomains does not take any responsibility for practices of websites linked from or to the Services or for nation’s privacy practices operated by other customers available on Services. Hence, before you go to interact with any of the nations or sites, you should make sure reviewing their policies and rules before you should provide them with private information.</p></li>
                        <li className="text-sm">
                            <h4 className="text-2xl mb-3">Special Intimation about Minors and Children</h4>
                            <p className="mb-3 text-sm">As we have mentioned in our Terms, we never solicit or collect personal information intentionally from any person below 13years or knowingly allow such individuals to register with our services. If you have not completed 13years, you should not send any detail, such as name, email address, telephone number or address to us. In case, we identify of collecting personal information from 13years below children without proper verification of parental consent we will immediately the respective information. In case of any concern, you should approach us at hello@xidomains.com.</p></li>
                        <li className="text-sm">
                            <h4 className="text-2xl mb-3">Collection of Personal Information from You</h4>
                            <p className="mb-3 text-sm">Whenever you sign up to get our services, you provide us your name, your email address and other similar type of information. Even we collect details from the social media accounts of our visitors.</p>
                            <p className="mb-3 text-sm">Whenever you use our Services, we send cookies to your PC/laptop for identifying your browser and allow you boost the overall experience with our offered services. You may setup the web browser to refuse cookies or indicate whenever we send a cookie. However, in case you opt to disable the accept cookies, a few of our services may fail to function in a right way. We opt to use tiny images referred as GIFs to keep a track on our users’ behaviors, including statistics related to opening our emails.</p>
                            <p className="mb-3 text-sm">Our XiDomains servers record specific log file information automatically reported from the online browser whenever you gain access to the services. Our server logs contain information about the service page/pages you visit, type of browser you use, IP address and other details on ways, in which you opt to interact with our services. We delete these log files after a period of one week. Our XiDomains utilizes Google Analytics, so that you may understand the importance/usage of offered services.</p></li>
                        <li className="text-sm">
                            <h4 className="text-2xl mb-3">Usage of Our Visitors’ Personal Information</h4>
                            <p className="mb-3 text-sm">We use your submitted personal information to maintain, operate and provide you with the necessary functions and features related to the service. Our team may use specific information about our visitors internally to analyze the use of your services, diagnose services or technical issues, maintain the level of security and personalize contents.</p>
                            <p className="mb-3 text-sm">By simply providing your email address to XiDomains, you become content to use our email address to send you notices related to our services, including any notice according to the laws based on postal mail communication. Even you agree that we can send Service activity notifications to your provided email address as per your applicable privacy settings. We may use the email address to send changes in our Service features, newsletters and special offers to your email address. In case you are unwilling to receive any of the mentioned email messages, you should opt for unsubscribe from it. After deactivation or termination of your XiDomains account, you may retain the information related to your profile to public service areas.</p></li>
                        <li className="text-sm">
                            <h4 className="text-2xl mb-3">Usage of Cookies</h4>
                            <p className="mb-3 text-sm">We use clear GIFs, cookies and details related to log files to-</p>
                            <ul className="list-disc pl-5 mt-3 mb-5">
                                <li className="text-sm">Remember the provided information, so that you do not require re-entering it whenever you visit our website next time</li>
                                <li className="text-sm">To provide personalized, custom information and content</li>
                                <li className="text-sm">To monitor overall effectiveness of our offered service</li>
                                <li className="text-sm">To monitor aggregate metrics, including exact number of visitors, traffic level and demographic patterns</li>
                                <li className="text-sm">To fix and diagnose technological problems, as mentioned by our engineers or users associated with specific IP addresses</li>
                                <li className="text-sm">To help in accessing information after signing in efficiently</li>
                            </ul>
                        </li>
                        <li className="text-sm">
                            <h4 className="text-2xl mb-3">How We Use Your Information</h4>
                            <ul className="list-disc pl-5 mt-3 mb-5">
                                <li className="text-sm"><strong className="mr-2">Personally Identifiable Information:</strong>Our XiDomains never sells or rents the personally identifiable information to other people. Instead, we may share the respective information with third parties to provide you required service. In case we do this, third parties using the provided information remain bound with our Privacy Policy. Even we may opt to store your personal information in specific locations, which remain outside of the direct control of our XiDomains, such as our databases or servers co-located with our hosting providers.
                                    <p className="mb-3 text-sm">As we start with developing our business, we make the purchase or sell assets and/or business offerings. Visitors’ information and customers’ emails are transferable business assets in the mentioned types of transactions. We even opt to assign or transfer the personally identifiable information in the course of corporate dissolution, mergers and divestitures.</p>
                                    <p className="mb-3 text-sm">Any personal content or information disclosed voluntarily to post to the Service becomes available publicly, as controlled from any applicable website customization or privacy settings. To bring changes in the Service privacy settings, you have to visit the account settings page of XiDomains. In case you delete your posted information to the Service, copies of it remain viewable in archived or cached Service pages or in case any other user contains copied or save the respective information.</p>
                                    <p className="mb-3 text-sm">We regularly organize special offers, contests and other related activities or events on Service page in combination with third party partners. If you provide any information to respective third parties, you will give permission to them for the same Event or any other related usage approved by you. We are unable to control the use of information provided by you to third parties. If you are reluctant to share your information with any third party, you may avoid participating in such Events.</p>
                                    <p className="mb-3 text-sm">Excluding the ones mentioned in our Privacy Policy, XiDomains never discloses any personal information of visitors to third parties until and unless required doing so based on subpoena or law or in case the website believes that doing so is important to-</p>
                                    <ul className="list-disc pl-5 mt-3">
                                        <li className="text-sm">Confirm by the law as well as comply with the necessary legal procedures served or our affiliates</li>
                                        <li className="text-sm">Search, avoid and take the necessary actions related to actual or suspected illegal activities</li>
                                        <li className="text-sm">To enforce the necessary Terms of Services, take the necessary precautions against your liability</li>
                                        <li className="text-sm">To investigate as well as defend us against any allegation or claim made by third-party/parties</li>
                                        <li className="text-sm">To help government enforced agencies</li>
                                        <li className="text-sm">To protect integrity and security of our website</li>
                                        <li className="text-sm">To exercise and safeguard the rights, personal safety and property of our XiDomains, our visitors, clients and other individuals/parties</li>
                                    </ul>
                                </li>
                                <li className="text-sm"><strong className="mr-2">Non-Personally Identifiable Information:</strong>Our team may share any non-personally identifiable details, like referring or exit pages, anonymous usage data, URLs, types of platforms and exact number of clicks with our interested third parties. In this way, we allow understanding of usage patterns associated with specific XiDomains services.</li>
                            </ul>
                        </li>
                        <li className="text-sm">
                            <h4 className="text-2xl mb-3">Control of Personal Information</h4>
                            <p className="mb-3 text-sm">With the help of your account settings, you will gain access and in some of the cases, you may edit and/or delete your provided information-</p>
                            <ul className="list-disc pl-5 mt-3 mb-5">
                                <li className="text-sm">name and password</li>
                                <li className="text-sm">email</li>
                                <li className="text-sm">Your information about user profile containing any content you already upload to our service</li>
                                <li className="text-sm">Your location</li>
                            </ul>
                            <p className="mb-3 text-sm">With the change of Services, the information you update, view or delete changes. In case you have any queries related to view or update information we contain on our file, you are free to contact with our team at hello@XiDomains.com.</p></li>
                        <li className="text-sm">
                            <h4 className="text-2xl mb-3">Data Security</h4>
                            <p className="mb-3 text-sm">We protect your account by providing a password for your security and privacy. You have to avoid unauthorized access to the account and personal information by simply selection as well as securing your password in a right way. Even you should make sure of limiting the access to your PC or any other device as well as browser by simply sign off after you complete with accessing the account.</p>
                            <p className="mb-3 text-sm">We always endeavor to safeguard your account privacy and other related personal information we keep in our online records. However, we fail to guarantee the overall security of your information. Software or hardware failure, unauthorized usage or entry and any other similar factors may result in a compromise of the user information security during any time.</p></li>
                        <li className="text-sm">
                            <h4 className="text-2xl mb-3">Change to Our Privacy Policy Statement</h4>
                            <p className="mb-3 text-sm">We may opt to update our Privacy Policy on a timely basis. We encourage our visitors to review our privacy policy periodically to stay aware about our essential practices. Your consistent usage of our website forms your agreement with our Privacy Policy and any other related updates.</p></li>
                        <li className="text-sm">
                            <h4 className="text-2xl mb-3">Enforcement of the Privacy Policy</h4>
                            <p className="mb-3 text-sm">In case you have any query about our statement, you should contact with our XiDomains team by simply mailing us at hello@xidomains.com.</p></li>
                        <li className="text-sm">
                            <h4 className="text-2xl mb-3">Contact Details</h4>
                            {/* <p className="mb-3 text-sm">Social Benchers have created the XiDomains tool, which is a registered organization under the Social Benchers LLC in USA.</p> */}
                            <p className="mb-3 text-sm">XiDomains welcomes comments from visitors about the Privacy Policy. In case you perceive any adherence from our mentioned privacy policy, please email us or send a letter to the postal address mentioned here. We will resolve your problem immediately.</p>
                            {/* <p className="mb-3 text-sm">Social Benchers LLC,<br />
                                2035 Sunset Lake Road<br />
                                Suite B-2, Newark 19702<br />
                                hello@xidomains.com
                            </p> */}
                        </li>
                        <li className="text-sm">
                            <h4 className="text-2xl mb-3">Additional Information for Users Located in the European Economic Area (“EEA”)</h4>
                            <p className="mb-3 text-sm"><strong className="mr-2">IMPORTANT NOTICE:</strong><br /><br />
                                This Privacy Policy originates and is hosted on a Website located in United States, which has different data protection laws from those of the European Economic Area (EEA). Be aware that law enforcement agencies in United States may have access to your Personal Information under certain circumstances.</p>
                            <p className="mb-3 text-sm">If you are a resident of the European Union, the General Data Protection Regulation (“GDPR”) is applicable from May 25, 2018. We are committed to safeguarding your privacy and ensuring compliance with GDPR regulations.</p>
                            <p className="mb-3 text-sm">If you reside outside of United States, by using XiDomainsApp, you acknowledge and consent to the collection, transmission, and storage of your Personal Information outside your country of residence.<br />
                                <br /><strong className="mr-2">Transfer of Data Outside of the EEA:</strong><br /><br />
                                We may transfer some or all of your personal information outside the EEA. Adequate safeguards will be in place to ensure compliance with privacy laws in the receiving country, which may differ from those in the EEA.</p>
                            <p className="mb-3 text-sm"><strong className="mr-2">Your Rights:</strong><br /><br />
                                As a user, you have the following rights regarding your information:</p>
                            <ul className="list-disc pl-5 mt-3 mb-5">
                                <li className="text-sm">The Right to be Informed:You have the right to clear, transparent information about how we use your data and your rights, as outlined in this Privacy Policy.</li>
                                <li className="text-sm">The Right of Access:You can obtain access to your information and certain other details, similar to what is provided in this Privacy Policy.</li>
                                <li className="text-sm">The Right to Rectification:You can request correction of inaccurate or incomplete information.</li>
                                <li className="text-sm">The Right to Erasure:Known as ‘the right to be forgotten,’ you can request the deletion of your information when there is no compelling reason for its retention, with exceptions.</li>
                                <li className="text-sm">The Right to Restrict Processing:You can request the blocking or suppression of further use of your information. Lists are maintained to respect restrictions in future.</li>
                                <li className="text-sm">The Right to Data Portability:You have the right to obtain and reuse your personal data for your purposes across different services, ensuring secure and easy transfer.</li>
                                <li className="text-sm">The Right to Object:You can object to certain types of processing, including processing for direct marketing, conducted only with your consent.</li>
                            </ul>
                            <p className="mb-3 text-sm">For any queries or to exercise your rights, contact us at hello@xidomains.com. We are dedicated to ensuring the privacy and security of your data on XiDomains App.</p></li>
                    </ul>
                </div>
            </div>
            <Footer />
        </>
    );
}