import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function importAll(r) {
    let images = {};
    r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
    return images
}

const images = importAll(require.context('../../assets/Images/Seller', false, /\.(png|jpe?g|svg)$/));
const banner_img = '' + images["SellerBanner.png"] + '';

export default function Banner() {
    const navigation = useNavigate();

    return (
        <>
            <Box sx={{
                maxWidth: '1440px',
                margin: '0 auto',
                padding: '40px 20px',
            }}>
                <div className='flex flex-wrap items-center justify-between'>
                    <div className="w-full md:w-[46%] text-left text-black">
                        <h1 className='text-[30px] font-bold md:text-[50px]'>Sell Your Domains Faster on Xidomains</h1>
                        <p className=' mt-2 mb-4'>Get everything you need to sell your domains effectively, from world-class marketing to powerful portfolio management tools. Join our community of Domain Investors today.</p>
                        <button onClick={() => { navigation('/register') }} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-3.5 me-2 mb-2 focus:outline-none text-[16px]">Register now <ArrowRightAltIcon /></button>
                    </div>
                    <div className='w-full md:w-2/4'>
                        <img src={banner_img} alt="seller" />
                    </div>
                </div>
            </Box>
        </>
    );
}