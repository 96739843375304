import { Typography } from '@mui/material';
// import { createSvgIcon } from '@mui/material/utils';
import React from 'react';
import { How1, How2, How3 } from '../Icon';

export default function HowItWork() {
    // const how_it_card = [
    //     { "title": "Order", "description": "Take your pick from our portfolio of premium domain names or let our experts guide you to the perfect pick. Ordering online has never been easier!" },
    //     { "title": "Transfer", "description": "Upon successful completion of your order, one of our domain specialists will reach out via email to kickstart the domain transfer process!" },
    //     { "title": "Assets", "description": "As part of your purchase, we will require basic detail from your side to provide you the requested domain detail and based on that we will give you required domain access." },
    // ]

    // const UserIcon = createSvgIcon(
    //     <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49" fill="none">
    //         <g clipPath="url(#clip0_1_210)">
    //             <path d="M45.2831 25.7817L37.37 22.617C37.0864 22.5024 36.5024 22.353 35.8403 22.617L27.9237 25.7817C27.1812 26.07 26.7 26.7661 26.7 27.4692C26.7 35.3161 31.4266 40.7442 35.8369 42.5091C36.4986 42.7727 37.0744 42.6233 37.3666 42.5091C40.89 41.1661 46.5 36.2864 46.5 27.4692C46.5 26.7661 46.0187 26.07 45.2831 25.7817ZM36.6 39.2325V25.9294L43.1656 28.5577C42.7806 34.6903 38.9788 38.0794 36.6 39.2325ZM17.9 24.72C22.7606 24.72 26.7 20.6904 26.7 15.72C26.7 10.7496 22.7606 6.71997 17.9 6.71997C13.0394 6.71997 9.1 10.7496 9.1 15.72C9.1 20.6904 13.0394 24.72 17.9 24.72ZM24.5413 28.538C23.5306 28.2567 22.4788 28.095 21.3856 28.095H14.4144C7.83569 28.095 2.5 33.5583 2.5 40.2872C2.5 41.6302 3.567 42.72 4.88287 42.72H30.9144C31.1305 42.72 31.3246 42.6494 31.5236 42.5942C27.9169 39.7528 24.7819 34.8661 24.5413 28.538Z" fill="#637CF7" />
    //         </g>
    //         <defs>
    //             <clipPath id="clip0_1_210">
    //                 <rect width="48" height="48" fill="white" transform="translate(0.5 0.719971)" />
    //             </clipPath>
    //         </defs>
    //     </svg>,
    //     'user',
    // );

    // const MulitUserIcon = createSvgIcon(
    //     <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49" fill="none">
    //         <g clipPath="url(#clip0_1_221)">
    //             <g clipPath="url(#clip1_1_221)">
    //                 <path d="M14.3 12.1201C14.3 14.4376 12.4175 16.3201 10.1 16.3201C7.78025 16.3201 5.9 14.4376 5.9 12.1201C5.9 9.80029 7.78025 7.92004 10.1 7.92004C12.4175 7.92004 14.3 9.80029 14.3 12.1201ZM16.13 20.2426C13.9025 22.2226 12.5 25.1101 12.5 28.3201C12.5 30.8926 13.3325 33.2551 14.9 35.1076V36.7201C14.9 38.0476 13.8275 39.1201 12.5 39.1201H7.7C6.37475 39.1201 5.3 38.0476 5.3 36.7201V34.7101C2.462 33.3601 0.5 30.4726 0.5 27.1201C0.5 22.4776 4.2605 18.7201 8.9 18.7201H11.3C13.0325 18.7201 14.765 19.2826 16.13 20.2426ZM5.3 23.9476C4.553 24.7876 4.1 25.9051 4.1 27.1201C4.1 28.3351 4.553 29.4526 5.3 30.2926V23.9476ZM34.1 36.7201V35.1076C35.6 33.2551 36.5 30.8926 36.5 28.3201C36.5 25.1101 35.0975 22.2226 32.87 20.2426C34.235 19.2826 35.9 18.7201 37.7 18.7201H40.1C44.7425 18.7201 48.5 22.4776 48.5 27.1201C48.5 30.4726 46.535 33.3601 43.7 34.7101V36.7201C43.7 38.0476 42.6275 39.1201 41.3 39.1201H36.5C35.1725 39.1201 34.1 38.0476 34.1 36.7201ZM43.7 30.2926C44.3825 29.4526 44.9 28.3351 44.9 27.1201C44.9 25.9051 44.3825 24.7876 43.7 23.9476V30.2926ZM43.1 12.1201C43.1 14.4376 41.2175 16.3201 38.9 16.3201C36.5825 16.3201 34.7 14.4376 34.7 12.1201C34.7 9.80029 36.5825 7.92004 38.9 7.92004C41.2175 7.92004 43.1 9.80029 43.1 12.1201ZM19.7 12.7201C19.7 10.0688 21.8525 7.92004 24.5 7.92004C27.1475 7.92004 29.3 10.0688 29.3 12.7201C29.3 15.3676 27.1475 17.5201 24.5 17.5201C21.8525 17.5201 19.7 15.3676 19.7 12.7201ZM34.1 28.3201C34.1 31.6726 32.135 34.5601 29.3 35.9101V39.1201C29.3 40.4476 28.2275 41.5201 26.9 41.5201H22.1C20.7725 41.5201 19.7 40.4476 19.7 39.1201V35.9101C16.865 34.5601 14.9 31.6726 14.9 28.3201C14.9 23.6776 18.6575 19.9201 23.3 19.9201H25.7C30.3425 19.9201 34.1 23.6776 34.1 28.3201ZM19.7 31.4926V25.1476C18.95 25.9876 18.5 27.1051 18.5 28.3201C18.5 29.5351 18.95 30.6526 19.7 31.4926ZM29.3 25.1476V31.4926C29.9825 30.6451 30.5 29.5351 30.5 28.3201C30.5 27.1051 29.9825 25.9876 29.3 25.1476Z" fill="#637CF7" />
    //             </g>
    //         </g>
    //         <defs>
    //             <clipPath id="clip0_1_221">
    //                 <rect width="48" height="48" fill="white" transform="translate(0.5 0.719971)" />
    //             </clipPath>
    //             <clipPath id="clip1_1_221">
    //                 <rect width="48" height="48" fill="white" transform="translate(0.5 0.719971)" />
    //             </clipPath>
    //         </defs>
    //     </svg>,
    //     'multiuser',
    // );

    // const AnalyticIcon = createSvgIcon(
    //     <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49" fill="none">
    //         <g clipPath="url(#clip0_1_232)">
    //             <g mask="url(#mask0_1_232)">
    //                 <path d="M25.7 6.76352C25.7 6.08664 26.1575 5.52002 26.9 5.52002C36.1775 5.52002 43.7 13.0425 43.7 22.32C43.7 22.995 43.13 23.52 42.455 23.52H25.7V6.76352ZM5.30005 25.92C5.30005 16.8225 12.0575 9.29552 20.7575 8.08877C21.515 7.99427 22.1 8.54702 22.1 9.24077V27.12L33.8375 38.8575C34.34 39.36 34.3025 40.185 33.725 40.5975C30.785 42.69 27.185 43.92 23.3 43.92C13.3625 43.92 5.30005 35.865 5.30005 25.92ZM44.78 27.12C45.47 27.12 46.025 27.705 45.935 28.395C45.3575 32.5875 43.3325 36.315 40.3925 39.0675C39.9425 39.4275 39.2375 39.4575 38.8025 39.0225L26.9 27.12H44.78Z" fill="#637CF7" />
    //             </g>
    //         </g>
    //         <defs>
    //             <clipPath id="clip0_1_232">
    //                 <rect width="48" height="48" fill="white" transform="translate(0.5 0.719971)" />
    //             </clipPath>
    //         </defs>
    //     </svg>,
    //     'analytic',
    // );

    return (
        <div className='bg-[#F0F2FF] color-[#040715] p-6' id="how_it_works">
            <div className='max-w-[1440px] mx-auto my-2 p-0 md:p-4'>
                <Typography variant="h4" fontWeight={600} gutterBottom sx={{ mt: 2, mb: 2 }}>How it Works?</Typography>
                <div className='flex flex-wrap justify-between items-start'>
                    <div className='flex flex-col items-start w-full mx-3 my-2 max-w-[450px] md:w-[31%]'>
                        <How1 sx={{ width: 60, height: 60 }} />
                        <h1 className='text-[22px] font-bold md:text-[32px] mb-1 mt-2'>Order</h1>
                        <p className='text-[14px] md:text-[16px]'>Take your pick from our portfolio of premium domain names or let our experts guide you to the perfect pick. Ordering online has never been easier!</p>
                    </div>
                    <div className='flex flex-col items-start w-full mx-3 my-2 max-w-[450px] md:w-[31%]'>
                        <How2 sx={{ width: 60, height: 60 }} />
                        <h1 className='text-[22px] font-bold md:text-[32px] mb-1 mt-2'>Transfer</h1>
                        <p className='text-[14px] md:text-[16px]'>Upon successful completion of your order, one of our domain specialists will reach out via email to kickstart the domain transfer process!</p>
                    </div>
                    <div className='flex flex-col items-start w-full mx-3 my-2 max-w-[450px] md:w-[31%]'>
                        <How3 sx={{ width: 60, height: 60 }} />
                        <h1 className='text-[22px] font-bold md:text-[32px] mb-1 mt-2'>Assets</h1>
                        <p className='text-[14px] md:text-[16px]'>As part of your purchase, we will require basic detail from your side to provide you the requested domain detail and based on that we will give you required domain access.</p>
                    </div>
                </div>
                {/* <br />
                <Button variant="contained" sx={{
                    color: '#637CF7',
                    fontSize: 18,
                    backgroundColor: "#FFFFFF",
                    minWidth: 180,
                    boxShadow: 'none',
                    textShadow: 'none',
                    border: 'none',
                    mt: { xs: 0, sm: 5 },
                    fontWeight: 600,
                    borderRadius: '5px',
                    padding: '15px',
                    '&:hover': {
                        background: '#ffffff',
                    },
                }}>
                    Contact Us
                </Button> */}
            </div>
        </div>
    );
}