import { Stack, createSvgIcon } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export default function PerfectBrand() {

    const RightArrow = createSvgIcon(
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="31" viewBox="0 0 36 31" fill="none">
            <g clipPath="url(#clip0_1_564)">
                <path d="M24 9.91983L30 15.9198M30 15.9198L24 21.9198M30 15.9198H6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_1_564">
                    <rect width="26" height="14" fill="white" transform="translate(5 8.91983)" />
                </clipPath>
            </defs>
        </svg>,
        'right_arrow',
    );
    const RightRoundArrow = createSvgIcon(
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
            <rect y="0.738037" width="20" height="20" rx="10" fill="#425CD9" />
            <path d="M14 10.738C13.9968 10.3873 13.8555 10.0519 13.6067 9.80467L10.7467 6.938C10.6218 6.81384 10.4528 6.74414 10.2767 6.74414C10.1006 6.74414 9.9316 6.81384 9.80669 6.938C9.7442 6.99998 9.69461 7.07371 9.66076 7.15495C9.62691 7.23619 9.60949 7.32333 9.60949 7.41134C9.60949 7.49934 9.62691 7.58648 9.66076 7.66772C9.69461 7.74896 9.7442 7.82269 9.80669 7.88467L12 10.0713H5.33335C5.15654 10.0713 4.98697 10.1416 4.86195 10.2666C4.73693 10.3916 4.66669 10.5612 4.66669 10.738C4.66669 10.9148 4.73693 11.0844 4.86195 11.2094C4.98697 11.3344 5.15654 11.4047 5.33335 11.4047H12L9.80669 13.598C9.68115 13.7227 9.61027 13.8921 9.60965 14.069C9.60902 14.2459 9.6787 14.4158 9.80335 14.5413C9.92801 14.6669 10.0974 14.7377 10.2743 14.7384C10.4512 14.739 10.6212 14.6693 10.7467 14.5447L13.6067 11.678C13.8571 11.4291 13.9985 11.0911 14 10.738Z" fill="white" />
        </svg>,
        'right_round_arrow',
    );

    return (
        <>
            <div className='bg-[#F0F2FF] py-8 md:p-5'>
                <div className='max-w-[1440px] m-auto p-5 md:py-10'>
                    <Stack className='justify-between' direction={{ xs: 'column', sm: 'row' }} flexWrap="wrap">
                        <div className='custom_before_start relative overflow-hidden w-full md:w-[50%] bg-[#425CD9] text-white p-5 flex items-center flex-col justify-center text-center rounded-xl mb-12 md:mb-0'>
                            <h1 className='text-[25px] font-bold md:text-[35px] mb-2'>Find The Perfect Brand Name Today!</h1>
                            <div className="flex mt-2 md:mt-0 justify-center md:justify-between items-center flex-wrap">
                                <button type="button" className="text-[#425CD9] bg-white hover:bg-transparent border hover:text-white focus:ring-4 focus:ring-white font-medium rounded-lg text-sm px-5 py-3.5 focus:outline-none text-[16px]">Explore Names for Sale <RightArrow /></button>
                                {/* <p className='my-2 md:my-0 md:mx-4 w-full md:w-auto'>Or</p>
                                <button type="button" className="text-white hover:bg-white hover:text-[#425CD9] border focus:ring-4 focus:ring-white font-medium rounded-lg text-sm px-5 py-3.5 focus:outline-none text-[16px]">I want a Custom Name <RightArrow /></button> */}
                            </div>
                        </div>
                        <div className='custom_after_start relative overflow-hidden w-full border p-5 border-[#4D4D4D] rounded-xl md:w-[48%] flex flex-col items-start justify-center'>
                            <h1 className='text-[22px] font-bold md:text-[32px] mb-2'>Why Xidomain.com?</h1>
                            <div className='flex flex-col mt-2'>
                                <div className='mb-5'>
                                    <div className="flex justify-start items-center font-bold text-[16px] md:text-[22px] mb-2">
                                        <RightRoundArrow />
                                        <span className="ml-2">Disrupting The Traditional Agency Model</span>
                                    </div>
                                    <p className='text-[14px] md:text-[16px] ml-7 '>Atom.com is reimagining how naming is done, providing fast, affordable, high-quality, soup-to-nuts solutions unlike anyone else.</p>
                                </div>
                                <div>
                                    <div className="flex justify-start items-center font-bold text-[16px] md:text-[22px] mb-2">
                                        <RightRoundArrow />
                                        <span className="ml-2">Satisfaction Guarantee</span>
                                    </div>
                                    <p className='text-[14px] md:text-[16px] ml-7 '>We have policies in place to ensure that you are satisfied with your experience.</p>
                                </div>
                                <div className='mt-4 ml-7 flex justify-start items-center '>
                                    <div className='underline'>
                                        <Link to="/">Contest Policies</Link>
                                    </div>
                                    <div className='ml-2 md:ml-5 underline'>
                                        <Link to="/">Marketplace Policies</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Stack>
                </div>
            </div>
        </>
    );
}