import MenuIcon from "@mui/icons-material/Menu";
import { Badge, Divider, Drawer, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import { Heart, Search } from "../componets/Icon";
import { logout } from "../helper/helper";

// const pages = [
//   "Buy a domain",
//   "Sell a domain",
//   "About Us",
//   "How it works",
//   "Contact Us",
// ];
const pages = [
  // { title: "Buy a domain", link: "/buydomain" },
  { title: "Sell a Domain", link: "/selldomain" },
  // { title: "About Us", link: "/#how_it_works" },
  { title: "How it works", link: "/#how_it_works" },
  { title: "Contact Us", link: "/contact" },
  // { title: "Register", link: "/register" },
  // { title: "Login", link: "/login" },
];
// let settings = [
//   {
//     name: "Login",
//     link: "/login",
//     icon: <AccountCircle sx={{ color: "#000000" }} />,
//   },
//   {
//     name: "Register",
//     link: "/register",
//     icon: <AccountCircle sx={{ color: "#000000" }} />,
//   },
// ];

// function LogoutButton() {
//   const navigate = useNavigate();

//   const handleLogout = async () => {
//     await logout();
//     navigate("/");
//     window.location.reload();
//   };

//   return (
//     <MenuItem onClick={handleLogout}>
//       <LogoutIcon sx={{ color: "#000000" }} />
//       <Typography sx={{ marginLeft: 1 }} textAlign="center">
//         Logout
//       </Typography>
//     </MenuItem>
//   );
// }

function importAll(r) {
  let images = {};
  r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
  return images
}

const images = importAll(require.context('../assets/Images', false, /\.(png|jpe?g|svg)$/));

function HeaderBar() {
  // const [{ apiData }] = useFetch();
  const { user } = React.useContext(UserContext);
  const [anchorElNav, setAnchorElNav] = useState(null);
  // const [anchorElUser, setAnchorElUser] = useState(null);
  const [wishlistCount, setwishlistCount] = useState(0);
  const navigations = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setloading] = useState(true);
  const { pathname, hash } = useLocation();

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  // const handleOpenNavMenu = (event) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  // const handleMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  const handleCloseNavMenu = (link) => {
    if (link !== "") {
      navigations(link);
      if (hash) {
        setTimeout(() => {
          const element = document.getElementById(hash.replace("#", ""));
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
            // window.history.replaceState(null, null, window.location.pathname);
          }
        }, 100);
      }
    }
    setAnchorElNav(null);
  };

  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };
  // const handleNavigateUserMenu = (link) => {
  //   navigations(link);
  //   setAnchorElUser(null);
  // };
  const handleRedirect = (value) => {
    navigations("/");
  };


  const handleLogout = async () => {
    await logout();
    navigations("/");
    window.location.reload();
  };


  // if (apiData) {
  //   // console.log('appp', apiData)
  //   settings = [
  //     {
  //       name: "Dashboard",
  //       link: "/dashboard",
  //       icon: <DashboardIcon sx={{ color: "#000000" }} />,
  //     },
  //     {
  //       name: "My Account",
  //       link: "/dashboard/account",
  //       icon: <AccountCircle sx={{ color: "#000000" }} />,
  //     },
  //     { name: "Logout", element: <LogoutButton /> },
  //   ];
  // } else {
  //   settings = [
  //     {
  //       name: "Login",
  //       link: "/login",
  //       icon: <AccountCircle sx={{ color: "#000000" }} />,
  //     },
  //     {
  //       name: "Register",
  //       link: "/register",
  //       icon: <AccountCircle sx={{ color: "#000000" }} />,
  //     },
  //   ];
  // }

  useEffect(() => {
    // setloading(true);
    if (user && user !== "") {
      setwishlistCount(user.wishlist.length)
    }
    setTimeout(() => {
      setloading(false);
    }, 100);
  }, [user])


  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <Typography
        variant="h6"
        noWrap
        sx={{
          fontWeight: 700,
          fontSize: 20,
          width: "100%",
          p: 2,
          textDecoration: "none",
          textAlign: "cente",
          cursor: "pointer",
        }}
        onClick={handleRedirect}
      >
        <span className="text-[#425CD9]">XI</span> DOMAINS
      </Typography>

      <Divider />
      <List>
        {pages && pages.length > 0 && pages.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton onClick={() => handleCloseNavMenu(item.link)}>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
        {user ?
          <>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleCloseNavMenu('/dashboard')}>
                <ListItemText primary={"Dashboard"} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleLogout()}>
                <ListItemText primary={"Log out"} />
              </ListItemButton>
            </ListItem>
          </>
          :
          <>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleCloseNavMenu('/register')}>
                <ListItemText primary={"Register"} />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleCloseNavMenu('/login')}>
                <ListItemText primary={"Login"} />
              </ListItemButton>
            </ListItem>
          </>
        }
      </List>
    </Box>
  );

  return (
    <>
      {!loading &&
        <>
          <div className="announcement_bar_main font-bold">
            <h1>Let's make your online dreams a reality!</h1>
          </div>
          <AppBar
            position="static"
            sx={{
              backgroundColor: "#FBFBFF",
              color: "#000000",
              boxShadow: "4px 4px 13px 0 rgba(0, 0, 0, 0.15)",
              fontFamily: "inter",
            }}
          >
            <Container sx={{ maxWidth: "1440px !important" }}>
              <Toolbar disableGutters sx={{
                justifyContent: "space-between",
                alignItems: "center",
              }}>
                <Typography
                  variant="h6"
                  noWrap
                  sx={{
                    mr: 2,
                    display: { xs: "none", md: "flex" },
                    fontWeight: 700,
                    justifyContent: "space-between",
                    alignItems: "center",
                    fontSize: 20,
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                  onClick={handleRedirect}
                >
                  <img src={images['XIDOMAINS.png']} alt="logo" />
                </Typography>

                <Box sx={{ display: { xs: "flex", md: "none" } }}>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={toggleDrawer(true)}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                  <Drawer open={open} onClose={toggleDrawer(false)}>
                    {DrawerList}
                  </Drawer>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={() => handleCloseNavMenu('')}
                    sx={{
                      display: { xs: "block", md: "none" },
                    }}
                  >
                    {pages.map((page, index) => (
                      <MenuItem key={index} onClick={() => handleCloseNavMenu(page.link)}>
                        <Typography textAlign="center">{page.title}</Typography>
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>

                <Typography
                  variant="h5"
                  noWrap
                  sx={{
                    display: { xs: "flex", md: "none" },
                    justifyContent: "space-between",
                    alignItems: "center",
                    // flexGrow: 1,
                    fontWeight: 700,
                    letterSpacing: ".3rem",
                    color: "inherit",
                    textDecoration: "none",
                  }}
                  onClick={handleRedirect}
                >
                  <img src={images['XIDOMAINS.png']} alt="logo" />
                </Typography>
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "none", md: "flex" },
                    justifyContent: "center",
                  }}
                >
                  {pages.map((page, index) => (
                    <Button
                      key={index}
                      className={pathname === page.link ? "!text-[#1d4ed8]" : "!text-[#19184A]"}
                      onClick={() => handleCloseNavMenu(page.link)}
                      sx={{ my: 2, display: "block", ml: 2 }}
                    >
                      {page.title}
                    </Button>
                  ))}
                </Box>

                {/* <Box sx={{ flexGrow: 0 }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                sx={{ mt: "45px", color: "inherit" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((item, index) => (
                  <div key={index}>
                    {item.link ? (
                      <>
                        <MenuItem
                          onClick={(e) => handleNavigateUserMenu(item.link)}
                        >
                          {item.icon}
                          <Typography sx={{ marginLeft: 1 }} textAlign="center">
                            {item.name}
                          </Typography>
                        </MenuItem>
                      </>
                    ) : (
                      item.element
                    )}
                  </div>
                ))}
              </Menu>
            </Box> */}
                <div className="flex justify-between flex-wrap">
                  <IconButton size="large" color="inherit" onClick={() => navigations('/search')}>
                    <Search sx={{ color: "#000" }} />
                  </IconButton>
                  {user ?
                    <>
                      {user.user_type === "2" &&
                        <IconButton size="large" color="inherit" onClick={() => navigations('/dashboard/wishlist')}>
                          <Badge badgeContent={wishlistCount} color="primary">
                            <Heart />
                          </Badge>
                        </IconButton>
                      }
                      <div className="!hidden md:!flex justify-between items-center ml-1 font-[Inter,sans-serif]">
                        <button onClick={() => { handleLogout() }} type="button" className="text-[#425CD9] bg-white hover:bg-white border border-[#425CD9] focus:ring-4 font-bold rounded-lg text-sm px-4 py-3 me-2 focus:outline-none text-[16px]">Log out</button>
                        <button onClick={() => { navigations('/dashboard') }} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 font-bold rounded-lg text-sm px-4 py-3 me-2 focus:outline-none text-[16px]">Dashboard</button>
                      </div>
                    </>
                    :
                    <>
                      <IconButton size="large" color="inherit" onClick={() => navigations('/dashboard/wishlist')}>
                        <Badge badgeContent={wishlistCount} color="primary">
                          <Heart />
                        </Badge>
                      </IconButton>
                      <div className="!hidden md:!flex justify-between items-center ml-1 font-[Inter,sans-serif]">
                        <button onClick={() => { navigations('/register') }} type="button" className="text-[#425CD9] bg-white hover:bg-white border border-[#425CD9] focus:ring-4 font-bold rounded-lg text-sm px-4 py-3 me-2 focus:outline-none text-[16px]">Sign up</button>
                        <button onClick={() => { navigations('/login') }} type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 font-bold rounded-lg text-sm px-4 py-3 me-2 focus:outline-none text-[16px]">Log in</button>
                      </div>
                    </>
                  }

                </div>
              </Toolbar>
            </Container>
          </AppBar>
          <Outlet />
        </>
      }
    </>
  );
}

export default HeaderBar;
