import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/X';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';

function importAll(r) {
  let images = {};
  r.keys().forEach((item) => { images[item.replace('./', '')] = r(item); });
  return images
}

const images = importAll(require.context('../assets/Images', false, /\.(png|jpe?g|svg)$/));
const banner_img = 'url("' + images["Footer.png"] + '")';


export default function Footer() {

  const { hash } = useLocation();

  // const RightArrow = createSvgIcon(
  //   <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
  //     <path d="M18 12.4761C17.9951 11.95 17.7832 11.447 17.41 11.0761L13.12 6.77614C12.9326 6.58989 12.6792 6.48535 12.415 6.48535C12.1508 6.48535 11.8974 6.58989 11.71 6.77614C11.6163 6.86911 11.5419 6.97971 11.4911 7.10157C11.4403 7.22343 11.4142 7.35413 11.4142 7.48614C11.4142 7.61816 11.4403 7.74886 11.4911 7.87072C11.5419 7.99258 11.6163 8.10318 11.71 8.19614L15 11.4761H5C4.73478 11.4761 4.48043 11.5815 4.29289 11.769C4.10536 11.9566 4 12.2109 4 12.4761C4 12.7414 4.10536 12.9957 4.29289 13.1833C4.48043 13.3708 4.73478 13.4761 5 13.4761H15L11.71 16.7661C11.5217 16.9531 11.4154 17.2072 11.4144 17.4726C11.4135 17.738 11.518 17.9928 11.705 18.1811C11.892 18.3694 12.1461 18.4758 12.4115 18.4767C12.6768 18.4776 12.9317 18.3731 13.12 18.1861L17.41 13.8861C17.7856 13.5128 17.9978 13.0057 18 12.4761Z" fill="#425CD9" />
  //   </svg>,
  //   'right_arrow',
  // )

  const handleScroll = () => {
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash.replace("#", ""));
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          // window.history.replaceState(null, null, window.location.pathname);
        }
      }, 100);
    }
  }

  return (
    <Box className="bg-[#FBFBFF]" sx={{
      backgroundImage: banner_img,
    }}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 4, sm: 8 },
          p: '50px 30px 20px 30px',
          // backgroundColor: "#FBFBFF",
          // backgroundImage: banner_img,
          textAlign: { sm: 'center', md: 'left' },
        }}
      >
        <Box className="py-4 flex sm:flex-row w-full sm:justify-between sm:items-start text-black flex-wrap text-start">

          <Box className="flex flex-col w-1/2 sm:w-[auto] sm:justify-between sm:items-start gap-2 mb-6">
            <h1 className='text-[17px] font-bold mb-1 mt-2'>Services</h1>
            {/* <div className="flex text-[#4D4D4D]">
              <RightArrow sx={{color: "#425CD9"}} />
              <Link to="/">Buy a domain</Link>
            </div> */}
            <div className="flex text-[#4D4D4D]">
              {/* <RightArrow sx={{ color: "#425CD9" }} /> */}
              <Link to="/selldomain">Sell your domain</Link>
            </div>
          </Box>

          <Box className="flex flex-col w-1/2 sm:w-[auto] sm:justify-between sm:items-start gap-2 mb-6">
            <h1 className='text-[17px] font-bold mb-1 mt-2'>Quick Links</h1>
            {/* <div className="flex text-[#4D4D4D]">
              <RightArrow sx={{color: "#425CD9"}} />
              <Link to="/">About</Link>
            </div> */}
            <div className="flex text-[#4D4D4D]" onClick={() => handleScroll()}>
              {/* <RightArrow sx={{ color: "#425CD9" }} /> */}
              <Link to="/#how_it_works">How It Works</Link>
            </div>
            <div className="flex text-[#4D4D4D]" onClick={() => handleScroll()}>
              {/* <RightArrow sx={{ color: "#425CD9" }} /> */}
              <Link to="/#help_faq">Help & FAQs</Link>
            </div>
          </Box>

          <Box className="flex flex-col w-1/2 sm:w-[auto] sm:justify-between sm:items-start gap-2 mb-6">
            <h1 className='text-[17px] font-bold mb-1 mt-2'>Legal</h1>
            <div className="flex text-[#4D4D4D]">
              {/* <RightArrow sx={{ color: "#425CD9" }} /> */}
              <Link to="/terms-of-servies">Terms of Service</Link>
            </div>
            <div className="flex text-[#4D4D4D]">
              {/* <RightArrow sx={{ color: "#425CD9" }} /> */}
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
          </Box>

          <Box className="flex flex-col w-1/2 sm:w-[auto] gap-2 mb-6">
            <h1 className='text-[17px] font-bold mb-1 mt-2'>Follow Us</h1>
            <Stack
              direction="row"
              justifyContent="left"
              spacing={1}
              useFlexGap
              sx={{
                color: 'text.secondary',
              }}
            >
              <FacebookIcon className='mr-4 cursor-pointer' />
              <TwitterIcon className='cursor-pointer' />
            </Stack>
          </Box>

        </Box>
      </Container>
      <div className='bg-[#F0F2FF] text-center p-5 text-[#425CD9]'>
        <Typography variant="body2">Copyright © 2024 Xidomain.com</Typography>
      </div>
    </Box>
  );
}
