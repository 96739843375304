import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FaceIcon from '@mui/icons-material/Face';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { Chip, IconButton, Stack, SvgIcon, Tooltip, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { getBuytransactions } from '../../helper/helper';
import { Spinner } from '../Loader';
import MetaTags from '../MetaTags';

function BuyerTransaction(props) {
    const { userData } = props;
    const [transaction, settransaction] = useState([]);
    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1);
    const pageSize = 100;
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    useEffect(() => {
        async function fetchData() {
            if (userData && userData !== "") {
                setLoading(true)
                try {
                    const response = await getBuytransactions({ user_id: userData._id });
                    let transactionData = response.data.data;
                    // console.log('transactionData', transactionData)
                    // if (transactionData && transactionData.length > 0) {
                    //     const transactionDataArray = [];
                    //     transactionData.forEach((item) => {
                    //         const transactionDate = new Date(item.transaction_time).toString();
                    //         item.transaction_time = transactionDate;
                    //         transactionDataArray.push(item);
                    //     })
                    //     // transactionData = [...transactionDataArray];
                    // }
                    settransaction(transactionData);
                    setLoading(false);
                } catch (error) {
                    console.error("Error fetching transaction data:", error);
                    setLoading(false)
                }
            }
        }
        fetchData();
    }, [userData]);

    const columns = [
        { field: 'id', headerName: 'ID', headerClassName: " text-[12px]  bg-[#ffffff] text-black-100", minWidth: 100 },
        { field: 'name', headerName: 'Domain Name', headerClassName: " text-[12px]  bg-[#ffffff] text-black-100", flex: 2, minWidth: 150, renderCell: (params) => (<>{params.row.domain_name}</>) },
        { field: 'card_type', headerName: 'Card Type', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", minWidth: 100, renderCell: (params) => (<>{params.row.card_type}</>) },
        { field: 'account_number_last4', headerName: 'Card', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", minWidth: 100, renderCell: (params) => (<>{params.row.account_number_last4}</>) },
        { field: 'card_expiry', headerName: 'Card Expiry', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", minWidth: 100, renderCell: (params) => (<>{params.row.exp_month} / {params.row.exp_year}</>) },
        { field: 'transaction_time', headerName: 'Transaction Time', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", flex: 2, minWidth: 150, cellClassName: 'font-tabular-nums', renderCell: (params) => (<>{new Date(params.row.transaction_time).toString()}</>) },
        { field: 'amount', headerName: 'Amount', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", minWidth: 100, renderCell: (params) => (<>${params.row.amount}</>) },
        { field: 'currency', headerName: 'Currency', headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100", minWidth: 100, renderCell: (params) => (<>{params.row.currency}</>) },
        {
            field: 'status',
            headerName: 'Status',
            headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100",
            minWidth: 110,
            renderCell: (params) => (
                <>
                    {params.row.status === '1' ?
                        <Tooltip title="Success">
                            <Chip label={'✓'} color={'success'} icon={<FaceIcon fontSize='15px' />} size='small' sx={{ color: "white", fontWeight: '500' }} />
                        </Tooltip>
                        :
                        <Tooltip title="Failed">
                            <Chip label={'x'} color={'error'} icon={<FaceIcon fontSize='15px' />} size='small' sx={{ color: "white", fontWeight: '500' }} />
                        </Tooltip>
                    }
                </>
            )
        },
        {
            field: 'receipt_url',
            headerName: 'Receipt',
            headerClassName: " text-[12px]  bg-[#ffffff]  text-black-100",
            minWidth: 60,
            renderCell: (params) => (
                <>
                    {params.row.status === "1" ?
                        <Tooltip title="Download Invoice">
                            <IconButton onClick={() => window.open(params.row.receipt_url, "_blank")}>
                                <SvgIcon>
                                    <SimCardDownloadIcon fontSize='small' />
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                        :
                        ""
                    }
                </>
            )
        },

    ];
    const transactionWithUniqueId = transaction && transaction.length > 0 && transaction.map((user, index) => ({
        id: user._id,
        status: user.status === '1' ? "Active" : "Inactive",
        ...user,
    }));

    const currentPageRows = transactionWithUniqueId && transactionWithUniqueId.length > 0 && transactionWithUniqueId.slice(startIndex, endIndex);

    const handlePageChange = (event, value) => {
        setPage(value)
    };

    return (
        <>
            <MetaTags title="Transaction" description="Manage your transcation data." />
            {loading ?
                <div className="flex items-center justify-center h-screen w-full">
                    <Stack direction="row" alignItems="center" gap={2} >
                        <Spinner />
                    </Stack>
                </div>
                :
                <div className='w-full h-screen'>
                    <Stack direction="column" gap={4}>
                        <Stack gap={1}>
                            <DataGrid
                                autoHeight
                                ColumnFilter
                                // autosizeOptions={{
                                //     columns: ['name', 'transaction_name', 'top_level_transaction'],
                                //     includeOutliers: true,
                                //     includeHeaders: true,
                                // }}
                                slots={{
                                    // toolbar: GridToolbar,
                                    loadingOverlay: () => loading && <div className='flex w-full justify-center h-screen items-center'> <Spinner /> </div>,
                                    pagination: () => (
                                        <Stack spacing={2} justifyContent="center" alignItems="center" className='w-full p-3 bg-[#ffffff] text-center'>
                                            <Pagination
                                                count={Math.ceil(transactionWithUniqueId && transactionWithUniqueId.length > 0 && transactionWithUniqueId.length / pageSize)}
                                                page={page}
                                                onChange={handlePageChange}
                                                renderItem={(item) => (
                                                    <PaginationItem
                                                        component="div"

                                                        slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                                                        {...item}
                                                        onClick={() => handlePageChange(null, item.page)}

                                                    />
                                                )}

                                            />
                                        </Stack>
                                    ),
                                    noRowsOverlay: () => <Typography p={5} textAlign="center" >No Data Available</Typography>
                                }}
                                // componentsProps={{
                                //     toolbar: {
                                //         showQuickFilter: false,
                                //         quickFilterProps: { debounceMs: 500 },
                                //     },
                                // }}
                                sx={{

                                    '.MuiDataGrid-columnHeaderTitle': {
                                        fontWeight: 'bold',
                                    }, color: "#000", backgroundColor: "#ffffff", fontSize: "13px", lineHeight: "18px", cursor: "pointer", fontWeight: '600',
                                }}
                                loading={loading}
                                rows={currentPageRows && currentPageRows.length > 0 ? currentPageRows : []}
                                columns={columns}
                                initialState={{
                                    ...currentPageRows.initialState,
                                    columns: {
                                        columnVisibilityModel: {
                                            // top_level_transaction: false,
                                            id: false,
                                            // transaction_name: false
                                        }
                                    }
                                }}
                                disableRowSelectionOnClick
                            />
                        </Stack>
                    </Stack>
                </div>
            }
        </>
    )
};

export default BuyerTransaction