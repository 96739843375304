import axios from 'axios';
import { jwtDecode } from "jwt-decode";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_DOMAIN || "http://localhost:8080"

export async function registerUser(credentials) {
    try {

        const { data: { message } } = await axios.post('/api/register', credentials);
        return Promise.resolve(message);
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function getPageSetting(page) {
    try {
        const { data } = await axios.get(`/api/getPageSetting?page=${page}`);
        return { data };
    } catch (error) {
        return { error: "Page Not Found" };
    }
}

export const verifyEmailToken = async (token) => {
    try {
        const { data: { message } } = await axios.post('/api/verifyConfirmEmailToken', { token })
        return Promise.resolve(message);
    }
    catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function login({ email, password }) {
    try {
        if (email) {
            const { data } = await axios.post('/api/login', { email, password });
            // console.log(data);
            return Promise.resolve({ data });
        }
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function loginUserWithGoogle(tokenResponse) {
    try {
        const { data } = await axios.post('/api/loginWithGoogle', tokenResponse);
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject({ error });
    }
}
export async function loginWithGoogleUpdate(response) {
    try {
        const { data } = await axios.post('/api/loginWithGoogleUpdate', response);
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject({ error });
    }
}

export async function getEmailFromToken() {
    const token = localStorage.getItem('token');
    // console.log('token', token)
    if (!token) return Promise.reject("Cannot find token")

    let decode = jwtDecode(token)
    // console.log(decode);
    return decode;
}

export async function logout() {
    localStorage.removeItem('token');
}

export async function getUser(email) {
    try {
        const { data } = await axios.get(`/api/user/${email}`);
        return { data };
    } catch (error) {
        // return { error: "Password Does Not Match!" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getUserData() {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get('/api/getUserList', { headers: { "authorization": `Bearer ${token}` } });
        return { data: "data" };
    } catch (error) {
        // return { error: "User Data not found" }
        return Promise.reject(error.response.data.error);
    }
}


export async function deleteUser(id) {
    try {
        const token = localStorage.getItem('token');
        // console.log(token);
        const data = await axios.delete('/api/adminDeleteUser', { params: { id }, headers: { "authorization": `Bearer ${token}` } });
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}


export async function updateUser(response) {
    try {
        const token = localStorage.getItem('token');
        const data = await axios.put('/api/updateUser', response, { headers: { "authorization": `Bearer ${token}` } })
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function updateWishlist(response) {
    try {
        const token = localStorage.getItem('token');
        const data = await axios.put('/api/updateWishlist', response, { headers: { "authorization": `Bearer ${token}` } })
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function passwordChange(response) {
    try {
        const token = localStorage.getItem('token');
        const data = await axios.put('/api/passwordChange', response, { headers: { "authorization": `Bearer ${token}` } })
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function adminPasswordChange(response) {
    try {
        const token = localStorage.getItem('token');
        const data = await axios.put('/api/adminPasswordChange', response, { headers: { "authorization": `Bearer ${token}` } })
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function createDomain(response) {
    try {
        const token = localStorage.getItem('token');
        const data = await axios.post('/api/createDomain', response, { headers: { "authorization": `Bearer ${token}` } })
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}


export async function getDomains(response) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.post('/api/getDomains', response, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "Domain not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getBuyDomains(response) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.post('/api/getBuyDomains', response, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "Domain not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getDomain(id) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get(`/api/getDomain/${id}`, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "Domain Data not found" }
        return Promise.reject(error.response.data.error);
    }
}


export async function getUserDomain(name) {
    try {
        const { data } = await axios.get(`/api/getUserDomain/${name}`);
        return { data };
    } catch (error) {
        // return { error: "Domain Data not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getSellerDomainCounts(id) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get(`/api/getSellerDomainCounts/${id}`, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "Domain Data not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getBuyerDomainCounts(id) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get(`/api/getBuyerDomainCounts/${id}`, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "Domain Data not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getWishlistDomains(response) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.post('/api/getWishlistDomains', response, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "Domain not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function deleteDomain(user_id, id) {
    try {
        const token = localStorage.getItem('token');
        // console.log(token);
        const data = await axios.delete('/api/deleteDomain', { params: { user_id, id }, headers: { "authorization": `Bearer ${token}` } });
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}


export async function getAllCategory() {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get('/api/getAllCategory', { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: error || "Category not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function updateDomainCategory(response) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.put('/api/updateDomainCategory', response, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: error || "Domain not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function verifyDomain(response) {
    try {
        const token = localStorage.getItem('token')
        const { data: { message } } = await axios.post('/api/verifyDomain', response, { headers: { "authorization": `Bearer ${token}` } });
        // console.log('lcllc', message)
        return Promise.resolve(message);;
    } catch (error) {
        // console.log('error', error)
        // return { error: error }
        return Promise.reject(error.response.data.error);
    }
}

export async function searchDomain(response) {
    try {
        const queryString = new URLSearchParams(response).toString();
        const { data } = await axios.get(`/api/searchDomain/?${queryString}`);
        return { data };
    } catch (error) {
        // return { error: "Password Does Not Match!" }
        // return Promise.reject(error.response.data.error);
    }
}

export async function createCategory(response) {
    try {
        const token = localStorage.getItem('token')
        const { data: { message } } = await axios.post('/api/createCategory', response, { headers: { "authorization": `Bearer ${token}` } });
        return Promise.resolve(message);;
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function updateCategory(response) {
    try {
        const token = localStorage.getItem('token')
        const { data: { message } } = await axios.put('/api/updateCategory', response, { headers: { "authorization": `Bearer ${token}` } });
        return Promise.resolve(message);;
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function createPaymentIntent(response) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.post('/api/createPaymentIntent', response, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "Domain not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getCountryList() {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get('/api/getCountryList', { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "User Data not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getStateList(id) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get(`/api/getStateList/${id}`, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "User Data not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getCityList(id) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get(`/api/getCityList/${id}`, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "User Data not found" }
        return Promise.reject(error.response.data.error);
    }
}


export async function getBuytransactions(response) {
    try {
        const token = localStorage.getItem('token');
        const data = await axios.post('/api/getBuytransactions', response, { headers: { "authorization": `Bearer ${token}` } })
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}



export async function generateOTP(response) {
    try {
        const token = localStorage.getItem('token');
        const data = await axios.post('/api/generateOTP', response, { headers: { "authorization": `Bearer ${token}` } })
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}


export async function verifyOTP(response) {
    try {
        const token = localStorage.getItem('token');
        const data = await axios.post('/api/verifyOTP', response, { headers: { "authorization": `Bearer ${token}` } })
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function resetPassword(response) {
    try {
        const token = localStorage.getItem('token');
        const data = await axios.put('/api/resetPassword', response, { headers: { "authorization": `Bearer ${token}` } })
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}


export async function findDetails() {
    try {
        const { data } = await axios.get(`/api/findDetails`);
        return { data };
    } catch (error) {
        // return { error: "Domain Data not found" }
        return Promise.reject(error.response.data.error);
    }
}

export async function getWishlistList(response) {
    try {
        const { data } = await axios.post('/api/getWishlistList', response);
        return Promise.resolve({ data })
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function searchSellerDomain(response) {
    try {
        const token = localStorage.getItem('token');
        const data = await axios.post('/api/searchSellerDomain', response, { headers: { "authorization": `Bearer ${token}` } })
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function createContact(response) {
    try {
        const data = await axios.post('/api/createContact', response)
        return Promise.resolve({ data });
    } catch (error) {
        return Promise.reject(error.response.data.error);
    }
}

export async function descriptionsSet(name) {
    try {
        const token = localStorage.getItem('token')
        const { data } = await axios.get(`/api/descriptionsSet/${name}`, { headers: { "authorization": `Bearer ${token}` } });
        return { data };
    } catch (error) {
        // return { error: "User Data not found" }
        return Promise.reject(error.response.data.error);
    }
}