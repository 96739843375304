import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function Search(props) {
    const { data } = props;
    const [categorys, setcategorys] = useState();
    const [query, setquery] = useState('');
    const navigation = useNavigate();

    const handleChange = (value) => {
        setquery(value);
    }

    const handleClick = () => {
        if (query && query !== "" && query !== undefined) {
            navigation(`/search?key=${query}`);
        } else {
            navigation('/search');
        }
    }

    useEffect(() => {
        if (data && data !== "" && data !== undefined) {
            setcategorys(data.category)
        }
    }, [data])

    return (
        <>
            <Box sx={{ backgroundColor: "#F0F2FF" }}>
                <Box sx={{
                    maxWidth: '1440px',
                    margin: '0 auto',
                    padding: '20px 20px 0',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <div className="mainbox">
                        <div className='flex justify-start w-[90%]'>
                            <div className="iconContainer" onClick={() => handleClick()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none"> <path d="M19.35 20.9251L13.325 14.9001C12.825 15.3334 12.2417 15.6709 11.575 15.9126C10.9083 16.1543 10.2 16.2751 9.45 16.2751C7.65 16.2751 6.125 15.6501 4.875 14.4001C3.625 13.1501 3 11.6418 3 9.8751C3 8.10843 3.625 6.6001 4.875 5.3501C6.125 4.1001 7.64167 3.4751 9.425 3.4751C11.1917 3.4751 12.6958 4.1001 13.9375 5.3501C15.1792 6.6001 15.8 8.10843 15.8 9.8751C15.8 10.5918 15.6833 11.2834 15.45 11.9501C15.2167 12.6168 14.8667 13.2418 14.4 13.8251L20.475 19.8501C20.625 19.9834 20.7 20.1543 20.7 20.3626C20.7 20.5709 20.6167 20.7584 20.45 20.9251C20.3 21.0751 20.1167 21.1501 19.9 21.1501C19.6833 21.1501 19.5 21.0751 19.35 20.9251ZM9.425 14.7751C10.775 14.7751 11.925 14.2959 12.875 13.3376C13.825 12.3793 14.3 11.2251 14.3 9.8751C14.3 8.5251 13.825 7.37093 12.875 6.4126C11.925 5.45426 10.775 4.9751 9.425 4.9751C8.05833 4.9751 6.89583 5.45426 5.9375 6.4126C4.97917 7.37093 4.5 8.5251 4.5 9.8751C4.5 11.2251 4.97917 12.3793 5.9375 13.3376C6.89583 14.2959 8.05833 14.7751 9.425 14.7751Z" fill="currentColor" /> </svg>
                            </div>
                            <input className="search_input focus:text-left text-left w-[90%]" placeholder="Search Your Domain Name Here" value={query} onKeyPress={event => { if (event.key === 'Enter') { handleClick() } }} onChange={(e) => handleChange(e.target.value)} type="text" />
                        </div>
                        <div className="iconContainer rounded_icon bg-[#425CD9]" onClick={() => handleClick()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 20 21" fill="none"> <rect y="0.738037" width="20" height="20" rx="10" fill="#425CD9" /> <path d="M14 10.738C13.9968 10.3873 13.8555 10.0519 13.6067 9.80467L10.7467 6.938C10.6218 6.81384 10.4528 6.74414 10.2767 6.74414C10.1006 6.74414 9.9316 6.81384 9.80669 6.938C9.7442 6.99998 9.69461 7.07371 9.66076 7.15495C9.62691 7.23619 9.60949 7.32333 9.60949 7.41134C9.60949 7.49934 9.62691 7.58648 9.66076 7.66772C9.69461 7.74896 9.7442 7.82269 9.80669 7.88467L12 10.0713H5.33335C5.15654 10.0713 4.98697 10.1416 4.86195 10.2666C4.73693 10.3916 4.66669 10.5612 4.66669 10.738C4.66669 10.9148 4.73693 11.0844 4.86195 11.2094C4.98697 11.3344 5.15654 11.4047 5.33335 11.4047H12L9.80669 13.598C9.68115 13.7227 9.61027 13.8921 9.60965 14.069C9.60902 14.2459 9.6787 14.4158 9.80335 14.5413C9.92801 14.6669 10.0974 14.7377 10.2743 14.7384C10.4512 14.739 10.6212 14.6693 10.7467 14.5447L13.6067 11.678C13.8571 11.4291 13.9985 11.0911 14 10.738Z" fill="white" /> </svg>
                        </div>
                    </div>
                    <div className='w-full max-w-[900px] p-5 flex flex-wrap justify-start md:flex-nowrap items-center md:justify-center'>
                        <p className='text-[#343434] mr-5 mb-3 md:mb-0'>Popular searches :</p>
                        <ul className='category_searches search_page'>
                            {categorys && categorys.map((item, index) => {
                                return (
                                    <li key={index}>
                                        <Link to={`/search?category=${item.id}`}>{item.name}</Link>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </Box>
            </Box>
        </>
    );
}