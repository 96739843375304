import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useEffect, useState } from 'react';

export default function Faq(props) {
    const { data } = props;
    const [faqList, setfaqList] = useState();

    useEffect(() => {
        if (data && data !== "" && data !== undefined) {
            setfaqList(data)
        }
    }, [data]);

    return (
        <div>
            <Box sx={{
                maxWidth: '800px',
                margin: '0 auto',
                padding: { xs: '30px 15px', sm: '40px 15px' },
            }}>
                <Typography variant="h4" fontWeight={600} gutterBottom sx={{ mt: 2, mb: 5, textAlign: 'center', fontSize: { xs: '30px', sm: '40px' } }}>Frequently Asked Questions</Typography>

                {faqList && faqList.map((item, index) => {
                    return (
                        <Accordion key={index} sx={{ mb: 2 }}>
                            <AccordionSummary
                                expandIcon={<AddIcon />}
                            >
                                <Typography>{item.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    {item.answer}
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    )
                })}
            </Box>
        </div >
    );
}
